import React, { useMemo } from 'react';
import { useProgressBar } from '@hooks/helpers/useProgressBar';
import styles from './styles.module.scss';
import Typography from '@components/common/typography';
import ProgressBar from '@components/progress_bar';
import { useTranslation } from 'react-i18next';
import { useForm } from '@hooks/helpers/useForm';
import { ETransactionMethod } from '@xeppt/xeppt-sdk/types/transaction';
import { FormProvider } from 'react-hook-form';
import { useVerificationGuard } from '@hooks/helpers/useVerificationGuard';
import { motion } from 'framer-motion';
import { pageAnimation } from '@const/animation';
import BankPaymentStep from '@sections/move_money/add_money/bank_payment';
import AmountStep from '@sections/move_money/add_money/amount';
import FinishStep from '@sections/move_money/add_money/finish';
import SummarySection from '@sections/move_money/add_money/summary';
import { useLocales } from '@hooks/helpers/useLocales';
import { useApiMutation } from '@hooks/api/useApiMutation';
import { apiAccountService } from '@api';
import { useUserContext } from '@hooks/context/useUserContext';

const steps = (localization: (val: string) => string) => [
    {
        title: localization('amount'),
        id: '1'
    },
    {
        title: localization('payment_methods'),
        id: '2'
    },
    {
        title: localization('add_money'),
        id: '3'
    }
];

const SendMoneyLayout = () => {
    const { t } = useTranslation('add_money');
    const { requestSuccessLocale, requestErrorLocale } = useLocales();
    const stepsMemo = useMemo(() => steps(t), [steps]);
    const { currentStep, availableStep, nextStep, prevStep } = useProgressBar(stepsMemo);
    const { verifiedAction } = useVerificationGuard();
    const { refetchAccount } = useUserContext();
    const form = useForm({
        defaultValues: {
            id: '',
            method: ETransactionMethod.EFT,
            amount: '',
            description: 'Load balance'
        }
    });

    const { handleRequest: onSubmit, isLoading: isLoading } = useApiMutation({
        method: () => {
            const { method, ...values } = form.getValues();
            return apiAccountService.sendMoneyEft({
                ...values,
                amount: Number(values.amount)
            });
        },
        onSuccess: () => {
            nextStep();
            refetchAccount();
            requestSuccessLocale('send_money_eft');
        },
        onError: requestErrorLocale
    });

    const handlePrevStepPayment = () => {
        prevStep();
    };

    const renderStep = () => {
        switch (currentStep?.id) {
            case '1':
                return <AmountStep isBalance handleNext={() => verifiedAction(nextStep)} />;
            case '2':
                return (
                    <BankPaymentStep
                        handlePrevStep={() => {
                            handlePrevStepPayment();
                            form.setValue('id', '');
                        }}
                    />
                );
            default:
                return (
                    <FinishStep
                        text="has been transferred to your bank account. You’re all set!"
                        success={true}
                    />
                );
        }
    };

    return (
        <motion.div {...pageAnimation} className={styles.wrapper}>
            <Typography variant="h4">Send money</Typography>
            <div className={styles.title}>
                <ProgressBar
                    className={styles.progress_bar}
                    size="large"
                    variant="light"
                    currentStep={currentStep}
                    availableStep={availableStep}
                    steps={steps(t)}
                />
            </div>
            <FormProvider {...form}>
                <div className={styles.content}>
                    <div className={styles.left}>{renderStep()}</div>
                    {currentStep?.id === '2' && (
                        <div className={styles.right}>
                            {currentStep?.id === '2' && (
                                <SummarySection
                                    currentStep={currentStep?.id}
                                    isLoading={isLoading}
                                    handleNext={() =>
                                        verifiedAction(() => {
                                            if (currentStep?.id === '2') {
                                                onSubmit(undefined);
                                            } else {
                                                nextStep();
                                            }
                                        })
                                    }
                                />
                            )}
                        </div>
                    )}
                </div>
            </FormProvider>
        </motion.div>
    );
};

export default SendMoneyLayout;
