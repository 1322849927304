import { z } from 'zod';
import { validator } from '@xeppt/xeppt-sdk';

interface IProps {
    messages: {
        email: string;
        phone: string;
        firstName: string;
        lastName: string;
        birthDate: string;
        occupancy: string;
    };
}

export const profileSettingsValidation = ({ messages }: IProps) => {
    const validationObject: any = {
        email: validator.email(messages.email),
        phoneNumber: validator.phone(messages.phone),
        phoneCode: validator.phoneCode(messages.phone),
        profile: z.object({
            firstName: validator.firstName(messages.firstName),
            lastName: validator.lastName(messages.lastName),
            birthDate: validator.birthday(messages.birthDate)
        }),
        occupancy: z.string().min(1, { message: messages.occupancy })
    };
    return z.object(validationObject);
};

interface IBusinessValidatorProps {
    messages: {
        companyName: string;
        tradingName: string;
        registrationNumber: string;
        category: string;
        size: string;
        website: string;
    };
}

export const businessSettingsValidation = ({ messages }: IBusinessValidatorProps) => {
    return z.object({
        companyName: z.string().min(1, { message: messages.companyName }),
        tradingName: z.string().min(1, { message: messages.tradingName }),
        registrationNumber: z.string().min(1, { message: messages.registrationNumber }),
        category: z.string().min(1, { message: messages.category }),
        size: z.string().min(1, { message: messages.size }),
        website: z.string().url({ message: messages.website })
    });
};
