import React from 'react';
import Typography from '@components/common/typography';
import Input from '@components/common/input';
import styles from './styles.module.scss';
import { useLocales } from '@hooks/helpers/useLocales';
import { useTranslation } from 'react-i18next';

const ActivateCardModal = () => {
    const { t } = useTranslation('modals', {
        keyPrefix: 'activate_card_pin'
    });
    const { labelLocale } = useLocales();
    return (
        <div className={styles.wrapper}>
            <Typography className={styles.title} variant="h4">
                {t('title')}
            </Typography>
            <Typography className={styles.description} variant="body2">
                {t('description')}
            </Typography>
            <div className={styles.inputs_wrapper}>
                <div className={styles.input_wrapper}>
                    <Input
                        label={labelLocale('your_pin')}
                        full
                        placeholder="••••"
                        type="password"
                    />
                </div>
                <div className={styles.input_wrapper}>
                    <Input
                        label={labelLocale('confirm_pin')}
                        full
                        placeholder="••••"
                        type="password"
                    />
                </div>
            </div>
        </div>
    );
};

export default ActivateCardModal;
