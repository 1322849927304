import React, { useEffect } from 'react';
import styles from './styles.module.scss';
import Button from '@components/common/button';
import Typography from '@components/common/typography';
import { useNavigate } from 'react-router-dom';
import { routes } from '@const/routes';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { pageAnimation } from '@const/animation';
import { useNotificationsContext } from '@hooks/context/useNotificationsContext';
import { useLocales } from '@hooks/helpers/useLocales';
import { prettifyDate } from '@utils/date';
import Skeleton from '@components/common/skeleton';
import { TNotification } from '@xeppt/xeppt-sdk/types/notification';
import { useApiMutation } from '@hooks/api/useApiMutation';
import { apiNotificationService } from '@api';
import { getNotificationAction, getNotificationString } from '@utils/index';

const NotificationsLayout = () => {
    const { t } = useTranslation('notifications');
    const { t: shuftiProLocale } = useTranslation('shufti_pro');
    const navigate = useNavigate();
    const { notifications, isNotificationsLoading, refetchNotifications } =
        useNotificationsContext();
    const { notificationsLocale } = useLocales();

    useEffect(() => {
        refetchNotifications && refetchNotifications();
    }, []);

    const { handleRequest: handleReadNotification } = useApiMutation({
        method: (id: string) => apiNotificationService.readNotification(id),
        onSuccess: refetchNotifications
    });

    const onReadNotification = (notification: TNotification) => {
        if (!notification.isRead) {
            handleReadNotification(notification.id);
        }
        getNotificationAction(notification, navigate);
    };

    return (
        <motion.div {...pageAnimation} className={styles.wrapper}>
            <div className={styles.head}>
                <Button onClick={() => navigate(routes.dashboard)} leftIcon="arrow_left">
                    {t('title')}
                </Button>
            </div>
            <div className={styles.content}>
                {isNotificationsLoading && <Skeleton className={styles.skeleton} />}
                {notifications?.map((item) => {
                    return (
                        <div
                            className={styles.item}
                            key={item.id}
                            onClick={() => onReadNotification(item)}>
                            <Typography className={styles.time} variant="body3">
                                {prettifyDate(item.createdAt, t)}{' '}
                            </Typography>
                            <Typography className={styles.title} fw={400} variant="h5">
                                {notificationsLocale(item.event)}{' '}
                                {getNotificationString(item, shuftiProLocale)}
                            </Typography>
                            <span className={`${styles.status} ${item.isRead ? '' : styles.red}`} />
                        </div>
                    );
                })}
            </div>
        </motion.div>
    );
};

export default NotificationsLayout;
