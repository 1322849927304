import React, { FC, useEffect, useState } from 'react';
import { IModalComponentProps } from '@context/modal_context';
import KycDocuments from './kyc_documents';
import KycErrors from './kyc_errors';
import styles from './styles.module.scss';
import KycSuccess from './kyc_success';

const KycModal: FC<
    IModalComponentProps<{ isError: boolean; codes: string[]; isSuccess: boolean }>
> = ({ onClose, modalData }) => {
    const [step, setStep] = useState(0);
    const [errors, setErrors] = useState<string[]>([]);

    const handleNextStep = () => {
        setStep((state) => state + 1);
    };

    useEffect(() => {
        if (modalData?.isError) {
            setStep(1);
        }
        if (modalData?.isSuccess) {
            setStep(2);
        }
        if (modalData?.codes) {
            setErrors(modalData.codes);
        }
    }, [modalData]);

    const renderStep = () => {
        switch (step) {
            case 0:
                return <KycDocuments onClose={onClose} handleNextStep={handleNextStep} />;
            case 1:
                return (
                    <KycErrors
                        onClose={onClose}
                        errors={errors}
                        handleStart={() => {
                            setStep(0);
                            setErrors([]);
                        }}
                    />
                );
            case 2:
                return <KycSuccess onClose={onClose} />;
        }
    };

    return <div className={styles.wrapper}>{renderStep()}</div>;
};

export default KycModal;
