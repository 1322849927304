import { createContext, FC, ReactNode, useEffect, useState } from 'react';
import { initializeApp } from 'firebase/app';
import { getDatabase, ref } from 'firebase/database';
import { useApiQuery } from '@hooks/api/useApiQuery';
import { apiAuthService, apiNotificationService } from '@api';
import { TNotification, EEventName } from '@xeppt/xeppt-sdk/types/notification';
import { useObjectVal } from 'react-firebase-hooks/database';
import { useUserContext } from '@hooks/context/useUserContext';
import notificationSound from '@audio/new_notification.mp3';

const shownNotifications = [
    EEventName.CardBalanceLoaded,
    EEventName.CardCreated,
    EEventName.CardLimitsUpdated,
    EEventName.CardPinChanged,
    EEventName.CardStatusChanged,
    EEventName.EftAccountLinked,
    EEventName.ETransferAccountUpdated,
    EEventName.PaymentReceived,
    EEventName.PaymentStatusChanged,
    EEventName.PrimaryEmailChanged,
    EEventName.PrimaryPhoneChanged,
    EEventName.SchedulePaymentProcessed,
    EEventName.UserVerified
];

type TNotificationsContext = {
    database?: any;
    notifications?: TNotification[];
    isNotificationsLoading?: boolean;
    refetchNotifications?: () => void;
};

export const NotificationsContext = createContext<TNotificationsContext>({});

interface IProps {
    children: ReactNode;
}

export const NotificationsContextProvider: FC<IProps> = ({ children }) => {
    const [database, setDatabase] = useState<any>();
    const { user } = useUserContext();
    const [isFirstRender, setIsFirstRender] = useState(true);
    const [isSecondRender, setIsSecondRender] = useState(false);
    const [newNotification] = useObjectVal(database ? ref(database, user?.id) : null);

    const playNotificationsSound = () => {
        const audio = new Audio(notificationSound);
        audio.play();
    };

    const { data: firebaseConfig } = useApiQuery({
        method: () => apiAuthService.getRDBCredentials()
    });

    const {
        data: notifications,
        isLoading: isNotificationsLoading,
        handleRequest: refetchNotifications
    } = useApiQuery({
        method: () => apiNotificationService.getNotifications(),
        isInitialRequest: false
    });

    useEffect(() => {
        if (!isFirstRender) {
            refetchNotifications();
            setIsSecondRender(true);
        }
        setIsFirstRender(false);
        if (isSecondRender) {
            playNotificationsSound();
        }
    }, [newNotification]);

    useEffect(() => {
        if (firebaseConfig) {
            const firebaseApp = initializeApp(JSON.parse(firebaseConfig));
            //@ts-ignore
            setDatabase(getDatabase(firebaseApp));
        }
    }, [firebaseConfig]);

    return (
        <NotificationsContext.Provider
            value={{
                database,
                notifications: notifications
                    ?.filter((item) => shownNotifications.includes(item.event))
                    .reverse(),
                isNotificationsLoading,
                refetchNotifications
            }}>
            {children}
        </NotificationsContext.Provider>
    );
};
