import { apiUserService } from '@api';
import { EUserStatus } from '@xeppt/xeppt-sdk/types/user';
import { modalIds } from '@const/modals';
import { useEffect } from 'react';
import useModalContext from '@hooks/context/useModalContext';
import { useUserContext } from '@hooks/context/useUserContext';
import { useLocales } from '@hooks/helpers/useLocales';

interface IProps {
    onSuccess?: () => void;
}

export const useVerificationResult = (data?: IProps) => {
    const { changeModalData, onOpen } = useModalContext();
    const { requestErrorLocale, requestSuccessLocale } = useLocales();
    const { user, refetchUser, refetchAccount } = useUserContext();

    const getVerificationResult = () => {
        apiUserService
            .verificationResult()
            .then(({ status, codes }) => {
                if (status === EUserStatus.UNVERIFIED) {
                    if (codes) {
                        changeModalData({ isError: true, codes });
                        onOpen({
                            modalId: modalIds.KYC_FIRST_MODAL
                        });
                    } else {
                        changeModalData({ isSuccess: true });
                        onOpen({
                            modalId: modalIds.KYC_FIRST_MODAL
                        });
                    }
                    requestErrorLocale('failed_verify');
                    refetchUser();
                    refetchAccount();
                } else if (status === EUserStatus.ACTIVE) {
                    data?.onSuccess && data?.onSuccess();
                    requestSuccessLocale('kyc_success');
                    refetchUser();
                    refetchAccount();
                } else {
                    setTimeout(() => getVerificationResult(), 10000);
                }
            })
            .catch();
    };

    useEffect(() => {
        if (user && user?.status === EUserStatus.PENDING) {
            getVerificationResult();
        }
    }, [user]);
};
