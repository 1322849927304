import React from 'react';
import { Icon } from '@components/icons';
import Typography from '@components/common/typography';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';

const ResumePaymentModal = () => {
    const { t } = useTranslation('modals', {
        keyPrefix: 'resume_payment'
    });
    return (
        <div className={styles.wrapper}>
            <Icon name="error_outlined" width={60} height={60} />
            <Typography variant="h5">{t('title')}</Typography>
            <Typography variant="body3" className={styles.title}>
                {t('description')}
            </Typography>
        </div>
    );
};

export default ResumePaymentModal;
