import React, { FC, useCallback, useRef, useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import DragAndDrop from '@components/drag_and_drop';
import styles from './styles.module.scss';
import Typography from '@components/common/typography';
import { IModalComponentProps } from '@context/modal_context';
import Button from '@components/common/button';
import { useLocales } from '@hooks/helpers/useLocales';
import BarPicker from '@components/bar_picker';
import { useTranslation } from 'react-i18next';
import { useUserContext } from '@hooks/context/useUserContext';
import Webcam from 'react-webcam';
import { base64ToFile } from '@utils/index';

const UploadAvatarModal: FC<
    IModalComponentProps<{ isBusiness: boolean; customTitle: boolean; avatar?: string }>
> = ({ onSubmit, changeModalData, modalData }) => {
    const { t } = useTranslation('modals', { keyPrefix: 'upload_avatar' });
    const { submitLocale } = useLocales();
    const [image, setImage] = useState<File>();
    const [preview, setPreview] = useState<string>();
    const [scale, setScale] = useState(0);
    const editorRef = useRef<AvatarEditor | null>(null);
    const webcamRef = useRef<any>(null);
    const [isWebcam, setIsWebcam] = useState(false);
    const { user } = useUserContext();

    const handleSave = () => {
        if (editorRef.current) {
            const canvas = editorRef.current.getImageScaledToCanvas().toDataURL();
            setPreview(canvas);
        }
    };

    const handleMakePhoto = useCallback(() => {
        const imageSrc = webcamRef.current.getScreenshot();
        const mimetype = webcamRef.current.getScreenshot().split(';')?.[0].split('/')?.[1];
        setImage(base64ToFile(imageSrc, 'image', mimetype));
        setIsWebcam(false);
    }, [webcamRef]);

    return (
        <div className={styles.wrapper}>
            <Typography className={styles.title} variant="h4">
                {modalData?.isBusiness
                    ? t(user?.profile?.avatar ? 'update_logo' : 'add_logo')
                    : modalData?.customTitle
                      ? modalData?.customTitle
                      : t(user?.profile?.avatar ? 'update_avatar' : 'add_avatar')}
            </Typography>
            {image ? (
                preview ? (
                    <>
                        <img className={styles.preview} src={preview} alt="image preview" />
                        <div className={styles.actions}>
                            <Button
                                size="normal"
                                variant="outlined"
                                onClick={() => {
                                    changeModalData({ ...modalData, avatar: undefined });
                                    setPreview(undefined);
                                }}>
                                {submitLocale('back')}
                            </Button>
                            <Button
                                size="normal"
                                variant="primary"
                                onClick={() => {
                                    changeModalData({ ...modalData, avatar: preview });
                                    onSubmit && onSubmit({ ...modalData, avatar: preview });
                                }}>
                                {submitLocale('save')}
                            </Button>
                        </div>
                    </>
                ) : (
                    <>
                        <AvatarEditor
                            ref={editorRef}
                            image={image && URL.createObjectURL(image)}
                            width={250}
                            height={250}
                            border={50}
                            borderRadius={125}
                            scale={1 + scale / 100}
                        />
                        <BarPicker
                            step={1}
                            value={scale}
                            onChange={(val) => setScale(val)}
                            min={0}
                            max={100}
                        />
                        <div className={styles.actions}>
                            <Button
                                size="normal"
                                variant="outlined"
                                onClick={() => setImage(undefined)}>
                                {submitLocale('back')}
                            </Button>
                            <Button size="normal" variant="primary" onClick={handleSave}>
                                {submitLocale('preview')}
                            </Button>
                        </div>
                    </>
                )
            ) : isWebcam ? (
                <>
                    <Webcam
                        screenshotFormat="image/png"
                        style={{ borderRadius: 8 }}
                        width="100%"
                        ref={webcamRef}
                    />
                    <div className={styles.actions}>
                        <Button size="small" variant="primary" onClick={() => setIsWebcam(false)}>
                            {submitLocale('cancel')}
                        </Button>
                        <Button size="small" variant="primary" onClick={handleMakePhoto}>
                            {submitLocale('make_photo')}
                        </Button>
                    </div>
                </>
            ) : (
                <>
                    <DragAndDrop onChange={(val) => setImage(val)} />
                    <Button
                        size="small"
                        variant="primary"
                        onClick={() => {
                            setIsWebcam(true);
                        }}>
                        {submitLocale('make_photo')}
                    </Button>
                </>
            )}
        </div>
    );
};

export default UploadAvatarModal;
