import { z } from 'zod';
import { validator } from '@xeppt/xeppt-sdk';

export const getPhoneValidation = (
    messages: { phone: string; verification: string },
    phoneVerification?: boolean
) => {
    if (phoneVerification) {
        return z.object({
            verificationCode: validator.verificationCode(messages.verification)
        });
    } else {
        return z.object({
            phoneNumber: validator.phone(messages.phone),
            phoneCode: validator.phoneCode(messages.phone)
        });
    }
};
