import React from 'react';
import Typography from '@components/common/typography';
import styles from './styles.module.scss';
import { Icon } from '@components/icons';
import { useTranslation } from 'react-i18next';

const UnlockCardModal = () => {
    const { t } = useTranslation('modals', {
        keyPrefix: 'unlock_card'
    });
    return (
        <div className={styles.wrapper}>
            <Icon name="lock_opened" width={80} height={80} />
            <Typography className={styles.title} variant="h4">
                {t('title')}
            </Typography>
        </div>
    );
};

export default UnlockCardModal;
