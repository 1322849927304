import React, { FC } from 'react';
import Radio from '@components/common/radio';
import { EPaymentSystem } from '@enum';
import visaIcon from '@svg/visa.svg';
import mcIcon from '@svg/mastercard.svg';
import { hideCardNumber } from '@utils/index';
import Input from '@components/common/input';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';

interface IProps {
    name: string;
    card: string;
    type: EPaymentSystem;
    date: string;
    checked: boolean;
    onChange: (value: string) => void;
}

const cx = classNames.bind(styles);

const PaymentCardRadio: FC<IProps> = ({ name, checked, onChange, card, type, date }) => {
    return (
        <div className={cx([styles.wrapper, { checked }])} onClick={() => onChange(card)}>
            <div className={styles.left}>
                <img src={type === EPaymentSystem.VISA ? visaIcon : mcIcon} alt={type} />
                <div className={styles.content}>
                    <div className={styles.text_wrapper}>
                        <p>
                            {name} ({hideCardNumber(card, 'full', 'stars')})
                        </p>
                        <span>Valid {date}</span>
                    </div>
                    <div className={cx([styles.input_wrapper, { checked }])}>
                        <p>Confirm security code (CVC/CVV)</p>
                        <Input full label="CVV" rightIcon="credit_card" />
                    </div>
                </div>
            </div>
            <Radio checked={checked} />
        </div>
    );
};

export default PaymentCardRadio;
