import React, { useMemo, useState } from 'react';
import { useProgressBar } from '@hooks/helpers/useProgressBar';
import styles from './styles.module.scss';
import Typography from '@components/common/typography';
import ProgressBar from '@components/progress_bar';
import { useTranslation } from 'react-i18next';
import { useForm } from '@hooks/helpers/useForm';
import { ETransactionMethod } from '@xeppt/xeppt-sdk/types/transaction';
import { apiAccountService } from '@api';
import { useLocales } from '@hooks/helpers/useLocales';
import { FormProvider, useWatch } from 'react-hook-form';
import { useVerificationGuard } from '@hooks/helpers/useVerificationGuard';
import { motion } from 'framer-motion';
import { pageAnimation } from '@const/animation';
import { useApiMutation } from '@hooks/api/useApiMutation';
import CardPaymentStep from '@sections/move_money/add_money/card_payment';
import SentCardPaymentStep from '@sections/move_money/add_money/sent_card_payment';
import BankPaymentStep from '@sections/move_money/add_money/bank_payment';
import InteracPaymentStep from '@sections/move_money/add_money/interac_payment';
import SentInteracRequestStep from '@sections/move_money/add_money/sent_interac_request';
import AmountStep from '@sections/move_money/add_money/amount';
import PaymentMethodStep from '@sections/move_money/add_money/payment_type';
import FinishStep from '@sections/move_money/add_money/finish';
import SummarySection from '@sections/move_money/add_money/summary';

const steps = (localization: (val: string) => string) => [
    {
        title: localization('amount'),
        id: '1'
    },
    {
        title: localization('payment_methods'),
        id: '2'
    },
    {
        title: localization('add_money'),
        id: '3'
    }
];

const AddMoneyLayout = () => {
    const { t } = useTranslation('add_money');
    const { requestSuccessLocale, requestErrorLocale } = useLocales();
    const stepsMemo = useMemo(() => steps(t), [steps]);
    const { currentStep, availableStep, nextStep, prevStep } = useProgressBar(stepsMemo);
    const { verifiedAction } = useVerificationGuard();
    const [preventPaymentType, setPreventPaymentType] = useState<ETransactionMethod>(
        ETransactionMethod.EFT
    );
    const [sentInteracRequest, setSentInteracRequest] = useState(false);
    const [sentCardPayment, setSentCardPayment] = useState(false);
    const form = useForm({
        defaultValues: {
            id: '',
            method: ETransactionMethod.EFT,
            amount: '',
            description: 'Load balance'
        }
    });
    const paymentMethod = useWatch({ name: 'method', control: form.control });

    const { handleRequest: onSubmit, isLoading: isLoading } = useApiMutation({
        method: () => {
            const { method, ...values } = form.getValues();
            return apiAccountService.requestMoneyEft({
                ...values,
                amount: Number(values.amount)
            });
        },
        onSuccess: () => {
            nextStep();
            requestSuccessLocale('load_balance');
        },
        onError: requestErrorLocale
    });

    const handlePrevStepPayment = () => {
        prevStep();
    };

    const renderPaymentStep = () => {
        switch (paymentMethod) {
            case ETransactionMethod.CARD:
                return !sentCardPayment ? (
                    <CardPaymentStep
                        handlePrevStep={() => {
                            handlePrevStepPayment();
                            setSentCardPayment(false);
                        }}
                    />
                ) : (
                    <SentCardPaymentStep
                        handlePrevStep={() => {
                            handlePrevStepPayment();
                            setSentCardPayment(false);
                        }}
                    />
                );
            case ETransactionMethod.EFT:
                return (
                    <BankPaymentStep
                        handlePrevStep={() => {
                            handlePrevStepPayment();
                            form.setValue('id', '');
                        }}
                    />
                );
            case ETransactionMethod.E_TRANSFER:
                return !sentInteracRequest ? (
                    <InteracPaymentStep
                        handlePrevStep={() => {
                            handlePrevStepPayment();
                            form.setValue('id', '');
                        }}
                        handleSubmitRequest={() => setSentInteracRequest(true)}
                    />
                ) : (
                    <SentInteracRequestStep
                        isLoading={isLoading}
                        handleNextStep={() => onSubmit(undefined)}
                    />
                );
        }
    };

    const renderStep = () => {
        switch (currentStep?.id) {
            case '1':
                return <AmountStep handleNext={() => verifiedAction(nextStep)} />;
            case '2':
                return (
                    <PaymentMethodStep
                        type={preventPaymentType}
                        onChange={(value) => verifiedAction(() => setPreventPaymentType(value))}
                    />
                );
            case '3':
                return renderPaymentStep();
            default:
                return <FinishStep success={true} />;
        }
    };

    return (
        <motion.div {...pageAnimation} className={styles.wrapper}>
            <Typography variant="h4">XEPPT Balance</Typography>
            <div className={styles.title}>
                <ProgressBar
                    className={styles.progress_bar}
                    size="large"
                    variant="light"
                    currentStep={currentStep}
                    availableStep={availableStep}
                    steps={steps(t)}
                />
            </div>
            <FormProvider {...form}>
                <div className={styles.content}>
                    <div className={styles.left}>{renderStep()}</div>
                    {(((currentStep?.id === '2' || currentStep?.id === '3') &&
                        !sentInteracRequest) ||
                        (currentStep?.id === '3' &&
                            paymentMethod === ETransactionMethod.CARD &&
                            !sentCardPayment)) && (
                        <div className={styles.right}>
                            {(currentStep?.id === '2' || currentStep?.id === '3') &&
                                !sentInteracRequest && (
                                    <SummarySection
                                        currentStep={currentStep?.id}
                                        isLoading={isLoading}
                                        handleNext={() =>
                                            verifiedAction(() => {
                                                if (currentStep?.id === '2') {
                                                    form.setValue('method', preventPaymentType);
                                                }
                                                if (currentStep?.id === '3') {
                                                    onSubmit(undefined);
                                                    if (
                                                        paymentMethod ===
                                                        ETransactionMethod.E_TRANSFER
                                                    ) {
                                                        setSentInteracRequest(false);
                                                    } else {
                                                        setSentInteracRequest(false);
                                                    }
                                                } else {
                                                    nextStep();
                                                }
                                            })
                                        }
                                    />
                                )}
                        </div>
                    )}
                </div>
            </FormProvider>
        </motion.div>
    );
};

export default AddMoneyLayout;
