import React, {
    FC,
    MutableRefObject,
    TextareaHTMLAttributes,
    useEffect,
    useMemo,
    useState
} from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import Typography from '@components/common/typography';

interface IProps {
    value?: string;
    onChange?: (value: string) => void;
    helperText?: string;
    error?: boolean;
    label?: string;
    placeholder?: string;
    className?: string;
    full?: boolean;
    disabled?: boolean;
    stringClassName?: string;
    ref?: MutableRefObject<null>;
    inputClassName?: string;
    wrapperRef?: MutableRefObject<null>;
}

const cx = classNames.bind(styles);

const Textarea: FC<IProps & Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, 'onChange'>> = ({
    value = '',
    onChange,
    placeholder,
    label,
    prefix,
    helperText,
    full,
    className,
    stringClassName,
    error,
    disabled,
    ref,
    inputClassName,
    wrapperRef,
    ...props
}) => {
    const [inputId] = useState(String(props.id ? props.id : Math.random()));
    const [localValue, setLocalValue] = useState('');
    const rootStyles = useMemo(
        () =>
            cx([
                styles.root,
                className,
                {
                    disabled,
                    full,
                    error
                }
            ]),
        [full, error, disabled, className, prefix]
    );

    const labelStyles = useMemo(() => cx([styles.label, { isActive: !!value }]), [value]);

    useEffect(() => {
        if (value !== undefined) {
            setLocalValue(value);
        }
    }, [value]);

    return (
        <div className={`${rootStyles} ${stringClassName}`} ref={wrapperRef}>
            {label && (
                <label htmlFor={inputId} className={labelStyles}>
                    {label}
                </label>
            )}
            <div className={styles.input_wrapper}>
                <textarea
                    disabled={disabled}
                    placeholder={placeholder}
                    value={localValue}
                    ref={ref}
                    id={inputId}
                    className={inputClassName}
                    onChange={(e: any) => {
                        onChange && onChange(e.target.value);
                        setLocalValue(e.target.value);
                    }}
                    {...props}
                />
            </div>
            {helperText && (
                <Typography variant="body3" className={styles.helper_text}>
                    {helperText}
                </Typography>
            )}
        </div>
    );
};

export default Textarea;
