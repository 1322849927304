import React from 'react';
import Typography from '@components/common/typography';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { pageAnimation } from '@const/animation';
import Switch from '@components/common/switch';
import { useForm } from '@hooks/helpers/useForm';
import FormField from '@components/form_field';
import { FormProvider } from 'react-hook-form';

const SettingsNotificationsLayout = () => {
    const { t } = useTranslation('settings', {
        keyPrefix: 'notifications'
    });
    const formMethods = useForm({
        defaultValues: {
            fraud: {
                transactions: {
                    text: true,
                    email: true
                },
                updates: {
                    text: true,
                    email: true
                }
            },
            transactions: {
                new: {
                    text: true,
                    email: true
                },
                updates: {
                    text: true,
                    email: true
                }
            },
            reminders: {
                new: {
                    text: true,
                    email: true
                },
                updates: {
                    text: true,
                    email: true
                }
            },
            marketing: {
                new: {
                    text: true,
                    email: true
                },
                updates: {
                    text: true,
                    email: true
                }
            }
        }
    });

    console.log(formMethods.getValues());

    return (
        <motion.div {...pageAnimation} className={styles.wrapper}>
            <div className={styles.title_wrapper}>
                <Typography variant="h4" className={styles.title}>
                    {t('title')}
                </Typography>
                <Typography variant="body3">{t('description')}</Typography>
            </div>
            <FormProvider {...formMethods}>
                <div className={styles.content}>
                    <div className={styles.row}>
                        <div className={styles.header}>
                            <Typography variant="body1" weight="semibold">
                                {t('fraud.title')}
                            </Typography>
                        </div>
                        <div className={styles.row}>
                            <Typography variant="body3">{t('fraud.transactions')}</Typography>
                            <div className={styles.switches_wrapper}>
                                <FormField<boolean>
                                    name="fraud.transactions.text"
                                    renderComponent={({ value, onChange }) => (
                                        <div className={styles.switch_wrapper}>
                                            <Typography variant="body3">{t('text')}</Typography>{' '}
                                            <Switch
                                                checked={value}
                                                onClick={() => onChange(!value)}
                                            />
                                        </div>
                                    )}
                                />
                                <FormField<boolean>
                                    name="fraud.transactions.email"
                                    renderComponent={({ value, onChange }) => (
                                        <div className={styles.switch_wrapper}>
                                            <Typography variant="body3">{t('email')}</Typography>{' '}
                                            <Switch
                                                checked={value}
                                                onClick={() => onChange(!value)}
                                            />
                                        </div>
                                    )}
                                />
                            </div>
                        </div>
                        <div className={styles.row}>
                            <Typography variant="body3">{t('fraud.updates')}</Typography>
                            <div className={styles.switches_wrapper}>
                                <FormField<boolean>
                                    name="fraud.updates.text"
                                    renderComponent={({ value, onChange }) => (
                                        <div className={styles.switch_wrapper}>
                                            <Typography variant="body3">{t('text')}</Typography>{' '}
                                            <Switch
                                                checked={value}
                                                onClick={() => onChange(!value)}
                                            />
                                        </div>
                                    )}
                                />
                                <FormField<boolean>
                                    name="fraud.updates.email"
                                    renderComponent={({ value, onChange }) => (
                                        <div className={styles.switch_wrapper}>
                                            <Typography variant="body3">{t('email')}</Typography>{' '}
                                            <Switch
                                                checked={value}
                                                onClick={() => onChange(!value)}
                                            />
                                        </div>
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.header}>
                            <Typography variant="body1" weight="semibold">
                                {t('transactions.title')}
                            </Typography>
                        </div>
                        <div className={styles.row}>
                            <Typography variant="body3">{t('transactions.card')}</Typography>
                            <div className={styles.switches_wrapper}>
                                <FormField<boolean>
                                    name="transactions.new.text"
                                    renderComponent={({ value, onChange }) => (
                                        <div className={styles.switch_wrapper}>
                                            <Typography variant="body3">{t('text')}</Typography>{' '}
                                            <Switch
                                                checked={value}
                                                onClick={() => onChange(!value)}
                                            />
                                        </div>
                                    )}
                                />
                                <FormField<boolean>
                                    name="transactions.new.email"
                                    renderComponent={({ value, onChange }) => (
                                        <div className={styles.switch_wrapper}>
                                            <Typography variant="body3">{t('email')}</Typography>{' '}
                                            <Switch
                                                checked={value}
                                                onClick={() => onChange(!value)}
                                            />
                                        </div>
                                    )}
                                />
                            </div>
                        </div>
                        <div className={styles.row}>
                            <Typography variant="body3">{t('transactions.balance')}</Typography>
                            <div className={styles.switches_wrapper}>
                                <FormField<boolean>
                                    name="transactions.updates.text"
                                    renderComponent={({ value, onChange }) => (
                                        <div className={styles.switch_wrapper}>
                                            <Typography variant="body3">{t('text')}</Typography>{' '}
                                            <Switch
                                                checked={value}
                                                onClick={() => onChange(!value)}
                                            />
                                        </div>
                                    )}
                                />
                                <FormField<boolean>
                                    name="transactions.updates.email"
                                    renderComponent={({ value, onChange }) => (
                                        <div className={styles.switch_wrapper}>
                                            <Typography variant="body3">{t('email')}</Typography>{' '}
                                            <Switch
                                                checked={value}
                                                onClick={() => onChange(!value)}
                                            />
                                        </div>
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.header}>
                            <Typography variant="body1" weight="semibold">
                                {t('reminders.title')}
                            </Typography>
                        </div>
                        <div className={styles.row}>
                            <Typography variant="body3">{t('reminders.card')}</Typography>
                            <div className={styles.switches_wrapper}>
                                <FormField<boolean>
                                    name="reminders.new.text"
                                    renderComponent={({ value, onChange }) => (
                                        <div className={styles.switch_wrapper}>
                                            <Typography variant="body3">{t('text')}</Typography>{' '}
                                            <Switch
                                                checked={value}
                                                onClick={() => onChange(!value)}
                                            />
                                        </div>
                                    )}
                                />
                                <FormField<boolean>
                                    name="reminders.new.email"
                                    renderComponent={({ value, onChange }) => (
                                        <div className={styles.switch_wrapper}>
                                            <Typography variant="body3">{t('email')}</Typography>{' '}
                                            <Switch
                                                checked={value}
                                                onClick={() => onChange(!value)}
                                            />
                                        </div>
                                    )}
                                />
                            </div>
                        </div>
                        <div className={styles.row}>
                            <Typography variant="body3">{t('reminders.balance')}</Typography>
                            <div className={styles.switches_wrapper}>
                                <FormField<boolean>
                                    name="reminders.updates.text"
                                    renderComponent={({ value, onChange }) => (
                                        <div className={styles.switch_wrapper}>
                                            <Typography variant="body3">{t('text')}</Typography>{' '}
                                            <Switch
                                                checked={value}
                                                onClick={() => onChange(!value)}
                                            />
                                        </div>
                                    )}
                                />
                                <FormField<boolean>
                                    name="reminders.updates.email"
                                    renderComponent={({ value, onChange }) => (
                                        <div className={styles.switch_wrapper}>
                                            <Typography variant="body3">{t('email')}</Typography>{' '}
                                            <Switch
                                                checked={value}
                                                onClick={() => onChange(!value)}
                                            />
                                        </div>
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.header}>
                            <Typography variant="body1" weight="semibold">
                                {t('marketing.title')}
                            </Typography>
                        </div>
                        <div className={styles.row}>
                            <Typography variant="body3">{t('marketing.card')}</Typography>
                            <div className={styles.switches_wrapper}>
                                <FormField<boolean>
                                    name="marketing.new.text"
                                    renderComponent={({ value, onChange }) => (
                                        <div className={styles.switch_wrapper}>
                                            <Typography variant="body3">{t('text')}</Typography>{' '}
                                            <Switch
                                                checked={value}
                                                onClick={() => onChange(!value)}
                                            />
                                        </div>
                                    )}
                                />
                                <FormField<boolean>
                                    name="marketing.new.email"
                                    renderComponent={({ value, onChange }) => (
                                        <div className={styles.switch_wrapper}>
                                            <Typography variant="body3">{t('email')}</Typography>{' '}
                                            <Switch
                                                checked={value}
                                                onClick={() => onChange(!value)}
                                            />
                                        </div>
                                    )}
                                />
                            </div>
                        </div>
                        <div className={styles.row}>
                            <Typography variant="body3">{t('marketing.balance')}</Typography>
                            <div className={styles.switches_wrapper}>
                                <FormField<boolean>
                                    name="marketing.updates.text"
                                    renderComponent={({ value, onChange }) => (
                                        <div className={styles.switch_wrapper}>
                                            <Typography variant="body3">{t('text')}</Typography>{' '}
                                            <Switch
                                                checked={value}
                                                onClick={() => onChange(!value)}
                                            />
                                        </div>
                                    )}
                                />
                                <FormField<boolean>
                                    name="marketing.updates.email"
                                    renderComponent={({ value, onChange }) => (
                                        <div className={styles.switch_wrapper}>
                                            <Typography variant="body3">{t('email')}</Typography>{' '}
                                            <Switch
                                                checked={value}
                                                onClick={() => onChange(!value)}
                                            />
                                        </div>
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </FormProvider>
        </motion.div>
    );
};

export default SettingsNotificationsLayout;
