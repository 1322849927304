import { useProgressBar } from '@hooks/helpers/useProgressBar';
import styles from './styles.module.scss';
import ProgressBar, { TProgressItem } from '@components/progress_bar';
import Info from '@sections/send_and_request/request_money/info';
import Verification from '@sections/send_and_request/request_money/verification';
import Success from '@sections/send_and_request/request_money/success';
import { useForm } from '@hooks/helpers/useForm';
import { FormProvider } from 'react-hook-form';
import { apiAccountService } from '@api';
import { useLocales } from '@hooks/helpers/useLocales';
import { motion } from 'framer-motion';
import { pageAnimation } from '@const/animation';
import { useApiMutation } from '@hooks/api/useApiMutation';
import { eTransferRequestMoneyProgressSteps } from '@const/progress_bar_steps';
import { useState } from 'react';
import { ESendRequestMoneyMethod } from '@enum';
import SendMethodSection from '@sections/send_and_request/request_money/method';
import RecipientSection from '@sections/send_and_request/request_money/recipient';

const RequestMoneySection = () => {
    const { availableStep, prevStep, nextStep, currentStep, changeStep } = useProgressBar(
        eTransferRequestMoneyProgressSteps
    );
    const form = useForm({});
    const { requestSuccessLocale, requestErrorLocale } = useLocales();
    const [sendMethod, setSendMethod] = useState<ESendRequestMoneyMethod>(
        ESendRequestMoneyMethod.INTERNAL
    );
    const [isMethodChosen, setIsMethodChosen] = useState(false);

    // const { data: contactList } = useApiQuery({
    //     method: () => apiWalletService.getContactList()
    // });

    const { handleRequest: onSubmit, isLoading } = useApiMutation({
        method: () => {
            const values = form.getValues();
            return apiAccountService.requestMoneyETransfer({
                contactId: values.contact,
                amount: Number(values.amount),
                message: values.message
            });
        },
        onSuccess: () => {
            requestSuccessLocale('request_money');
            nextStep();
        },
        onError: requestErrorLocale
    });

    const renderContent = () => {
        switch (currentStep?.id) {
            case '1':
                return (
                    <Info
                        onNextStep={nextStep}
                        onPrevStep={() => setIsMethodChosen(false)}
                        isETransfer={sendMethod === ESendRequestMoneyMethod.E_TRANSFER}
                    />
                );
            case '2':
                return (
                    <RecipientSection
                        onPrevStep={prevStep}
                        onNextStep={nextStep}
                        isETransfer={sendMethod === ESendRequestMoneyMethod.E_TRANSFER}
                    />
                );
            case '3':
                return (
                    <Verification
                        contactList={[]}
                        isLoading={isLoading}
                        onSubmit={() => onSubmit(undefined)}
                        onPrevStep={prevStep}
                    />
                );
            default:
                return <Success onRepeat={() => changeStep('1')} />;
        }
    };

    return (
        <motion.div {...pageAnimation} className={styles.main_wrapper}>
            {isMethodChosen ? (
                <>
                    <div className={styles.progress_wrapper}>
                        <div className={styles.content}>
                            <ProgressBar
                                variant="light"
                                size="large"
                                steps={
                                    eTransferRequestMoneyProgressSteps.filter(
                                        (item) => item !== undefined
                                    ) as TProgressItem[]
                                }
                                availableStep={availableStep}
                                currentStep={currentStep}
                                className={styles.progress}
                            />
                        </div>
                    </div>
                    <FormProvider {...form}>{renderContent()}</FormProvider>
                </>
            ) : (
                <SendMethodSection
                    method={sendMethod}
                    onChangeMethod={(val) => setSendMethod(val)}
                    onSubmit={() => setIsMethodChosen(true)}
                />
            )}
        </motion.div>
    );
};

export default RequestMoneySection;
