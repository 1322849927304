import React from 'react';
import Typography from '@components/common/typography';
import Input from '@components/common/input';
import styles from './styles.module.scss';
import { Link } from 'react-router-dom';
import { routes } from '@const/routes';
import { useLocales } from '@hooks/helpers/useLocales';
import { useTranslation } from 'react-i18next';

const CardDetailsEnterPasswordModal = () => {
    const { t } = useTranslation('modals', {
        keyPrefix: 'enter_password'
    });
    const { labelLocale } = useLocales();
    return (
        <div className={styles.wrapper}>
            <Typography className={styles.title} variant="h4">
                {t('title')}
            </Typography>
            <Typography className={styles.description} variant="body2">
                {t('description')}
            </Typography>
            <div className={styles.input_wrapper}>
                <Input
                    label={labelLocale('your_password')}
                    full
                    placeholder="••••••••"
                    type="password"
                />
                <Link to={routes.forgot_password}>Forgot password</Link>
            </div>
        </div>
    );
};

export default CardDetailsEnterPasswordModal;
