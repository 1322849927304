import React, { FC } from 'react';
import Typography from '@components/common/typography';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import Button from '@components/common/button';
import { useLocales } from '@hooks/helpers/useLocales';

interface IProps {
    errors: string[];
    handleStart: () => void;
    onClose: () => void;
}

const KycErrors: FC<IProps> = ({ errors, handleStart, onClose }) => {
    const { t } = useTranslation('shufti_pro');
    const { submitLocale } = useLocales();

    return (
        <>
            <Typography variant="h4">{t('error')}</Typography>
            <div className={styles.errors_content}>
                {errors.map((item, i) => (
                    <div key={i}>⦿ {t(item)}</div>
                ))}
            </div>
            <div className={styles.actions}>
                <Button size="normal" variant="outlined" onClick={onClose}>
                    {submitLocale('cancel')}
                </Button>
                <Button size="normal" variant="primary" onClick={handleStart}>
                    {submitLocale('try_again')}
                </Button>
            </div>
        </>
    );
};

export default KycErrors;
