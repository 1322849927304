import React, { FC } from 'react';
import styles from './styles.module.scss';
import Typography from '@components/common/typography';
import { useTranslation } from 'react-i18next';
import Input from '@components/common/input';
import DatePicker from '@components/date_picker';
import { IModalComponentProps } from '@context/modal_context';

const CardLimitsModal: FC<
    IModalComponentProps<{ usageLimit: string; limitStartDate: string; limitEndDate: string }>
> = ({ modalData, changeModalData }) => {
    const { t } = useTranslation('modals', {
        keyPrefix: 'card_limits'
    });

    return (
        <div className={styles.wrapper}>
            <Typography variant="h4">{t('title')}</Typography>
            <div className={styles.content}>
                <div className={styles.row}>
                    <Input
                        suffix="$"
                        value={modalData?.usageLimit}
                        onChange={(value) => changeModalData({ ...modalData, usageLimit: value })}
                        full
                        label={t('available_limit')}
                    />
                </div>
                <div className={styles.row}>
                    <DatePicker
                        value={modalData?.limitStartDate}
                        onChange={(value) =>
                            changeModalData({ ...modalData, limitStartDate: value })
                        }
                        full
                        label={t('limit_start_date')}
                    />
                </div>
                <div className={styles.row}>
                    <DatePicker
                        value={modalData?.limitEndDate}
                        onChange={(value) => changeModalData({ ...modalData, limitEndDate: value })}
                        full
                        label={t('limit_end_date')}
                    />
                </div>
            </div>
        </div>
    );
};

export default CardLimitsModal;
