import { ECreditCardBrands } from '@enum';

export const getCardBrand = (cardNumber: string) => {
    const cleanedCardNumber = cardNumber.replace(/\D/g, ''); // Remove all non-digit characters

    if (/^4[0-9]{12}(?:[0-9]{3})?$/.test(cleanedCardNumber)) {
        return ECreditCardBrands.VISA;
    } else if (/^5[1-5][0-9]{14}$/.test(cleanedCardNumber)) {
        return ECreditCardBrands.MASTERCARD;
    } else if (/^3[47][0-9]{13}$/.test(cleanedCardNumber)) {
        return ECreditCardBrands.AMEX;
    } else if (/^6(?:011|5[0-9]{2})[0-9]{12}$/.test(cleanedCardNumber)) {
        return ECreditCardBrands.DISCOVER;
    } else {
        return;
    }
};
