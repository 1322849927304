import React, { FC } from 'react';
import Typography from '@components/common/typography';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import Button from '@components/common/button';
import { useLocales } from '@hooks/helpers/useLocales';

interface IProps {
    onClose: () => void;
}

const KycSuccess: FC<IProps> = ({ onClose }) => {
    const { t } = useTranslation('shufti_pro');
    const { submitLocale } = useLocales();

    return (
        <>
            <Typography variant="h4">{t('success')}</Typography>
            <Typography>{t('success_description')}</Typography>
            <div className={styles.actions}>
                <Button size="normal" variant="outlined" onClick={onClose}>
                    {submitLocale('cancel')}
                </Button>
            </div>
        </>
    );
};

export default KycSuccess;
