import React, { FC } from 'react';
import Typography from '@components/common/typography';
import Button from '@components/common/button';
import styles from './styles.module.scss';
import { IModalComponentProps } from '@context/modal_context';

const UserActivityModal: FC<
    IModalComponentProps<any, { logout: () => void; cancel: () => void }>
> = ({ onSubmit, modalData }) => {
    return (
        <div className={styles.wrapper}>
            <Typography variant="h4">Inactivity Detected</Typography>
            <Typography variant="body1">You have been inactive for 15 minutes.</Typography>
            <div className={styles.actions}>
                <Button onClick={() => onSubmit && onSubmit()?.logout()}>
                    Logout ({modalData?.timer}s)
                </Button>
                <Button onClick={() => onSubmit && onSubmit()?.cancel()}>Stay active</Button>
            </div>
        </div>
    );
};

export default UserActivityModal;
