import React, { FC, SVGAttributes } from 'react';

const Amex: FC<SVGAttributes<SVGElement>> = (props) => {
    return (
        <svg {...props} viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M36.6673 10.4993C36.6673 8.66602 35.1673 7.16602 33.334 7.16602H6.66732C4.83398 7.16602 3.33398 8.66602 3.33398 10.4993V30.4993C3.33398 32.3327 4.83398 33.8327 6.66732 33.8327H33.334C35.1673 33.8327 36.6673 32.3327 36.6673 30.4993V10.4993ZM33.334 10.4993L20.0007 18.8327L6.66732 10.4993H33.334ZM33.334 30.4993H6.66732V13.8327L20.0007 22.166L33.334 13.8327V30.4993Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default Amex;
