import React, { useState } from 'react';
import styles from '@layouts/settings/profile/styles.module.scss';
import Typography from '@components/common/typography';
import Button from '@components/common/button';
import Input from '@components/common/input';
import FormField from '@components/form_field';
import Phone from '@components/phone';
import DatePicker from '@components/date_picker';
import { getBirthDisabledDates } from '@utils/index';
import { useApiQuery } from '@hooks/api/useApiQuery';
import { apiUserService } from '@api';
import Radio from '@components/common/radio';
import useModalContext from '@hooks/context/useModalContext';
import { modalIds } from '@const/modals';
import { TEmail, TNewPhone, TPhone } from '@xeppt/xeppt-sdk/types';
import { useTranslation } from 'react-i18next';
import { useLocales } from '@hooks/helpers/useLocales';
import Skeleton from '@components/common/skeleton';

const PersonalSection = () => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'settings.contact'
    });
    const { labelLocale, requestSuccessLocale, requestErrorLocale } = useLocales();
    const { onOpen, onClose, changeModalData, handleChangeLoading } = useModalContext();
    const [isEdit] = useState(false);

    const {
        data: phones,
        handleRequest: refetchPhones,
        isLoading: isPhoneLoading
    } = useApiQuery({
        method: () => apiUserService.getPhones()
    });

    const {
        data: emails,
        handleRequest: refetchEmails,
        isLoading: isEmailLoading
    } = useApiQuery({
        method: () => apiUserService.getEmails()
    });

    const handleOpenAddPhoneModal = () => {
        onOpen({
            modalId: modalIds.SETTINGS_ADD_PHONE_MODAL,
            onSubmit: (data: TNewPhone) => {
                handleChangeLoading(true);
                apiUserService
                    .addPhone(data)
                    .then(({ id }) => {
                        changeModalData({ ...data, phoneId: id });
                        onOpen({
                            modalId: modalIds.SETTINGS_VERIFICATION_PHONE,
                            onSubmit: (data: { phoneId: string; code: string }) => {
                                handleChangeLoading(true);
                                apiUserService
                                    .verifyPhone(data?.phoneId, data?.code as string)
                                    .then(() => {
                                        requestSuccessLocale('verify_phone');
                                        onClose();
                                        refetchPhones();
                                    })
                                    .catch(requestErrorLocale)
                                    .finally(() => handleChangeLoading(false));
                            }
                        });
                    })
                    .catch(requestErrorLocale)
                    .finally(() => handleChangeLoading(false));
            }
        });
    };

    const handleOpenAddEmailModal = () => {
        onOpen({
            modalId: modalIds.SETTINGS_ADD_EMAIL_MODAL,
            onSubmit: (data: { email: string }) => {
                handleChangeLoading(true);
                apiUserService
                    .addEmail(data.email as string)
                    .then(({ id }) => {
                        changeModalData({ ...data, emailId: id });
                        handleChangeLoading(true);
                        apiUserService
                            .sendEmailVerificationCode(id)
                            .then(() => {
                                requestSuccessLocale('send_verification_email');
                                onOpen({
                                    modalId: modalIds.SETTINGS_VERIFICATION_EMAIL,
                                    onSubmit: (data: { code: string; emailId: string }) => {
                                        handleChangeLoading(true);
                                        apiUserService
                                            .verifyEMAIL(data.emailId, data?.code as string)
                                            .then(() => {
                                                requestSuccessLocale('verify_email');
                                                onClose();
                                                refetchEmails();
                                            })
                                            .catch(requestErrorLocale)
                                            .finally(() => handleChangeLoading(false));
                                    }
                                });
                            })
                            .catch(requestErrorLocale)
                            .finally(() => handleChangeLoading(false));
                    })
                    .catch(requestErrorLocale)
                    .finally(() => handleChangeLoading(false));
            }
        });
    };

    const handleSetPrimaryPhone = (phone: TPhone) => {
        onOpen({
            modalId: modalIds.USER_VERIFICATION_PHONE,
            onSubmit: (data: { secureId: string; code: string }) => {
                handleChangeLoading(true);
                apiUserService
                    .setPrimaryPhone(phone.id, {
                        id: data?.secureId,
                        code: data?.code
                    })
                    .then(() => {
                        requestSuccessLocale('set_primary_phone');
                        onClose();
                        refetchPhones();
                    })
                    .catch(requestErrorLocale)
                    .finally(() => handleChangeLoading(false));
            }
        });
    };

    const handleSetPrimaryEmail = (email: TEmail) => {
        onOpen({
            modalId: modalIds.USER_VERIFICATION_PHONE,
            onSubmit: (data: { secureId: string; code: string }) => {
                handleChangeLoading(true);
                apiUserService
                    .setPrimaryEmail(email.id, {
                        id: data?.secureId,
                        code: data?.code
                    })
                    .then(() => {
                        requestSuccessLocale('set_primary_email');
                        onClose();
                        refetchEmails();
                    })
                    .catch(requestErrorLocale)
                    .finally(() => handleChangeLoading(false));
            }
        });
    };

    const handleDeleteEmail = (data: TEmail) => {
        changeModalData({ email: data.email });
        onOpen({
            modalId: modalIds.DELETE_EMAIL,
            onSubmit: () => {
                handleChangeLoading(true);
                apiUserService
                    .deleteEmail(data.id)
                    .then(() => {
                        requestSuccessLocale('delete_email');
                        onClose();
                        refetchEmails();
                    })
                    .catch(requestErrorLocale)
                    .finally(() => handleChangeLoading(false));
            }
        });
    };

    const handleDeletePhone = (data: TPhone) => {
        changeModalData({
            phoneCode: data.phoneCode,
            phoneNumber: data.phoneNumber
        });
        onOpen({
            modalId: modalIds.DELETE_PHONE,
            onSubmit: () => {
                handleChangeLoading(true);
                apiUserService
                    .deletePhone(data.id)
                    .then(() => {
                        requestSuccessLocale('delete_phone');
                        onClose();
                        refetchPhones();
                    })
                    .catch(requestErrorLocale)
                    .finally(() => handleChangeLoading(false));
            }
        });
    };

    return (
        <div className={styles.row}>
            <div className={styles.header}>
                <Typography variant="body1" lh="120%" weight="semibold">
                    {t('title')}
                </Typography>
                {/*<Button*/}
                {/*    leftIcon={isEdit ? undefined : 'edit'}*/}
                {/*    variant="outlined"*/}
                {/*    size="normal"*/}
                {/*    onClick={() => {*/}
                {/*        setIsEdit((state) => !state);*/}
                {/*    }}*/}
                {/*    disabled={isEdit && !isValid}>*/}
                {/*    {isEdit ? submitLocale('save') : submitLocale('edit')}*/}
                {/*</Button>*/}
            </div>
            <div className={styles.inputs_wrapper}>
                <div className={styles.input_wrapper}>
                    <FormField
                        name="profile.firstName"
                        renderComponent={(props) => (
                            <Input
                                label={labelLocale('first_name')}
                                {...props}
                                full
                                readOnly={!isEdit}
                            />
                        )}
                    />
                </div>
                <div className={styles.input_wrapper}>
                    <FormField
                        name="profile.lastName"
                        renderComponent={(props) => (
                            <Input
                                label={labelLocale('last_name')}
                                {...props}
                                full
                                readOnly={!isEdit}
                            />
                        )}
                    />
                </div>
                <div className={styles.input_wrapper}>
                    <FormField
                        name="profile.birthDate"
                        renderComponent={(props) => (
                            <DatePicker
                                {...props}
                                label={labelLocale('birth_date')}
                                full
                                // readOnly
                                readOnly={!isEdit}
                                maxDate={getBirthDisabledDates()}
                            />
                        )}
                    />
                </div>
                <div className={styles.input_wrapper}>
                    <FormField
                        name="profile.occupancy"
                        renderComponent={(props) => (
                            <Input
                                label={labelLocale('occupation')}
                                {...props}
                                full
                                readOnly={!isEdit}
                            />
                        )}
                    />
                </div>
                <div className={styles.phones_emails_wrapper}>
                    <div className={styles.row}>
                        {isPhoneLoading && (
                            <Skeleton
                                className={`${styles.phone_skeleton} ${styles.input_wrapper}`}
                            />
                        )}
                        {phones &&
                            phones
                                .sort((a) => (a.isPrimary ? 1 : -1))
                                .map((item, i) => (
                                    <div key={item.id} className={styles.phone_email_row}>
                                        <div className={styles.input_wrapper}>
                                            <Phone
                                                key={item.id}
                                                readOnly
                                                isForm={false}
                                                label={labelLocale('phone_number')}
                                                phoneCode={item.phoneCode}
                                                phoneNumber={item.phoneNumber}
                                                onCodeChange={() => {}}
                                                onPhoneChange={() => {}}
                                            />
                                            <div
                                                className={styles.footer_wrapper}
                                                onClick={() => {
                                                    if (!item.isPrimary) {
                                                        handleSetPrimaryPhone(item);
                                                    }
                                                }}>
                                                <Radio small black checked={item.isPrimary} />
                                                {t('primary_phone')}
                                            </div>
                                        </div>
                                        <div className={styles.phone_email_actions}>
                                            {i === phones.length - 1 && (
                                                <Button
                                                    leftIcon="plus"
                                                    variant="outlined"
                                                    size="small"
                                                    onClick={handleOpenAddPhoneModal}
                                                />
                                            )}
                                            {!item.isPrimary && (
                                                <Button
                                                    leftIcon="delete"
                                                    variant="outlined-red"
                                                    size="small"
                                                    onClick={() => handleDeletePhone(item)}
                                                />
                                            )}
                                        </div>
                                    </div>
                                ))}
                    </div>
                    <div className={styles.row}>
                        {isEmailLoading && (
                            <Skeleton
                                className={`${styles.email_skeleton} ${styles.input_wrapper}`}
                            />
                        )}
                        {emails &&
                            emails
                                .sort((a) => (a.isPrimary ? -1 : 1))
                                .map((item, i) => (
                                    <div
                                        key={item.id}
                                        className={`${styles.phone_email_row} ${emails.length > 1 ? styles.email : styles.not_email}`}>
                                        <div className={styles.input_wrapper}>
                                            <Input
                                                label={labelLocale('email')}
                                                value={item.email}
                                                full
                                                readOnly
                                            />
                                            <div
                                                className={styles.footer_wrapper}
                                                onClick={() => {
                                                    if (!item.isPrimary) {
                                                        handleSetPrimaryEmail(item);
                                                    }
                                                }}>
                                                <Radio small black checked={item.isPrimary} />
                                                {t('primary_email')}
                                            </div>
                                        </div>
                                        <div className={styles.phone_email_actions}>
                                            {i === emails.length - 1 && (
                                                <Button
                                                    leftIcon="plus"
                                                    variant="outlined"
                                                    size="small"
                                                    onClick={handleOpenAddEmailModal}
                                                />
                                            )}
                                            {!item.isPrimary && (
                                                <Button
                                                    leftIcon="delete"
                                                    variant="outlined-red"
                                                    size="small"
                                                    onClick={() => handleDeleteEmail(item)}
                                                />
                                            )}
                                        </div>
                                    </div>
                                ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PersonalSection;
