import React, { FC } from 'react';
import styles from './styles.module.scss';
import Button from '@components/common/button';
import Table from '@components/table';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { EPaymentFrequency, EScheduleStatus, TSchedule } from '@xeppt/xeppt-sdk/types/billing';
import { apiBillingService } from '@api';
import { useLocales } from '@hooks/helpers/useLocales';
import { getBillingStatusList, getFrequencyList } from '@utils/billing';
import { pageAnimation } from '@const/animation';
import { motion } from 'framer-motion';
import { useApiMutation } from '@hooks/api/useApiMutation';

interface IProps {
    onEdit: (data: TSchedule) => void;
    refetchPaymentsList: () => void;
    paymentsList: TSchedule[];
    filter: { paymentFrequency?: EPaymentFrequency[]; status?: EScheduleStatus };
    onFilterChange: (val: {
        paymentFrequency?: EPaymentFrequency[];
        status?: EScheduleStatus;
    }) => void;
}

const PaymentsTable: FC<IProps> = ({
    paymentsList,
    refetchPaymentsList,
    onEdit,
    filter,
    onFilterChange
}) => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'pay_bills.schedule_payments.table'
    });
    const { t: frequencyLocale } = useTranslation('sections', {
        keyPrefix: 'pay_bills.pay_bills.payment_details'
    });
    const { requestErrorLocale, requestSuccessLocale } = useLocales();

    const { handleRequest: handleCancelPayment, isLoading: isCancelPaymentLoading } =
        useApiMutation({
            method: (id: string) => {
                return apiBillingService.cancelSchedule(id);
            },
            onSuccess: () => {
                requestSuccessLocale('cancel_schedule');
                refetchPaymentsList();
            },
            onError: requestErrorLocale
        });

    const { handleRequest: handleDeactivatePayment } = useApiMutation({
        method: (id: string) => {
            return apiBillingService.deactivateSchedule(id);
        },
        onSuccess: () => {
            requestSuccessLocale('pause_schedule');
            refetchPaymentsList();
        },
        onError: requestErrorLocale
    });

    const { handleRequest: handleActivatePayment } = useApiMutation({
        method: (id: string) => {
            return apiBillingService.activateSchedule(id);
        },
        onSuccess: () => {
            requestSuccessLocale('activate_schedule');
            refetchPaymentsList();
        },
        onError: requestErrorLocale
    });

    const handlePauseResumePayment = (id: string, status: EScheduleStatus) => {
        if (status === EScheduleStatus.ACTIVE) {
            handleDeactivatePayment(id);
        } else {
            handleActivatePayment(id);
        }
    };

    const columns = [
        {
            label: t('payee'),
            key: 'payeeName',
            render: (_: string, data: TSchedule) => (
                <div style={{ width: '100%' }}>
                    {data.payeeName} - {data.payeeAccountNumber}
                    <br />
                    {data?.payeeAlias && `${data?.payeeAlias}`}
                </div>
            ),
            width: 250
        },
        {
            label: t('frequency'),
            key: 'paymentFrequency',
            render: (value: EPaymentFrequency) =>
                getFrequencyList(frequencyLocale).find((item) => item.value === value)?.label,
            filterOptions: getFrequencyList(frequencyLocale)
        },
        {
            label: t('status'),
            key: 'status',
            render: (value: EScheduleStatus) =>
                getBillingStatusList(frequencyLocale).find((item) => item.value === value)?.label,
            filterOptions: getBillingStatusList(frequencyLocale)
        },
        {
            label: t('amount'),
            key: 'amount',
            render: (value: number) => `$${value.toFixed(2)}`
        },
        {
            label: 'Payments',
            key: 'remainingPayments',
            render: (value: number) => (value === -1 ? 'Infinite' : value)
        },
        {
            label: t('payment_date'),
            key: 'nextPaymentAt',
            render: (value: string) => moment(value).format('MM/DD/YYYY')
        },
        {
            label: t('actions'),
            key: 'actions',
            render: (_: undefined, data: TSchedule) => (
                <div className={styles.table_actions} key={data.id}>
                    {(data.status === EScheduleStatus.ACTIVE ||
                        data.status === EScheduleStatus.INACTIVE) && (
                        <Button
                            leftIcon={data.status === EScheduleStatus.ACTIVE ? 'pause' : 'play'}
                            onClick={() => handlePauseResumePayment(data.id, data.status)}
                        />
                    )}
                    <Button leftIcon="edit" onClick={() => onEdit(data)} />
                    <Button
                        leftIcon="trash"
                        className={styles.delete}
                        isLoading={isCancelPaymentLoading}
                        onClick={() => handleCancelPayment(data.id)}
                    />
                </div>
            )
        }
    ];

    return (
        <motion.div {...pageAnimation} className={styles.content}>
            <Table
                //@ts-ignore
                columns={columns}
                rows={paymentsList}
                emptyDescription={t('empty_payments')}
                isActions
                //@ts-ignore
                filter={filter}
                onFilterChange={(val) => {
                    onFilterChange({
                        ...filter,
                        ...(val?.paymentFrequency && {
                            //@ts-ignore
                            paymentFrequency: val.paymentFrequency as EPaymentFrequency[]
                        }),
                        ...(val?.status && {
                            status:
                                val.status.length > 0
                                    ? //@ts-ignore
                                      (val.status[0] as EScheduleStatus)
                                    : undefined
                        })
                    });
                }}
            />
        </motion.div>
    );
};

export default PaymentsTable;
