import React from 'react';
import Typography from '@components/common/typography';
import styles from './styles.module.scss';
import illustration from '@svg/illustrations/success_card_activation.svg';
import { useTranslation } from 'react-i18next';

const SuccessActivateCardModal = () => {
    const { t } = useTranslation('modals', {
        keyPrefix: 'activate_card'
    });
    return (
        <div className={styles.wrapper}>
            <img src={illustration} alt="illustration" />
            <Typography className={styles.title} variant="h4">
                {t('title')}
            </Typography>
            <Typography className={styles.description} variant="body2">
                {t('description')}
            </Typography>
        </div>
    );
};

export default SuccessActivateCardModal;
