import React, { FC, SVGAttributes } from 'react';

const Play: FC<SVGAttributes<SVGElement>> = (props) => {
    return (
        <svg {...props} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.5 8.95836L13.77 12.3184L8.5 15.6784V8.95836ZM6.5 5.31836V19.3184L17.5 12.3184L6.5 5.31836Z"
                fill="#282828"
            />
        </svg>
    );
};

export default Play;
