import React from 'react';
import Typography from '@components/common/typography';
import { useTranslation } from 'react-i18next';
import AccountBalance from '@components/account_balance';
import styles from './styles.module.scss';
import { useUserContext } from '@hooks/context/useUserContext';
import { useNavigate } from 'react-router-dom';
import { routes } from '@const/routes';
import { useVerificationGuard } from '@hooks/helpers/useVerificationGuard';
import Skeleton from '@components/common/skeleton';

const BalanceSection = () => {
    const { account, isDataLoading } = useUserContext();
    const { t } = useTranslation('sections', {
        keyPrefix: 'dashboard.balance'
    });
    const navigate = useNavigate();
    const { verifiedAction } = useVerificationGuard();

    return (
        <div className={styles.wrapper}>
            <Typography variant="h4">{t('title')}</Typography>
            <div>
                {isDataLoading ? (
                    <Skeleton className={styles.skeleton} />
                ) : (
                    <AccountBalance
                        onClick={() => verifiedAction(() => navigate(routes.move_money))}
                        amount={account?.balance || 0}
                    />
                )}
            </div>
        </div>
    );
};

export default BalanceSection;
