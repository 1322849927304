import React from 'react';
import './styles.scss';

const Loader = () => {
    return (
        <div className="threee-ds-loader">
            <div className="loader-ring">
                <div className="loader-ring-light"></div>
            </div>
        </div>
    );
};

export default Loader;
