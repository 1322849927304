import React, { FC } from 'react';
import Typography from '@components/common/typography';
import VerificationCode from '@components/verification_code';
import styles from './styles.module.scss';
import { hidePhoneNumber } from '@utils/index';
import { apiWalletService } from '@api';
import { useApiMutation } from '@hooks/api/useApiMutation';
import { useTranslation } from 'react-i18next';
import { useLocales } from '@hooks/helpers/useLocales';
import { useApiQuery } from '@hooks/api/useApiQuery';
import { IModalComponentProps } from '@context/modal_context';

const VerificationPhoneWalletModal: FC<
    IModalComponentProps<{ phoneCode: string; phoneNumber: string; code: string }>
> = ({ modalData, changeModalData }) => {
    const { t } = useTranslation('modals', {
        keyPrefix: 'verification_phone'
    });
    const { requestSuccessLocale, requestErrorLocale } = useLocales();

    const { handleRequest: handleResend } = useApiMutation({
        method: () => apiWalletService.sendTemporaryCode('email'),
        onSuccess: () => requestSuccessLocale('resent_phone_verification'),
        onError: requestErrorLocale
    });

    useApiQuery({
        method: () => apiWalletService.sendTemporaryCode('email'),
        onSuccess: () => requestSuccessLocale('sent_code'),
        onError: requestErrorLocale
    });

    return (
        <div className={styles.wrapper}>
            <Typography variant="h4" className={styles.title}>
                {t('title')}
            </Typography>
            <Typography variant="body3" className={styles.description}>
                {modalData?.phoneCode ? (
                    <>
                        {t('to')}{' '}
                        <b>
                            {modalData?.phoneCode &&
                                (modalData?.phoneCode.includes('+')
                                    ? modalData?.phoneCode
                                    : `+${modalData?.phoneCode}`)}{' '}
                            {hidePhoneNumber(modalData?.phoneNumber)}
                        </b>
                    </>
                ) : (
                    t('to_primary')
                )}
            </Typography>
            <div className={styles.content}>
                <div>
                    <Typography variant="body2" weight="medium">
                        {t('code_title')}
                    </Typography>
                    <button onClick={handleResend}>{t('resend')}</button>
                </div>
                <VerificationCode
                    onChange={(value) => changeModalData({ ...modalData, code: value })}
                />
            </div>
        </div>
    );
};

export default VerificationPhoneWalletModal;
