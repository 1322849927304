import React from 'react';
import illustration from '@svg/illustrations/success_order_card.svg';
import Typography from '@components/common/typography';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';

const SuccessStep = () => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'order_card.success'
    });
    return (
        <div className={styles.wrapper}>
            <img src={illustration} alt="" />
            <Typography variant="body1">{t('title')}</Typography>
        </div>
    );
};

export default SuccessStep;
