import React, { FC, ReactNode, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import styles from './styles.module.scss';
import useClickOutside from '@hooks/helpers/useClickOutside';
import { Icon } from '@components/icons';

interface IProps {
    className?: string;
    items: {
        onClick: () => void;
        label: ReactNode | string;
    }[];
}

const cx = classNames.bind(styles);

const Dropdown: FC<IProps> = ({ className, items }) => {
    const [isOpen, setIsOpen] = useState(false);
    const ref = useRef(null);

    useClickOutside({
        ref,
        callback: () => setIsOpen(false),
        event: 'mousedown'
    });

    return (
        <div className={cx([styles.wrapper, className])} ref={ref}>
            <Icon name="more_vertical" onClick={() => setIsOpen((state) => !state)} />
            <div className={cx([styles.dropdown, { isOpen }])}>
                {items.map((item, i) => {
                    return (
                        <button
                            key={i}
                            onClick={() => {
                                item.onClick();
                                setIsOpen(false);
                            }}>
                            {item.label}
                        </button>
                    );
                })}
            </div>
        </div>
    );
};

export default Dropdown;
