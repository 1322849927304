import React from 'react';
import Typography from '@components/common/typography';
import { Icon } from '@components/icons';
import styles from './styles.module.scss';
import useModalContext from '@hooks/context/useModalContext';
import { modalIds } from '@const/modals';
import { Link } from 'react-router-dom';
import { routes } from '@const/routes';
import { useUserContext } from '@hooks/context/useUserContext';
import { EUserType } from '@xeppt/xeppt-sdk/types';
import { useTranslation } from 'react-i18next';
import { apiUserService } from '@api';
import { useLocales } from '@hooks/helpers/useLocales';
import { motion } from 'framer-motion';
import { pageAnimation } from '@const/animation';

const SettingsLayout = () => {
    const { t } = useTranslation('settings');
    const { requestSuccessLocale, requestErrorLocale } = useLocales();
    const { onOpen, onClose, handleChangeLoading } = useModalContext();
    const { user } = useUserContext();

    const handleOpenChangePasswordModal = () => {
        onOpen({
            modalId: modalIds.USER_VERIFICATION_PHONE,
            onSubmit: (data: { secureId: string; code: string }) => {
                onOpen({
                    modalId: modalIds.SETTINGS_CHANGE_PASSWORD,
                    onSubmit: ({
                        oldPassword,
                        newPassword
                    }: {
                        oldPassword: string;
                        newPassword: string;
                    }) => {
                        handleChangeLoading(true);
                        apiUserService
                            .changePassword(oldPassword, newPassword, {
                                id: data.secureId,
                                code: data.code
                            })
                            .then(() => {
                                requestSuccessLocale('change_password');
                                onClose();
                            })
                            .catch(requestErrorLocale)
                            .finally(() => handleChangeLoading(false));
                    }
                });
            }
        });
    };

    return (
        <motion.div {...pageAnimation} className={styles.wrapper}>
            <Typography variant="h4">{t('title')}</Typography>
            <div className={styles.actions_wrapper}>
                <Link to={routes.settings_profile} className={styles.action}>
                    <div>
                        <span>
                            <Icon name="person" />
                        </span>
                        <Typography variant="body1" weight="medium">
                            {t('my_profile')}
                        </Typography>
                    </div>
                    <Icon name="nav_right" />
                </Link>
                <Link to={routes.settings_notifications} className={styles.action}>
                    <div>
                        <span>
                            <Icon name="person" />
                        </span>
                        <Typography variant="body1" weight="medium">
                            {t('notification')}
                        </Typography>
                    </div>
                    <Icon name="nav_right" />
                </Link>
                {user?.type === EUserType.BUSINESS && (
                    <Link to={routes.settings_business} className={styles.action}>
                        <div>
                            <span>
                                <Icon name="business" />
                            </span>
                            <Typography variant="body1" weight="medium">
                                {t('my_business')}
                            </Typography>
                        </div>
                        <Icon name="nav_right" />
                    </Link>
                )}
                <button className={styles.action} onClick={handleOpenChangePasswordModal}>
                    <div>
                        <span>
                            <Icon name="lock" />
                        </span>
                        <Typography variant="body1" weight="medium">
                            {t('change_password')}
                        </Typography>
                    </div>
                    <Icon name="nav_right" />
                </button>
            </div>
        </motion.div>
    );
};

export default SettingsLayout;
