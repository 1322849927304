import React, { FC } from 'react';
import Typography from '@components/common/typography';
import Input from '@components/common/input';
import styles from './styles.module.scss';
import { Icon } from '@components/icons';
import FormField from '@components/form_field';
import { useTranslation } from 'react-i18next';
import { useLocales } from '@hooks/helpers/useLocales';
import { motion } from 'framer-motion';
import { pageAnimation } from '@const/animation';

// const items = (localization: (val: string) => string) => [
//     {
//         value: 'chequing',
//         label: localization('chequing')
//     },
//     {
//         value: 'saving',
//         label: localization('saving')
//     }
// ];

interface IProps {
    handleChooseOtherMethod: () => void;
}

const SecondStepManualLinkBank: FC<IProps> = ({ handleChooseOtherMethod }) => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'link_card_bank.link_bank.step_2_manual'
    });
    const { labelLocale } = useLocales();
    return (
        <motion.div {...pageAnimation} className={styles.wrapper}>
            <Typography variant="h4">{t('title')}</Typography>
            <div className={styles.content}>
                <div className={styles.left}>
                    <Typography variant="body1">{t('description')}</Typography>
                </div>
                <div className={styles.right}>
                    <FormField
                        name="accountName"
                        renderComponent={(props) => (
                            <Input {...props} full label={labelLocale('account_name')} />
                        )}
                    />
                    <FormField
                        name="transitNumber"
                        renderComponent={(props) => (
                            <Input
                                {...props}
                                full
                                label={labelLocale('transit_number')}
                                mask="99999"
                            />
                        )}
                    />
                    <FormField
                        name="accountNumber"
                        renderComponent={(props) => (
                            <Input
                                {...props}
                                full
                                label={labelLocale('account_number')}
                                mask="9999999"
                            />
                        )}
                    />
                    <FormField
                        name="bankNumber"
                        renderComponent={(props) => (
                            <Input {...props} full onlyNumbers label={labelLocale('bank_number')} />
                        )}
                    />
                </div>
            </div>
            <button className={styles.back} onClick={handleChooseOtherMethod}>
                <Icon name="arrow_left" />
                <p>{t('other_method')}</p>
            </button>
        </motion.div>
    );
};

export default SecondStepManualLinkBank;
