import styles from './styles.module.scss';
import React, { useState } from 'react';
import Contact from '@sections/send_and_request/manage_contacts/contact';
import ContactsTable from '@sections/send_and_request/manage_contacts/contacts_table';
import Typography from '@components/common/typography';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { pageAnimation } from '@const/animation';
import { TContact } from '@xeppt/xeppt-sdk/types';
import SearchSelect from '@components/common/search_select';

const ManageContactsSection = () => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'send_and_request.manage_contacts'
    });
    const [isEdit, setIsEdit] = useState(false);
    const [editableContact, setEditableContact] = useState<TContact>();
    const [chosenPayee, setChosenPayee] = useState('');
    const [search, setSearch] = useState('');

    return (
        <motion.div {...pageAnimation} className={styles.main_wrapper}>
            <div className={styles.wrapper}>
                <div className={styles.content}>
                    <div className={styles.header}>
                        <Typography variant="h4">{t('title')}</Typography>
                    </div>
                    <Typography variant="body3" className={styles.search_description}>
                        Enter the contact’s name to search
                    </Typography>
                    <div className={styles.form_row}>
                        <SearchSelect
                            search={search}
                            onChangeSearch={(val) => setSearch(val)}
                            onChange={(val) => {
                                setIsEdit(true);
                                setChosenPayee(val as string);
                            }}
                            value={chosenPayee}
                            full
                            label={t('search_contact')}
                            items={
                                []?.map((item: any) => ({
                                    label: item.name,
                                    value: item.code
                                })) || []
                            }
                        />
                    </div>
                </div>
                {isEdit ? (
                    <Contact
                        handleBack={() => {
                            setIsEdit(false);
                            // refetchContacts();
                        }}
                        editableContact={editableContact}
                    />
                ) : (
                    <ContactsTable
                        handleEdit={(data: TContact) => {
                            setEditableContact(data);
                            setIsEdit(true);
                        }}
                        // refetchContacts={refetchContacts}
                        refetchContacts={() => {}}
                        contactList={[]}
                        onAdd={() => setIsEdit(true)}
                    />
                )}
            </div>
        </motion.div>
    );
};

export default ManageContactsSection;
