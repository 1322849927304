import React, { FC } from 'react';
import styles from './styles.module.scss';
import TransactionItem from './transaction_item';
import Typography from '@components/common/typography';
import { useNavigate } from 'react-router-dom';
import { routes } from '@const/routes';
import { useTranslation } from 'react-i18next';
import { TCardTransaction, TTransactionLite } from '@xeppt/xeppt-sdk/types';
import Skeleton from '@components/common/skeleton';
import CardTransactionItem from '@components/transactions_table/card_transaction_item';

interface IProps {
    transactions: TTransactionLite[] | TCardTransaction[];
    isTransactionsLoading?: boolean;
    isCard?: boolean;
}

const TransactionTable: FC<IProps> = ({ transactions, isTransactionsLoading, isCard = false }) => {
    const { t } = useTranslation('components', {
        keyPrefix: 'transaction_tables'
    });
    const navigate = useNavigate();
    return (
        <div className={styles.wrapper}>
            {transactions.length === 0 ? (
                <div className={styles.empty_transactions}>
                    <Typography variant="body3">{t('empty')}</Typography>
                </div>
            ) : (
                transactions.map((item, i) => {
                    if (isCard) {
                        return (
                            <CardTransactionItem
                                key={i}
                                transaction={item as TCardTransaction}
                                onClick={(id) => navigate(`${routes.transactions}/${id}`)}
                            />
                        );
                    } else {
                        return (
                            <TransactionItem
                                key={i}
                                transaction={item as TTransactionLite}
                                onClick={(id) => navigate(`${routes.transactions}/${id}`)}
                            />
                        );
                    }
                })
            )}
            {isTransactionsLoading && (
                <>
                    <Skeleton className={styles.skeleton} />
                    <Skeleton className={styles.skeleton} />
                    <Skeleton className={styles.skeleton} />
                </>
            )}
        </div>
    );
};

export default TransactionTable;
