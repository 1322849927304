import Input from '@components/common/input';
import React from 'react';

interface IProps {
    helperText?: string;
    label?: string;
    placeholder?: string;
    onClick?: () => void;
    realValue?: string;
    full?: boolean;
    disabled?: boolean;
    error?: boolean;
    readOnly?: boolean;
    onChange?: (val: string) => void;
}

export const CustomInput = (
    {
        full,
        onClick,
        error,
        realValue,
        placeholder,
        label,
        helperText,
        disabled,
        readOnly,
        onChange
    }: IProps,
    ref: any
) => {
    return (
        <Input
            ref={ref}
            rightIcon="calendar"
            label={label}
            value={realValue}
            onClick={onClick}
            readOnly={readOnly}
            helperText={helperText}
            inputClassName="calendar_input"
            stringClassName="input_wrapper"
            placeholder={placeholder}
            hideErrorIcon
            disabled={disabled}
            onChange={onChange}
            full={full}
            error={error}
        />
    );
};
