import React, { FC } from 'react';
import Typography from '@components/common/typography';
import Button from '@components/common/button';
import Input from '@components/common/input';
import Select from '@components/common/select';
import styles from './styles.module.scss';
import { Icon } from '@components/icons';
import FormField from '@components/form_field';
import { useNavigate } from 'react-router-dom';
import { routes } from '@const/routes';
import { useLocales } from '@hooks/helpers/useLocales';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { pageAnimation } from '@const/animation';
import Skeleton from '@components/common/skeleton';
import { useFormContext } from 'react-hook-form';

interface IProps {
    onNextStep: () => void;
    onPrevStep: () => void;
    isETransfer: boolean;
}

const RecipientSection: FC<IProps> = ({ onNextStep, isETransfer, onPrevStep }) => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'send_and_request.send_money.info'
    });
    const navigate = useNavigate();
    const { labelLocale, submitLocale, validationLocale } = useLocales();
    const form = useFormContext();

    const onSubmit = () => {
        form.trigger().then((isValid) => {
            if (isValid) {
                onNextStep();
            }
        });
    };

    return (
        <motion.div {...pageAnimation} className={styles.wrapper}>
            <div className={styles.content}>
                <div className={styles.header}>
                    <Typography variant="h4">{t('to')}</Typography>
                    <div className={styles.actions}>
                        <Button
                            variant="outlined-dark"
                            size="small"
                            onClick={() => navigate(routes.send_and_request_contacts)}>
                            {submitLocale('add_new')}
                        </Button>
                    </div>
                </div>
                <FormField<string | number>
                    name="contact"
                    renderComponent={(props) => (
                        <Select
                            full
                            label={labelLocale('contact')}
                            items={
                                []?.map((item: any) => ({
                                    label: `${item.firstName} ${item.lastName} (${item.email || `+${item.phone}`})`,
                                    value: item.id
                                })) || []
                            }
                            {...props}
                        />
                    )}
                    rules={{ required: { value: true, message: validationLocale('contact') } }}
                />
                {isETransfer && (
                    <>
                        <Skeleton className={styles.skeleton_info} />
                        <Skeleton className={styles.skeleton} />
                        <Skeleton className={styles.skeleton} />
                        <div className={styles.ddr_info}>
                            <Icon name="info" />
                            <span>{t('autodeposit_title')}</span> - {t('autodeposit_description')}
                        </div>
                        <div className={styles.ddr_info}>
                            <Icon name="info" />
                            {t('autodeposit_off')}
                        </div>
                        <FormField
                            name="securityQuestion"
                            renderComponent={(props) => (
                                <Input full label={labelLocale('security_question')} {...props} />
                            )}
                            rules={{
                                required: {
                                    value: true,
                                    message: validationLocale('invalid_security_question')
                                },
                                validate: (value: string) => {
                                    const cleanValue = value.replaceAll(' ', '');
                                    if (cleanValue.length >= 40) {
                                        return validationLocale('invalid_security_question_length');
                                    }
                                }
                            }}
                        />
                        <FormField
                            name="securityAnswer"
                            renderComponent={(props) => (
                                <Input full label={labelLocale('answer')} {...props} />
                            )}
                            rules={{
                                required: {
                                    value: true,
                                    message: validationLocale('invalid_security_answer')
                                },
                                validate: (value: string) => {
                                    const cleanValue = value.replaceAll(' ', '');
                                    if (cleanValue.length <= 3 || cleanValue.length >= 25) {
                                        return validationLocale('invalid_security_answer_length');
                                    }
                                }
                            }}
                        />
                    </>
                )}
            </div>
            <Button variant="primary" size="normal" onClick={onSubmit}>
                {submitLocale('next')}
            </Button>
            <Button size="normal" leftIcon="arrow_left" onClick={onPrevStep}>
                {submitLocale('back')}
            </Button>
        </motion.div>
    );
};

export default RecipientSection;
