import React, { FC, useEffect, useRef, useState } from 'react';
import useCollapse from '@hooks/helpers/useCollapse';
import Typography from '@components/common/typography';
import Button from '@components/common/button';
import Input from '@components/common/input';
import Select from '@components/common/select';
import Textarea from '@components/common/textarea';
import styles from './styles.module.scss';
import FormField from '@components/form_field';
import { useUserContext } from '@hooks/context/useUserContext';
import { apiAccountService } from '@api';
import { useFormContext, useWatch } from 'react-hook-form';
import useModalContext from '@hooks/context/useModalContext';
import { modalIds } from '@const/modals';
import { useLocales } from '@hooks/helpers/useLocales';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { pageAnimation } from '@const/animation';
import { ESchedulePaymentType, ESendRequestMoneyMethod } from '@enum';
import { getFrequencyList, getLastPaymentDate, getNumberOfPayments } from '@utils/billing';
import DatePicker from '@components/date_picker';
import { getDisablePast } from '@utils/index';
import Radio from '@components/common/radio';
import { EPaymentFrequency } from '@xeppt/xeppt-sdk/types/billing';
import moment from 'moment/moment';

interface IProps {
    onNextStep: () => void;
    onPrevStep: () => void;
    method: ESendRequestMoneyMethod;
}

const Info: FC<IProps> = ({ onNextStep, method, onPrevStep }) => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'send_and_request.send_money.info'
    });
    const { t: recurringLocale } = useTranslation('sections', {
        keyPrefix: 'send_and_request.recurring'
    });

    const { isOpened, onToggle, contentStyles, contentRef } = useCollapse(true);
    const { account, user, refetchAccount } = useUserContext();
    const ref = useRef(null);
    const { onClose, onOpen, handleChangeLoading } = useModalContext();
    const {
        requestSuccessLocale,
        requestErrorLocale,
        labelLocale,
        submitLocale,
        validationLocale
    } = useLocales();
    const form = useFormContext();
    const [isFirstRender, setIsFirstRender] = useState(true);
    const watchedFrequency = useWatch({ name: 'paymentFrequency', control: form.control });
    const watchedAmount = useWatch({ name: 'amount', control: form.control });
    const watchedNextPaymentAt = useWatch({ name: 'nextPaymentAt', control: form.control });
    const watchedNumberOfPayments = useWatch({ name: 'numberOfPayments', control: form.control });
    const watchedPaymentsEndDate = useWatch({
        name: 'watchedPaymentsEndDate',
        control: form.control
    });
    const watchedPaymentType = useWatch({ name: 'paymentType', control: form.control });

    useEffect(() => {
        const resultNumberOfPayments = getNumberOfPayments({
            frequency: watchedFrequency,
            nextPaymentDate: watchedNextPaymentAt,
            lastPaymentDate: watchedPaymentsEndDate
        });
        if (
            watchedNumberOfPayments !== resultNumberOfPayments &&
            watchedPaymentType === ESchedulePaymentType.PAYMENTS_DATE
        ) {
            form.setValue('numberOfPayments', resultNumberOfPayments);
        }
    }, [watchedPaymentsEndDate, watchedFrequency]);

    useEffect(() => {
        const resultLastPaymentDate = getLastPaymentDate({
            frequency: watchedFrequency,
            nextPaymentDate: watchedNextPaymentAt,
            numberOfPayments: watchedNumberOfPayments
        });
        if (
            moment(watchedPaymentsEndDate).format('YYYY-MM-DD') !==
                moment(resultLastPaymentDate).format('YYYY-MM-DD') &&
            watchedPaymentType === ESchedulePaymentType.NUMBER_OF_PAYMENTS
        ) {
            form.setValue('paymentsEndDate', resultLastPaymentDate);
        }
    }, [watchedNumberOfPayments, watchedFrequency]);

    useEffect(() => {
        if (!isFirstRender) {
            watchedAmount && Number(watchedAmount) !== 0 && form.trigger('amount');
        } else {
            setIsFirstRender(false);
        }
    }, [watchedNextPaymentAt, watchedFrequency]);

    const onChangeInterac = () => {
        onOpen({
            modalId: modalIds.UPDATE_INTERAC,
            onSubmit: ({ interacEmail }: { interacEmail: string }) => {
                handleChangeLoading(true);
                apiAccountService
                    .updateInteracEmail(interacEmail)
                    .then(() => {
                        requestSuccessLocale('update_interac');
                        refetchAccount();
                        onClose();
                    })
                    .catch(requestErrorLocale)
                    .finally(() => handleChangeLoading(false));
            }
        });
    };

    const onSubmit = () => {
        form.trigger().then((isValid) => {
            if (isValid) {
                onNextStep();
            }
        });
    };

    const renderDescription = () => {
        switch (method) {
            case ESendRequestMoneyMethod.E_TRANSFER:
                return (
                    <>
                        {t('description')}{' '}
                        <a
                            onClick={() => {
                                !isOpened && onToggle();
                                //@ts-ignore
                                ref?.current?.scrollIntoView({
                                    behavior: 'smooth',
                                    block: 'start'
                                });
                            }}>
                            {t('description_fees')}
                        </a>
                        .
                    </>
                );
            case ESendRequestMoneyMethod.INTERNAL:
                return t('description_internal');
            case ESendRequestMoneyMethod.BANK:
                return t('description_bank');
        }
    };

    return (
        <motion.div {...pageAnimation} className={styles.wrapper}>
            <div className={styles.content}>
                <Typography variant="body3" className={styles.send_disclaimer}>
                    {renderDescription()}
                </Typography>
                {method === ESendRequestMoneyMethod.E_TRANSFER && (
                    <div className={styles.limits}>
                        <Typography variant="body3" weight="semibold">
                            {t('limits_title')}
                        </Typography>
                        <div>
                            <Typography variant="body3" weight="semibold">
                                {t('available')} $
                                {account?.eTransferAccount?.dailyLimit?.toFixed(2)}
                            </Typography>
                            <Typography variant="body3">{t('daily_limit')} $3,000.00</Typography>
                        </div>
                    </div>
                )}
            </div>
            <div className={styles.content}>
                <div className={styles.header}>
                    <Typography variant="h4">{t('from')}</Typography>
                    <div className={styles.actions}>
                        <Typography variant="body3">
                            {user?.profile?.firstName} {user?.profile?.lastName} (
                            {account?.eTransferAccount?.interacEmail})
                        </Typography>
                        <Button leftIcon="edit" onClick={onChangeInterac} />
                    </div>
                </div>
                <Typography variant="body3" className={styles.balance}>
                    {t('current_balance')}: ${(account?.balance || 0)?.toFixed(2)}
                </Typography>
                <div className={styles.form_row}>
                    <FormField
                        name="amount"
                        renderComponent={(props) => (
                            <Input
                                full
                                label={labelLocale('amount')}
                                prefix="CAD"
                                rightIcon="canadian"
                                onlyNumbers
                                hideErrorIcon
                                {...props}
                            />
                        )}
                        rules={{
                            required: { value: true, message: validationLocale('amount') },
                            validate: (amount: number) => {
                                if (method === ESendRequestMoneyMethod.E_TRANSFER) {
                                    if (
                                        watchedFrequency === EPaymentFrequency.ONCE ||
                                        moment(watchedNextPaymentAt).isSame(moment(), 'day')
                                    ) {
                                        if (Number(amount) > (account?.balance || 0)) {
                                            return validationLocale('not_enaught_balance');
                                        } else if (
                                            Number(amount) >
                                            (account?.eTransferAccount?.dailyLimit || 0)
                                        ) {
                                            return validationLocale('not_enaught_daily_limit');
                                        }
                                    }
                                } else {
                                    if (
                                        watchedFrequency === EPaymentFrequency.ONCE ||
                                        moment(watchedNextPaymentAt).isSame(moment(), 'day')
                                    ) {
                                        if (amount > (account?.balance || 0)) {
                                            return validationLocale('not_enaught_balance');
                                        }
                                    }
                                }
                            }
                        }}
                    />
                </div>
                <div className={styles.form_row}>
                    <FormField<string | number>
                        name="paymentFrequency"
                        renderComponent={(props) => (
                            <Select
                                full
                                label={labelLocale('frequency')}
                                {...props}
                                items={getFrequencyList(recurringLocale)}
                            />
                        )}
                        rules={{
                            required: { value: true, message: validationLocale('frequency') }
                        }}
                    />
                    <FormField
                        name="nextPaymentAt"
                        renderComponent={(props) => (
                            <DatePicker
                                label={recurringLocale('next_payment')}
                                minDate={getDisablePast()}
                                full
                                {...props}
                            />
                        )}
                        rules={{
                            required: { value: true, message: validationLocale('next_payment') }
                        }}
                    />
                    {/*{watchedFrequency !== EPaymentFrequency.ONCE && (*/}
                    {/*    <FormField<boolean>*/}
                    {/*        name="isInfinitePayment"*/}
                    {/*        renderComponent={({ value, onChange }) => (*/}
                    {/*            <div className={styles.row}>*/}
                    {/*                <Checkbox*/}
                    {/*                    checked={value}*/}
                    {/*                    onClick={() => {*/}
                    {/*                        onChange(!value);*/}
                    {/*                    }}*/}
                    {/*                />*/}
                    {/*                {t('infinite_payment')}*/}
                    {/*            </div>*/}
                    {/*        )}*/}
                    {/*    />*/}
                    {/*)}*/}
                </div>
                {watchedFrequency !== EPaymentFrequency.ONCE && (
                    <div className={styles.form_row}>
                        <div className={styles.full_row}>
                            <FormField<ESchedulePaymentType>
                                name="paymentType"
                                renderComponent={({ value, onChange }) => (
                                    <>
                                        <div
                                            className={styles.row}
                                            onClick={() =>
                                                onChange(ESchedulePaymentType.PAYMENTS_DATE)
                                            }>
                                            <Radio
                                                small
                                                checked={
                                                    value === ESchedulePaymentType.PAYMENTS_DATE
                                                }
                                            />{' '}
                                            <Typography variant="body3">
                                                {recurringLocale('ending_date')}
                                            </Typography>
                                        </div>
                                        <FormField
                                            name="paymentsEndDate"
                                            renderComponent={(props) => (
                                                <DatePicker
                                                    full
                                                    label={recurringLocale('ending_date')}
                                                    disabled={
                                                        value !== ESchedulePaymentType.PAYMENTS_DATE
                                                    }
                                                    {...props}
                                                />
                                            )}
                                        />
                                    </>
                                )}
                            />
                        </div>
                        <div className={styles.full_row}>
                            <FormField<ESchedulePaymentType>
                                name="paymentType"
                                renderComponent={({ value, onChange }) => (
                                    <>
                                        <div
                                            className={styles.row}
                                            onClick={() =>
                                                onChange(ESchedulePaymentType.NUMBER_OF_PAYMENTS)
                                            }>
                                            <Radio
                                                small
                                                checked={
                                                    value ===
                                                    ESchedulePaymentType.NUMBER_OF_PAYMENTS
                                                }
                                            />{' '}
                                            <Typography variant="body3">
                                                <span>{recurringLocale('or')}</span>{' '}
                                                {recurringLocale('end')}
                                            </Typography>
                                        </div>
                                        <FormField
                                            name="numberOfPayments"
                                            renderComponent={(props) => (
                                                <Input
                                                    disabled={
                                                        value !==
                                                        ESchedulePaymentType.NUMBER_OF_PAYMENTS
                                                    }
                                                    full
                                                    label={labelLocale('number_of_payments')}
                                                    onlyNumbers
                                                    {...props}
                                                />
                                            )}
                                        />
                                    </>
                                )}
                            />
                        </div>
                    </div>
                )}
                <FormField
                    name="message"
                    renderComponent={(props) => (
                        <Textarea full label={labelLocale('message_optional')} {...props} />
                    )}
                />
            </div>
            <Button variant="primary" size="normal" onClick={onSubmit}>
                {submitLocale('next')}
            </Button>
            <Button size="normal" onClick={onPrevStep} leftIcon="arrow_left">
                {submitLocale('back')}
            </Button>
            {method === ESendRequestMoneyMethod.E_TRANSFER && (
                <div className={styles.fees}>
                    <Button onClick={onToggle}>
                        <div className={`${styles.icon} ${isOpened ? styles.isOpen : ''}`} />{' '}
                        <Typography variant="h5">{t('fees')}</Typography>
                    </Button>
                    <div
                        className={`${styles.fees_container} ${isOpened ? styles.isOpen : ''}`}
                        style={contentStyles}
                        ref={contentRef}>
                        <div className={styles.fees_wrapper} ref={ref}>
                            <Typography variant="h5">{t('fee_personal')}</Typography>
                            <div className={styles.row}>
                                <Typography>{t('fee_personal_1')}</Typography>
                                <Typography>{t('fee_personal_2')}</Typography>
                            </div>
                            <div className={styles.row}>
                                <Typography>{t('fee_personal_3')}</Typography>
                                <Typography>$5 {t('fee_personal_4')}</Typography>
                            </div>
                        </div>
                        <div className={styles.fees_wrapper}>
                            <Typography variant="h5">{t('fee_business')}</Typography>
                            <div className={styles.row}>
                                <Typography>{t('fee_business_1')}</Typography>
                                <Typography>$1.50 {t('fee_business_2')}</Typography>
                            </div>
                            <div className={styles.row}>
                                <Typography>{t('fee_business_3')}</Typography>
                                <Typography>$5 {t('fee_business_4')}</Typography>
                            </div>
                        </div>
                    </div>
                    <div className={styles.notes}>
                        {t('notes')}
                        <div className={styles.row}>
                            <span>1.</span>
                            <div>{t('notes_1')}</div>
                        </div>
                        <div className={styles.row}>
                            <span>2.</span>
                            <div>{t('notes_2')}</div>
                        </div>
                    </div>
                </div>
            )}
        </motion.div>
    );
};

export default Info;
