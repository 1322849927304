import React, { FC, SVGAttributes } from 'react';

const Favorite: FC<SVGAttributes<SVGElement>> = (props) => {
    return (
        <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12 21.1742L10.55 19.8542C5.4 15.1842 2 12.1042 2 8.32422C2 5.24422 4.42 2.82422 7.5 2.82422C9.24 2.82422 10.91 3.63422 12 4.91422C13.09 3.63422 14.76 2.82422 16.5 2.82422C19.58 2.82422 22 5.24422 22 8.32422C22 12.1042 18.6 15.1842 13.45 19.8642L12 21.1742Z"
                fill="#F5CC82"
            />
        </svg>
    );
};

export default Favorite;
