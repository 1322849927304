import LinkCardOrBankModal from '../UI/modals/dashboard/link_card_bank';
import NotificationsModal from '../UI/modals/settings/notifications';
import VerificationPhoneModal from '../UI/modals/settings/verification_phone';
import ChangePasswordModal from '../UI/modals/settings/change_password';
import ActivateCardModal from '../UI/modals/xeppt_card/success_activate_card/enter_pin';
import SuccessActivateCardModal from '../UI/modals/xeppt_card/success_activate_card';
import CardDetailsEnterPasswordModal from '../UI/modals/xeppt_card/card_details/enter_password';
import CardDetailsModal from '../UI/modals/xeppt_card/card_details';
import LockCardModal from '../UI/modals/xeppt_card/lock_card';
import UnlockCardModal from '../UI/modals/xeppt_card/unlock_card';
import SuccessUpdatePinModal from '../UI/modals/xeppt_card/update_pin';
import UpdatePinModal from '../UI/modals/xeppt_card/update_pin/enter_pin';
import CardLimitsModal from '../UI/modals/xeppt_card/card_limits';
import ReplaceCardReasonModal from '../UI/modals/xeppt_card/replace_card/reason';
import ReplaceCardSubmitModal from '../UI/modals/xeppt_card/replace_card/submit';
import ReplaceCardSuccessModal from '../UI/modals/xeppt_card/replace_card';
import CancelCardReasonModal from '../UI/modals/xeppt_card/cancel_card/reason';
import CancelCardSubmitModal from '../UI/modals/xeppt_card/cancel_card/submit';
import CancelCardSuccessModal from '../UI/modals/xeppt_card/cancel_card';
import ManageCardModal from '../UI/modals/xeppt_card/manage_card';
import VerificationEmailModal from '../UI/modals/settings/verification_email';
import AddPhoneModal from '../UI/modals/settings/add_phone';
import AddEmailModal from '../UI/modals/settings/add_email';
import DeleteEmailModal from '../UI/modals/settings/delete_email';
import LoadCardBalanceModal from '../UI/modals/dashboard/load_card_balance';
import LinkInteracModal from '../UI/modals/dashboard/link_interac';
import VerificationPhoneWalletModal from '../UI/modals/settings/verification_phone_wallet';
import VerificationPhoneUserModal from '../UI/modals/settings/verification_phone_profile';
import UploadAvatarModal from '../UI/modals/settings/upload_avatar';
import UserActivityModal from '../UI/modals/global/user_activity';
import React from 'react';
import { IModalComponentProps } from '@context/modal_context';
import KycModal from '../UI/modals/global/kyc';
import WaitVerificationModal from '../UI/modals/global/wait_verification';
import DeleteContactModal from '../UI/modals/e_transfer/delete_contact';
import SuccessAutodepositModal from '../UI/modals/e_transfer/success_autodeposit';
import DeletePayeeModal from '../UI/modals/pay_bills/delete_payee';
import DeletePaymentModal from '../UI/modals/pay_bills/delete_payment';
import PausePaymentModal from '../UI/modals/pay_bills/pause_payment';
import ResumePaymentModal from '../UI/modals/pay_bills/resume_payment';
import UpdateInteracModal from '../UI/modals/e_transfer/update_interac';
import DeleteAccountModal from '../UI/modals/manage_accounts/delete_account';
import SetPreferredAccountModal from '../UI/modals/manage_accounts/set_prefered';

type ModalType = {
    modalId: string;
    confirmLabel?: string;
    cancelLabel?: string;
    isHeader?: boolean;
    isFooter?: boolean;
    variant: 'medium' | 'small' | 'custom' | 'large';
    Component: React.FC<IModalComponentProps> | null;
};

const modalIds = {
    LINK_BANK_OR_CARD: 'LINK_BANK_OR_CARD',
    SETTINGS_NOTIFICATION: 'SETTINGS_NOTIFICATION',
    SETTINGS_VERIFICATION_PHONE: 'SETTINGS_VERIFICATION_PHONE',
    PIN_VERIFICATION_PHONE: 'PIN_VERIFICATION_PHONE',
    USER_VERIFICATION_PHONE: 'USER_VERIFICATION_PHONE',
    SETTINGS_VERIFICATION_EMAIL: 'SETTINGS_VERIFICATION_EMAIL',
    SETTINGS_ADD_PHONE_MODAL: 'SETTINGS_ADD_PHONE_MODAL',
    SETTINGS_ADD_EMAIL_MODAL: 'SETTINGS_ADD_EMAIL_MODAL',
    SETTINGS_CHANGE_PASSWORD: 'SETTINGS_CHANGE_PASSWORD',
    SETTINGS_UPLOAD_IMAGE: 'SETTINGS_UPLOAD_IMAGE',
    ACTIVATE_CARD: 'ACTIVATE_CARD',
    SUCCESS_ACTIVATE_CARD: 'SUCCESS_ACTIVATE_CARD',
    CARD_DETAILS_ENTER_PASSWORD: 'CARD_DETAILS_ENTER_PASSWORD',
    CARD_DETAILS: 'CARD_DETAILS',
    LOCK_CARD: 'LOCK_CARD',
    UNLOCK_CARD: 'UNLOCK_CARD',
    UPDATE_PIN: 'UPDATE_PIN',
    SUCCESS_UPDATE_PIN: 'SUCCESS_UPDATE_PIN',
    CARD_LIMITS: 'CARD_LIMITS',
    REPLACE_CARD_REASON: 'REPLACE_CARD_REASON',
    REPLACE_CARD_SUBMIT: 'REPLACE_CARD_SUBMIT',
    REPLACE_CARD_SUCCESS: 'REPLACE_CARD_SUCCESS',
    CANCEL_CARD_REASON: 'CANCEL_CARD_REASON',
    CANCEL_CARD_SUBMIT: 'CANCEL_CARD_SUBMIT',
    CANCEL_CARD_SUCCESS: 'CANCEL_CARD_SUCCESS',
    MANAGE_CREDIT_CARD: 'MANAGE_CREDIT_CARD',
    DELETE_EMAIL: 'DELETE_EMAIL',
    LOAD_CARD_BALANCE: 'LOAD_CARD_BALANCE',
    LINK_INTERAC_EMAIL: 'LINK_INTERAC_EMAIL',
    USER_ACTIVITY_MODAL: 'USER_ACTIVITY_MODAL',
    KYC_FIRST_MODAL: 'KYC_FIRST_MODAL',
    VERIFICATION_PENDING: 'VERIFICATION_PENDING',
    DELETE_PHONE: 'DELETE_PHONE',
    DELETE_CONTACT: 'DELETE_CONTACT',
    SUCCESS_AUTODEPOSIT: 'SUCCESS_AUTODEPOSIT',
    DELETE_PAYEE: 'DELETE_PAYEE',
    DELETE_PAYMENT: 'DELETE_PAYMENT',
    PAUSE_PAYMENT: 'PAUSE_PAYMENT',
    RESUME_PAYMENT: 'RESUME_PAYMENT',
    UPDATE_INTERAC: 'UPDATE_INTERAC',
    DELETE_BANK_ACCOUNT: 'DELETE_BANK_ACCOUNT',
    PREFERRED_ACCOUNT: 'PREFERRED_ACCOUNT'
};

const linkBankOrCardModal: ModalType = {
    modalId: modalIds.LINK_BANK_OR_CARD,
    variant: 'small',
    Component: LinkCardOrBankModal
};

const settingsNotificationsModal: ModalType = {
    modalId: modalIds.SETTINGS_NOTIFICATION,
    confirmLabel: 'done',
    isHeader: true,
    isFooter: true,
    variant: 'small',
    Component: NotificationsModal
};

const settingsVerificationPhoneModal: ModalType = {
    modalId: modalIds.SETTINGS_VERIFICATION_PHONE,
    confirmLabel: 'verify',
    isHeader: true,
    isFooter: true,
    variant: 'medium',
    Component: VerificationPhoneModal
};

const settingsVerificationEmailModal: ModalType = {
    modalId: modalIds.SETTINGS_VERIFICATION_EMAIL,
    confirmLabel: 'verify',
    isHeader: true,
    isFooter: true,
    variant: 'medium',
    Component: VerificationEmailModal
};

const settingsAddPhoneModal: ModalType = {
    modalId: modalIds.SETTINGS_ADD_PHONE_MODAL,
    confirmLabel: 'verify',
    cancelLabel: 'cancel',
    isHeader: true,
    isFooter: true,
    variant: 'medium',
    Component: AddPhoneModal
};

const settingsAddEmailModal: ModalType = {
    modalId: modalIds.SETTINGS_ADD_EMAIL_MODAL,
    confirmLabel: 'verify',
    cancelLabel: 'cancel',
    isHeader: true,
    isFooter: true,
    variant: 'medium',
    Component: AddEmailModal
};

const settingsChangePasswordModal: ModalType = {
    modalId: modalIds.SETTINGS_CHANGE_PASSWORD,
    confirmLabel: 'save',
    isHeader: true,
    isFooter: true,
    variant: 'medium',
    Component: ChangePasswordModal
};

const activateCardModal: ModalType = {
    modalId: modalIds.ACTIVATE_CARD,
    confirmLabel: 'confirm',
    isHeader: true,
    isFooter: true,
    variant: 'medium',
    Component: ActivateCardModal
};

const successActivateCardModal: ModalType = {
    modalId: modalIds.SUCCESS_ACTIVATE_CARD,
    confirmLabel: 'done',
    isHeader: true,
    isFooter: true,
    variant: 'medium',
    Component: SuccessActivateCardModal
};

const cardDetailsEnterPasswordModal: ModalType = {
    modalId: modalIds.CARD_DETAILS_ENTER_PASSWORD,
    confirmLabel: 'done',
    isHeader: true,
    isFooter: true,
    variant: 'medium',
    Component: CardDetailsEnterPasswordModal
};

const walletPhoneVerification: ModalType = {
    modalId: modalIds.PIN_VERIFICATION_PHONE,
    confirmLabel: 'verify',
    isHeader: true,
    isFooter: true,
    variant: 'medium',
    Component: VerificationPhoneWalletModal
};
const userPhoneVerification: ModalType = {
    modalId: modalIds.USER_VERIFICATION_PHONE,
    confirmLabel: 'verify',
    isHeader: true,
    isFooter: true,
    variant: 'medium',
    Component: VerificationPhoneUserModal
};

const cardDetailsModal: ModalType = {
    modalId: modalIds.CARD_DETAILS,
    confirmLabel: 'done',
    isHeader: true,
    isFooter: true,
    variant: 'medium',
    Component: CardDetailsModal
};

const lockCardModal: ModalType = {
    modalId: modalIds.LOCK_CARD,
    confirmLabel: 'done',
    isHeader: true,
    isFooter: true,
    variant: 'medium',
    Component: LockCardModal
};

const unlockCardModal: ModalType = {
    modalId: modalIds.UNLOCK_CARD,
    confirmLabel: 'done',
    isHeader: true,
    isFooter: true,
    variant: 'medium',
    Component: UnlockCardModal
};

const updatePinModal: ModalType = {
    modalId: modalIds.UPDATE_PIN,
    confirmLabel: 'update',
    isHeader: true,
    isFooter: true,
    variant: 'medium',
    Component: UpdatePinModal
};

const successUpdatePinModal: ModalType = {
    modalId: modalIds.SUCCESS_UPDATE_PIN,
    confirmLabel: 'done',
    isHeader: true,
    isFooter: true,
    variant: 'medium',
    Component: SuccessUpdatePinModal
};

const cardLimitsModal: ModalType = {
    modalId: modalIds.CARD_LIMITS,
    confirmLabel: 'done',
    isHeader: true,
    isFooter: true,
    variant: 'medium',
    Component: CardLimitsModal
};

const replaceCardReasonModal: ModalType = {
    modalId: modalIds.REPLACE_CARD_REASON,
    confirmLabel: 'submit',
    isHeader: true,
    isFooter: true,
    variant: 'medium',
    Component: ReplaceCardReasonModal
};

const replaceCardSubmitModal: ModalType = {
    modalId: modalIds.REPLACE_CARD_SUBMIT,
    confirmLabel: 'yes',
    cancelLabel: 'cancel',
    isHeader: true,
    isFooter: true,
    variant: 'medium',
    Component: ReplaceCardSubmitModal
};

const replaceCardSuccessModal: ModalType = {
    modalId: modalIds.REPLACE_CARD_SUCCESS,
    confirmLabel: 'yes',
    isHeader: true,
    isFooter: true,
    variant: 'medium',
    Component: ReplaceCardSuccessModal
};

const cancelCardReasonModal: ModalType = {
    modalId: modalIds.CANCEL_CARD_REASON,
    confirmLabel: 'submit',
    isHeader: true,
    isFooter: true,
    variant: 'medium',
    Component: CancelCardReasonModal
};

const cancelCardSubmitModal: ModalType = {
    modalId: modalIds.CANCEL_CARD_SUBMIT,
    confirmLabel: 'yes',
    cancelLabel: 'cancel',
    isHeader: true,
    isFooter: true,
    variant: 'medium',
    Component: CancelCardSubmitModal
};

const manageCreditCardModal: ModalType = {
    modalId: modalIds.MANAGE_CREDIT_CARD,
    isHeader: true,
    variant: 'large',
    Component: ManageCardModal
};

const cancelCardSuccessModal: ModalType = {
    modalId: modalIds.CANCEL_CARD_SUCCESS,
    confirmLabel: 'yes',
    isHeader: true,
    isFooter: true,
    variant: 'medium',
    Component: CancelCardSuccessModal
};

const deleteEmail: ModalType = {
    modalId: modalIds.DELETE_EMAIL,
    confirmLabel: 'yes',
    isHeader: true,
    isFooter: true,
    variant: 'medium',
    Component: DeleteEmailModal
};

const deletePhone: ModalType = {
    modalId: modalIds.DELETE_PHONE,
    confirmLabel: 'yes',
    isHeader: true,
    isFooter: true,
    variant: 'medium',
    Component: DeleteEmailModal
};

const loadCardBalanceModal: ModalType = {
    modalId: modalIds.LOAD_CARD_BALANCE,
    confirmLabel: 'add_money',
    isHeader: true,
    isFooter: true,
    variant: 'medium',
    Component: LoadCardBalanceModal
};

const linkInteracEmail: ModalType = {
    modalId: modalIds.LINK_INTERAC_EMAIL,
    confirmLabel: 'submit',
    isHeader: true,
    isFooter: true,
    variant: 'small',
    Component: LinkInteracModal
};

const uploadImageModal: ModalType = {
    modalId: modalIds.SETTINGS_UPLOAD_IMAGE,
    isHeader: true,
    variant: 'medium',
    Component: UploadAvatarModal
};

const userActivityModal: ModalType = {
    modalId: modalIds.USER_ACTIVITY_MODAL,
    isHeader: true,
    variant: 'small',
    Component: UserActivityModal
};

const kycFirstModal: ModalType = {
    modalId: modalIds.KYC_FIRST_MODAL,
    isHeader: true,
    variant: 'custom',
    Component: KycModal
};

const verificationPendingModal: ModalType = {
    modalId: modalIds.VERIFICATION_PENDING,
    isHeader: true,
    variant: 'small',
    Component: WaitVerificationModal
};

const deleteContactModal: ModalType = {
    modalId: modalIds.DELETE_CONTACT,
    isHeader: true,
    variant: 'small',
    isFooter: true,
    confirmLabel: 'confirm',
    cancelLabel: 'cancel',
    Component: DeleteContactModal
};

const successAutodepositModal: ModalType = {
    modalId: modalIds.SUCCESS_AUTODEPOSIT,
    isHeader: true,
    variant: 'small',
    isFooter: true,
    confirmLabel: 'done',
    Component: SuccessAutodepositModal
};

const deletePayeeModal: ModalType = {
    modalId: modalIds.DELETE_PAYEE,
    isHeader: true,
    variant: 'small',
    isFooter: true,
    confirmLabel: 'delete',
    cancelLabel: 'cancel',
    Component: DeletePayeeModal
};

const deletePaymentModal: ModalType = {
    modalId: modalIds.DELETE_PAYMENT,
    isHeader: true,
    variant: 'small',
    isFooter: true,
    confirmLabel: 'delete',
    cancelLabel: 'cancel',
    Component: DeletePaymentModal
};

const pausePaymentModal: ModalType = {
    modalId: modalIds.PAUSE_PAYMENT,
    isHeader: true,
    variant: 'small',
    isFooter: true,
    confirmLabel: 'pause',
    cancelLabel: 'cancel',
    Component: PausePaymentModal
};

const resumePaymentModal: ModalType = {
    modalId: modalIds.RESUME_PAYMENT,
    isHeader: true,
    variant: 'small',
    isFooter: true,
    confirmLabel: 'resume',
    cancelLabel: 'cancel',
    Component: ResumePaymentModal
};

const updateInteracModal: ModalType = {
    modalId: modalIds.UPDATE_INTERAC,
    isHeader: true,
    variant: 'small',
    isFooter: true,
    confirmLabel: 'save',
    cancelLabel: 'cancel',
    Component: UpdateInteracModal
};

const deleteAccountModal: ModalType = {
    modalId: modalIds.DELETE_BANK_ACCOUNT,
    isHeader: true,
    variant: 'small',
    isFooter: true,
    confirmLabel: 'remove',
    cancelLabel: 'cancel',
    Component: DeleteAccountModal
};

const setPreferredAccountModal: ModalType = {
    modalId: modalIds.PREFERRED_ACCOUNT,
    isHeader: true,
    variant: 'small',
    isFooter: true,
    confirmLabel: 'confirm',
    cancelLabel: 'cancel',
    Component: SetPreferredAccountModal
};

const modals: ModalType[] = [
    linkInteracEmail,
    loadCardBalanceModal,
    linkBankOrCardModal,
    settingsNotificationsModal,
    settingsVerificationPhoneModal,
    settingsChangePasswordModal,
    activateCardModal,
    successActivateCardModal,
    cardDetailsEnterPasswordModal,
    walletPhoneVerification,
    settingsVerificationEmailModal,
    cardDetailsModal,
    lockCardModal,
    unlockCardModal,
    updatePinModal,
    successUpdatePinModal,
    cardLimitsModal,
    replaceCardReasonModal,
    replaceCardSubmitModal,
    replaceCardSuccessModal,
    cancelCardReasonModal,
    cancelCardSubmitModal,
    cancelCardSuccessModal,
    manageCreditCardModal,
    settingsAddPhoneModal,
    settingsAddEmailModal,
    deleteEmail,
    deletePhone,
    userPhoneVerification,
    uploadImageModal,
    kycFirstModal,
    verificationPendingModal,
    userActivityModal,
    deleteContactModal,
    deletePayeeModal,
    deletePaymentModal,
    resumePaymentModal,
    pausePaymentModal,
    successAutodepositModal,
    updateInteracModal,
    deleteAccountModal,
    setPreferredAccountModal
];

export { modals, modalIds };
