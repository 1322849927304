import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './en.json';
import fr from './fr.json';
import { supportedLanguages } from '@const/index';

export const resources = {
    en,
    fr
};

i18n.use(initReactI18next).init({
    resources,
    fallbackLng: 'en',
    lng: supportedLanguages.includes(navigator.language.split('-')[0])
        ? navigator.language.split('-')[0]
        : 'en',
    interpolation: {
        escapeValue: false
    }
});

export default i18n;
