import { EPaymentFrequency, EScheduleStatus } from '@xeppt/xeppt-sdk/types/billing';
import moment from 'moment/moment';

export function getNumberOfPayments({
    nextPaymentDate,
    lastPaymentDate,
    frequency
}: {
    nextPaymentDate: string;
    lastPaymentDate: string;
    frequency: EPaymentFrequency;
}) {
    const startDate = moment(lastPaymentDate);
    const endDate = moment(nextPaymentDate);

    switch (frequency) {
        case EPaymentFrequency.ONCE:
            return 1; // Only a single payment is made

        case EPaymentFrequency.DAILY:
            return startDate.diff(endDate, 'days') + 1;

        case EPaymentFrequency.WEEKLY:
            return startDate.diff(endDate, 'weeks') + 1;

        case EPaymentFrequency.BIWEEKLY:
            return Math.floor(startDate.diff(endDate, 'days') / 14) + 1;

        case EPaymentFrequency.MONTHLY:
            return startDate.diff(endDate, 'months') + 1;

        case EPaymentFrequency.QUARTERLY:
            return Math.floor(startDate.diff(endDate, 'months') / 3) + 1;

        case EPaymentFrequency.SEMIANNUALLY:
            return Math.floor(startDate.diff(endDate, 'months') / 6) + 1;

        case EPaymentFrequency.ANNUALLY:
            return startDate.diff(endDate, 'years') + 1;

        default:
            throw new Error('Invalid payment frequency');
    }
}

export function getLastPaymentDate({
    nextPaymentDate,
    numberOfPayments,
    frequency
}: {
    nextPaymentDate: string;
    numberOfPayments: number;
    frequency: EPaymentFrequency;
}) {
    const startDate = moment(nextPaymentDate);

    switch (frequency) {
        case EPaymentFrequency.ONCE:
            // If there is only one payment, the last payment date is the same as the start payment date
            return startDate.toISOString();

        case EPaymentFrequency.DAILY:
            return startDate.add(numberOfPayments - 1, 'days').toISOString();

        case EPaymentFrequency.WEEKLY:
            return startDate.add(numberOfPayments - 1, 'weeks').toISOString();

        case EPaymentFrequency.BIWEEKLY:
            return startDate.add((numberOfPayments - 1) * 14, 'days').toISOString();

        case EPaymentFrequency.MONTHLY:
            return startDate.add(numberOfPayments - 1, 'months').toISOString();

        case EPaymentFrequency.QUARTERLY:
            return startDate.add((numberOfPayments - 1) * 3, 'months').toISOString();

        case EPaymentFrequency.SEMIANNUALLY:
            return startDate.add((numberOfPayments - 1) * 6, 'months').toISOString();

        case EPaymentFrequency.ANNUALLY:
            return startDate.add(numberOfPayments - 1, 'years').toISOString();

        default:
            throw new Error('Invalid payment frequency');
    }
}

export const getFrequencyList = (t: any) => [
    { label: t('once'), value: EPaymentFrequency.ONCE },
    { label: t('daily'), value: EPaymentFrequency.DAILY },
    { label: t('weekly'), value: EPaymentFrequency.WEEKLY },
    { label: t('biweekly'), value: EPaymentFrequency.BIWEEKLY },
    { label: t('monthly'), value: EPaymentFrequency.MONTHLY },
    { label: t('quarterly'), value: EPaymentFrequency.QUARTERLY },
    { label: t('semiannually'), value: EPaymentFrequency.SEMIANNUALLY },
    { label: t('annually'), value: EPaymentFrequency.ANNUALLY }
];

export const getBillingStatusList = (t: any) => [
    {
        label: t('active'),
        value: EScheduleStatus.ACTIVE
    },
    {
        label: t('inactive'),
        value: EScheduleStatus.INACTIVE
    },
    {
        label: t('canceled'),
        value: EScheduleStatus.CANCELED
    },
    {
        label: t('closed'),
        value: EScheduleStatus.CLOSED
    }
];
