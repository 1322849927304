import React, { FC } from 'react';
import styles from './styles.module.scss';
import Typography from '@components/common/typography';
import Switch from '@components/common/switch';
import { useTranslation } from 'react-i18next';
import { IModalComponentProps } from '@context/modal_context';

interface IState {
    sms: boolean;
    email: boolean;
}

const NotificationsModal: FC<IModalComponentProps<IState>> = ({ modalData, changeModalData }) => {
    const { t } = useTranslation('modals', {
        keyPrefix: 'notifications'
    });

    return (
        <div className={styles.wrapper}>
            <Typography variant="h4">{t('title')}</Typography>
            <div className={styles.content}>
                <div className={styles.row}>
                    <Typography variant="body1" weight="semibold">
                        {t('message')}
                    </Typography>
                    <Switch
                        checked={modalData?.sms}
                        onClick={() =>
                            changeModalData &&
                            changeModalData({ ...modalData, sms: !modalData?.sms })
                        }
                    />
                </div>
                <div className={styles.row}>
                    <Typography variant="body1" weight="semibold">
                        {t('email')}
                    </Typography>
                    <Switch
                        checked={modalData?.email}
                        onClick={() =>
                            changeModalData &&
                            changeModalData({ ...modalData, email: !modalData?.email })
                        }
                    />
                </div>
            </div>
        </div>
    );
};

export default NotificationsModal;
