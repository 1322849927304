import React, { FC, useEffect } from 'react';
import Typography from '@components/common/typography';
import Input from '@components/common/input';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import useModalContext from '@hooks/context/useModalContext';
import { useForm } from '@hooks/helpers/useForm';
import { defaultSignInData } from '@const/default_form_data';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { validator } from '@xeppt/xeppt-sdk';
import { useLocales } from '@hooks/helpers/useLocales';
import FormField from '@components/form_field';
import { IModalComponentProps } from '@context/modal_context';

const LinkInteracModal: FC<IModalComponentProps<{ interacEmail: string; accountName: string }>> = ({
    modalData,
    changeModalData
}) => {
    const { t } = useTranslation('modals', {
        keyPrefix: 'link_interac'
    });
    const { handleChangeDisable } = useModalContext();
    const { validationLocale, labelLocale } = useLocales();
    const {
        control,
        formState: { isValid }
    } = useForm({
        defaultValues: defaultSignInData,
        resolver: zodResolver(
            z.object({
                interacEmail: validator.email(validationLocale('email')),
                accountName: z.string().min(1, validationLocale('account_name'))
            })
        )
    });

    useEffect(() => {
        handleChangeDisable(!isValid);
    }, [isValid]);

    return (
        <div className={styles.wrapper}>
            <Typography className={styles.title} variant="h4">
                {t('title')}
            </Typography>
            <div className={styles.inputs_wrapper}>
                <div className={styles.input_wrapper}>
                    <FormField
                        control={control}
                        name="accountName"
                        renderComponent={({ onChange, ...props }) => (
                            <Input
                                {...props}
                                label={labelLocale('account_name')}
                                full
                                onChange={(val) => {
                                    onChange(val);
                                    changeModalData({ ...modalData, accountName: val });
                                }}
                            />
                        )}
                    />
                    <FormField
                        control={control}
                        name="interacEmail"
                        renderComponent={({ onChange, ...props }) => (
                            <Input
                                {...props}
                                label={labelLocale('interac_email')}
                                full
                                onChange={(val) => {
                                    onChange(val);
                                    changeModalData({ ...modalData, interacEmail: val });
                                }}
                            />
                        )}
                    />
                </div>
            </div>
        </div>
    );
};

export default LinkInteracModal;
