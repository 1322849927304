import React, { FC, useMemo } from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';

interface IProps {
    className?: string;
}

const cx = classNames.bind(styles);

const Skeleton: FC<IProps> = ({ className }) => {
    const rootStyles = useMemo(() => cx([styles.skeleton, className]), [className]);

    return (
        <div className={rootStyles}>
            <div />
        </div>
    );
};

export default Skeleton;
