import React from 'react';
import Typography from '@components/common/typography';
import styles from './styles.module.scss';

const TermsLayout = () => {
    return (
        <div className={styles.wrapper}>
            <Typography variant="h2">Terms of service</Typography>
            <div className={styles.content}>
                <div>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cumque esse fugiat
                    laboriosam nihil quibusdam sed suscipit veniam vero voluptatibus. Debitis dicta
                    dolores expedita nemo neque nihil pariatur provident qui voluptas.
                </div>
                <div>
                    Adipisci aliquam asperiores aut deleniti deserunt dicta dolorem et impedit iste
                    laudantium magnam, maxime minus mollitia nemo nihil nulla qui quidem recusandae
                    reprehenderit sapiente similique tenetur unde voluptate. Ipsa, totam!
                </div>
                <div>
                    Accusantium architecto dicta eos hic in, iusto maiores modi non pariatur placeat
                    quis quisquam sed, sit tempore voluptatibus? Ad cupiditate dolor hic nam odit
                    praesentium quidem repudiandae sapiente sint sunt?
                </div>
                <div>
                    Blanditiis, error, modi! Commodi corporis, distinctio et in laudantium molestiae
                    non porro possimus quos sit ullam voluptate! Autem consectetur consequuntur
                    dicta earum eos expedita magnam odit porro recusandae voluptates! Nostrum.
                </div>
                <div>
                    Eaque nisi sit voluptas! Ab commodi cum delectus dolorem doloribus eum, fugit
                    inventore itaque minus non omnis qui quis ratione rem saepe sed, sequi sunt
                    veniam vero vitae voluptatem voluptates!
                </div>
                <div>
                    Distinctio eveniet ipsum maiores nam nihil obcaecati odio. A accusamus at
                    consequuntur cupiditate ducimus, enim facilis ipsam, magni mollitia nemo
                    perspiciatis quaerat quam quod rerum sequi tempora velit, vero! Consectetur?
                </div>
                <div>
                    Aperiam commodi cupiditate dignissimos facere hic ipsum iste laborum mollitia
                    omnis pariatur porro quod sapiente soluta, suscipit ut veniam vitae voluptatem?
                    Architecto corporis doloremque harum labore nisi, quaerat sunt voluptas!
                </div>
                <div>
                    A ea eveniet fuga hic ipsum, mollitia non omnis quas quis rem sint soluta.
                    Blanditiis distinctio dolorem harum molestias, repellat voluptatum? Commodi
                    ducimus error excepturi numquam placeat qui quod voluptatem?
                </div>
                <div>
                    Accusantium aliquam at culpa cumque deleniti dolor fuga id ipsum itaque,
                    laboriosam maiores minus modi mollitia nesciunt nobis non odit perferendis,
                    provident quam quia quis quod sit ut vero vitae.
                </div>
                <div>
                    Aperiam esse fuga laborum, nam nemo nesciunt officia quos sapiente velit.
                    Blanditiis impedit quaerat repellat temporibus totam! Aliquam amet at, autem
                    dolore enim exercitationem incidunt magni necessitatibus odit, ratione veniam.
                </div>
                <div>
                    A alias at cum deserunt dignissimos dolore doloribus ducimus esse, est explicabo
                    fuga hic id mollitia neque quaerat, qui soluta, voluptatum. Error facilis
                    maiores obcaecati pariatur, perferendis saepe suscipit temporibus?
                </div>
                <div>
                    Autem facilis natus provident quibusdam soluta ullam voluptas voluptates. A
                    aliquam blanditiis consectetur culpa dignissimos dolorem ea, eaque earum eos
                    esse facilis harum, incidunt laudantium minus repudiandae sunt tempore ut.
                </div>
                <div>
                    A ad asperiores debitis deleniti doloremque eaque esse, est expedita incidunt
                    iste molestias necessitatibus nisi nostrum odit officiis perferendis
                    praesentium, quibusdam quo ratione vero! Amet corporis excepturi exercitationem
                    molestias nihil.
                </div>
                <div>
                    Beatae est excepturi nostrum odit velit. Error, iste laborum modi necessitatibus
                    nihil officia quidem veniam! Culpa mollitia nostrum numquam tempora tempore.
                    Aliquam cum ducimus harum, quos saepe sint suscipit ut!
                </div>
                <div>
                    Alias aliquid aperiam asperiores dolores eius eum, illo incidunt, ipsum nemo
                    nisi numquam odio pariatur, porro quos suscipit. Alias facilis minus molestias
                    nemo quaerat quibusdam rem, similique temporibus ut veritatis?
                </div>
                <div>
                    Ad atque beatae consectetur dolore expedita impedit in inventore ipsam ipsum
                    iste, minima nostrum perferendis placeat porro provident quaerat quidem quisquam
                    reprehenderit, repudiandae temporibus vitae, voluptates voluptatum? Eum, magnam,
                    magni.
                </div>
                <div>
                    Aliquid animi, assumenda atque dicta eveniet laborum minus nesciunt totam! Ad
                    delectus hic, nesciunt quaerat vel voluptatum. At cum esse fugiat fugit illum
                    ipsa labore minus sed unde voluptatum. Molestiae!
                </div>
                <div>
                    Architecto assumenda consequatur corporis, eos excepturi facilis fuga fugiat
                    harum ipsa ipsam laudantium minima natus necessitatibus neque nisi officia
                    porro, provident ratione reiciendis voluptatibus? Ab architecto beatae dolorem
                    molestias voluptas!
                </div>
                <div>
                    Autem, consectetur eius iste nobis, qui quis quo repellendus reprehenderit
                    sapiente sed similique ut voluptatibus voluptatum? Cum doloremque dolorum
                    magnam, necessitatibus neque officia velit voluptate. Adipisci ex id quod quos.
                </div>
                <div>
                    Aspernatur assumenda consectetur consequatur debitis deleniti deserunt dolores
                    ea eligendi enim harum iste minima, natus neque officia placeat possimus quae
                    quaerat quasi quis recusandae sed sequi totam ut vero voluptatem.
                </div>
            </div>
        </div>
    );
};

export default TermsLayout;
