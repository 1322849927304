import React, { FC } from 'react';
import styles from './styles.module.scss';
import Card from '@components/cards/card';
import Button from '@components/common/button';
import Typography from '@components/common/typography';
import { capitalizeFirstLetter, prettifyCardType } from '@utils/index';
import useModalContext from '@hooks/context/useModalContext';
import { modalIds } from '@const/modals';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLocales } from '@hooks/helpers/useLocales';
import { ECardStatus, TCard } from '@xeppt/xeppt-sdk/types';
import { apiWalletService } from '@api';
import { useUserContext } from '@hooks/context/useUserContext';
import Skeleton from '@components/common/skeleton';

interface IProps {
    card?: TCard;
    refetchCard: () => void;
    isCardLoading?: boolean;
}

const CardDataSection: FC<IProps> = ({ card, refetchCard, isCardLoading }) => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'xeppt_card.card_data'
    });
    const { submitLocale, requestErrorLocale, requestSuccessLocale } = useLocales();
    const navigate = useNavigate();
    const { onOpen, onClose, changeModalData, handleChangeLoading } = useModalContext();
    const { refetchAccount } = useUserContext();

    const handleActivateCard = () => {
        onOpen({
            modalId: modalIds.USER_VERIFICATION_PHONE,
            onSubmit: (data: { secureId: string; code: string }) => {
                // onOpen({
                //     modalId: modalIds.ACTIVATE_CARD,
                //     onSubmit: (data) => {
                handleChangeLoading(true);
                apiWalletService
                    .activateCard(card?.id || '', {
                        id: data?.secureId,
                        code: data?.code
                    })
                    .then(() => {
                        refetchCard();
                        onClose();
                        requestSuccessLocale('activate_card');
                    })
                    .catch(requestErrorLocale)
                    .finally(() => handleChangeLoading(false));
                //     }
                // })}
            }
        });
    };

    const handleOpeCardDetails = () => {
        // onOpen({
        //     modalId: modalIds.CARD_DETAILS_ENTER_PASSWORD,
        //     onSubmit: () => {
        //         changeModalData(card)
        //         onOpen({
        //             modalId: modalIds.CARD_DETAILS,
        //             onSubmit: onClose
        //         })
        //     }
        // });
        changeModalData(card);
        onOpen({
            modalId: modalIds.CARD_DETAILS,
            onSubmit: onClose
        });
    };

    // const handleOpenLockCard = () => {
    //     onOpen({
    //         modalId: modalIds.USER_VERIFICATION_PHONE,
    //         onSubmit: () =>
    //             onOpen({
    //                 modalId: modalIds.LOCK_CARD,
    //                 onSubmit: () => onClose
    //             })
    //     });
    // };

    // const handleOpenUnlockCard = () => {
    //     onOpen({
    //         modalId: modalIds.USER_VERIFICATION_PHONE,
    //         onSubmit: () =>
    //             onOpen({
    //                 modalId: modalIds.UNLOCK_CARD,
    //                 onSubmit: () => onClose
    //             })
    //     });
    // };

    const handleOpenManageModal = () => {
        changeModalData({ ...card, refetchCard });
        onOpen({
            modalId: modalIds.MANAGE_CREDIT_CARD
        });
    };

    const handleOpenAddMoneyModal = () => {
        changeModalData(card);
        onOpen({
            modalId: modalIds.LOAD_CARD_BALANCE,
            onSubmit: (data: { amount: string }) => {
                handleChangeLoading(true);
                apiWalletService
                    .loadCardBalance(card?.id || '', Number(data?.amount))
                    .then(() => {
                        onClose();
                        requestSuccessLocale('load_card_balance');
                        refetchCard();
                        refetchAccount();
                    })
                    .catch(requestErrorLocale)
                    .finally(() => handleChangeLoading(false));
            }
        });
    };

    return (
        <div className={styles.wrapper}>
            <Button leftIcon="arrow_left" onClick={() => navigate(-1)}>
                {submitLocale('back')}
            </Button>
            <Typography className={styles.title} variant="h4">
                {capitalizeFirstLetter(prettifyCardType(card?.type))} {t('card')}{' '}
                <span>{card?.cardNumber}</span>
            </Typography>
            <div className={styles.content}>
                {isCardLoading ? (
                    <Skeleton className={styles.card_skeleton} />
                ) : (
                    card && <Card isBalance={true} card={card} />
                )}
                <div className={styles.actions_wrapper}>
                    {card?.status === ECardStatus.ACTIVE && (
                        <div className={styles.action}>
                            <Button
                                variant="icon-dark"
                                leftIcon="plus"
                                onClick={handleOpenAddMoneyModal}
                            />
                            <p>{t('add_money')}</p>
                        </div>
                    )}
                    {(card?.status === ECardStatus.WAITING_FOR_ACTIVATION ||
                        card?.status === ECardStatus.TEMPORARY_CLOSED) && (
                        <div className={styles.action}>
                            <Button variant="icon" leftIcon="power" onClick={handleActivateCard} />
                            <p>{t('activate')}</p>
                        </div>
                    )}
                    <div className={styles.action}>
                        <Button
                            variant="icon"
                            leftIcon="credit_card"
                            onClick={handleOpeCardDetails}
                        />
                        <p>{t('card_details')}</p>
                    </div>
                    {/*{card.status === ECardStatus.ACTIVE && (*/}
                    {/*    <div className={styles.action}>*/}
                    {/*        <Button variant="icon" leftIcon="lock" onClick={handleOpenLockCard} />*/}
                    {/*        <p>{t('lock_card')}</p>*/}
                    {/*    </div>*/}
                    {/*)}*/}
                    {/*{card.status === ECardStatus.CLOSED && (*/}
                    {/*    <div className={styles.action}>*/}
                    {/*        <Button variant="icon" leftIcon="lock" onClick={handleOpenUnlockCard} />*/}
                    {/*        <p>{t('unlock_card')}</p>*/}
                    {/*    </div>*/}
                    {/*)}*/}
                    {card?.status === ECardStatus.ACTIVE && (
                        <div className={styles.action}>
                            <Button
                                variant="icon"
                                leftIcon="settings"
                                onClick={handleOpenManageModal}
                            />
                            <p>{t('manage_ard')}</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default CardDataSection;
