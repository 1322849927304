import React, { FC } from 'react';
import ChooseMethod from '@sections/send_and_request/choose_method';
import { ESendRequestMoneyMethod } from '@enum';
import styles from './styles.module.scss';
import Typography from '@components/common/typography';
import { useTranslation } from 'react-i18next';
import Button from '@components/common/button';
import { useLocales } from '@hooks/helpers/useLocales';

interface IProps {
    onChangeMethod: (val: ESendRequestMoneyMethod) => void;
    method: ESendRequestMoneyMethod;
    onSubmit: () => void;
}

const SendMethodSection: FC<IProps> = ({ onChangeMethod, method, onSubmit }) => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'send_and_request.send_money'
    });
    const { submitLocale } = useLocales();

    return (
        <div className={styles.wrapper}>
            <div className={styles.content}>
                <div className={styles.header}>
                    <Typography variant="h4">{t('title')}</Typography>
                </div>
                <div className={styles.cards_wrapper}>
                    <ChooseMethod
                        checked={method === ESendRequestMoneyMethod.INTERNAL}
                        onClick={() => onChangeMethod(ESendRequestMoneyMethod.INTERNAL)}
                        type={ESendRequestMoneyMethod.INTERNAL}
                    />
                    <ChooseMethod
                        checked={method === ESendRequestMoneyMethod.E_TRANSFER}
                        onClick={() => onChangeMethod(ESendRequestMoneyMethod.E_TRANSFER)}
                        type={ESendRequestMoneyMethod.E_TRANSFER}
                    />
                    <ChooseMethod
                        checked={method === ESendRequestMoneyMethod.BANK}
                        onClick={() => onChangeMethod(ESendRequestMoneyMethod.BANK)}
                        type={ESendRequestMoneyMethod.BANK}
                    />
                    <Button variant="primary" size="normal" onClick={onSubmit}>
                        {submitLocale('next')}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default SendMethodSection;
