import React, { FC } from 'react';
import illustration from '@svg/illustrations/success_link_card_or_bank.svg';
import Typography from '@components/common/typography';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { pageAnimation } from '@const/animation';
import Loader from '@components/loader';
import { useLocales } from '@hooks/helpers/useLocales';
import Button from '@components/common/button';
import { useNavigate } from 'react-router-dom';
import { routes } from '@const/routes';

interface IProps {
    isLoading: boolean;
    changeStep: (val: string) => void;
}

const ThirdStepLinkBank: FC<IProps> = ({ isLoading, changeStep }) => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'link_card_bank.link_bank.step_3'
    });
    const { submitLocale } = useLocales();
    const navigate = useNavigate();
    return (
        <motion.div {...pageAnimation} className={styles.wrapper}>
            {!isLoading && (
                <>
                    <img src={illustration} alt="illustration" />
                    <p className={styles.title}>{t('success')}</p>
                </>
            )}
            {isLoading && <Loader />}
            <Typography className={styles.description} variant="h4">
                {t(isLoading ? 'loading' : 'description')}
            </Typography>
            {!isLoading && (
                <Button
                    size="normal"
                    variant="primary"
                    onClick={() => {
                        navigate(routes.dashboard);
                        changeStep('1');
                    }}>
                    {submitLocale('done')}
                </Button>
            )}
        </motion.div>
    );
};

export default ThirdStepLinkBank;
