import React, { FC, SVGAttributes } from 'react';

const Sync: FC<SVGAttributes<SVGElement>> = (props) => {
    return (
        <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_300_3280)">
                <path
                    d="M12 22.5C13.2031 22.5 14.3555 22.3164 15.457 21.9492C16.5586 21.582 17.5664 21.0586 18.4805 20.3789C19.3945 19.6992 20.1875 18.8867 20.8594 17.9414C21.5312 16.9961 22.0469 15.9414 22.4062 14.7773L23.8359 15.2227C23.4453 16.5273 22.8633 17.7148 22.0898 18.7852C21.3164 19.8555 20.4102 20.7813 19.3711 21.5625C18.332 22.3437 17.1836 22.9414 15.9258 23.3555C14.668 23.7695 13.3594 23.9844 12 24C10.9297 24 9.88672 23.8672 8.87109 23.6016C7.85547 23.3359 6.89844 22.9531 6 22.4531C5.10156 21.9531 4.26562 21.3398 3.49219 20.6133C2.71875 19.8867 2.05469 19.0664 1.5 18.1523V21H0V15H6V16.5H2.29688C2.75781 17.4141 3.32812 18.2383 4.00781 18.9727C4.6875 19.707 5.45313 20.3359 6.30469 20.8594C7.15625 21.3828 8.05859 21.7852 9.01172 22.0664C9.96484 22.3477 10.9609 22.4922 12 22.5ZM24 3V9H18V7.5H21.7031C21.2422 6.58594 20.6719 5.76172 19.9922 5.02734C19.3125 4.29297 18.5469 3.66406 17.6953 3.14062C16.8438 2.61719 15.9414 2.21484 14.9883 1.93359C14.0352 1.65234 13.0391 1.50781 12 1.5C10.7969 1.5 9.64453 1.68359 8.54297 2.05078C7.44141 2.41797 6.43359 2.94141 5.51953 3.62109C4.60547 4.30078 3.8125 5.11328 3.14062 6.05859C2.46875 7.00391 1.95312 8.05859 1.59375 9.22266L0.164062 8.77734C0.554687 7.48047 1.13672 6.29297 1.91016 5.21484C2.68359 4.13672 3.58984 3.21094 4.62891 2.4375C5.66797 1.66406 6.81641 1.06641 8.07422 0.644531C9.33203 0.222656 10.6406 0.0078125 12 0C13.0703 0 14.1133 0.132813 15.1289 0.398438C16.1445 0.664062 17.1016 1.04687 18 1.54688C18.8984 2.04688 19.7344 2.66016 20.5078 3.38672C21.2812 4.11328 21.9453 4.93359 22.5 5.84766V3H24Z"
                    fill="currentColor"
                />
            </g>
            <defs>
                <clipPath id="clip0_300_3280">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default Sync;
