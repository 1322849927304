import React, { FC, ReactNode, useMemo } from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import Button from '../common/button';

interface IProps {
    isActive: boolean;
    children: ReactNode;
    onClick: () => void;
    variant?: 'outlined' | 'outlined-dark';
}

const cx = classNames.bind(styles);
const TableTag: FC<IProps> = ({ children, variant = 'outlined', isActive, onClick }) => {
    const rootStyles = useMemo(() => cx([styles.root, { isActive }, variant]), [isActive, variant]);

    return (
        <Button size="medium" className={rootStyles} onClick={onClick}>
            {children}
        </Button>
    );
};

export default TableTag;
