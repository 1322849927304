import React, { FC } from 'react';
import Typography from '@components/common/typography';
import Button from '@components/common/button';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { useLocales } from '@hooks/helpers/useLocales';
import BankAccountRadio from '@components/cards/bank_account_radio';
import FormField from '@components/form_field';
import { useUserContext } from '@hooks/context/useUserContext';
import { modalIds } from '@const/modals';
import { apiAccountService } from '@api';
import useModalContext from '@hooks/context/useModalContext';

interface IProps {
    handlePrevStep: () => void;
    handleSubmitRequest: () => void;
}

const InteracPaymentStep: FC<IProps> = ({ handlePrevStep }) => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'add_money.interac_payment'
    });
    const { requestSuccessLocale, requestErrorLocale } = useLocales();
    const { account, refetchAccount } = useUserContext();
    const { onOpen, onClose, handleChangeLoading } = useModalContext();

    const handleOpenLinkInteracModal = () => {
        onOpen({
            modalId: modalIds.LINK_INTERAC_EMAIL,
            onSubmit: (data: { interacEmail: string }) => {
                handleChangeLoading(true);
                apiAccountService
                    .linkETransferAccount({
                        interacEmail: data?.interacEmail
                    })
                    .then(() => {
                        requestSuccessLocale('link_interac_email');
                        onClose();
                        refetchAccount();
                    })
                    .catch(requestErrorLocale)
                    .finally(() => handleChangeLoading(false));
            }
        });
    };

    return (
        <div className={styles.wrapper}>
            <Typography className={styles.title} variant="h4">
                {t('title')}
            </Typography>
            <p className={styles.description}>{t('description')}</p>
            <div className={styles.emails}>
                {!!account?.eTransferAccount && (
                    <FormField
                        key={account?.eTransferAccount.id}
                        name="id"
                        renderComponent={({ value, onChange }) => {
                            return (
                                <BankAccountRadio
                                    name={account?.eTransferAccount.interacEmail}
                                    checked={value === account?.eTransferAccount.id}
                                    onChange={() => onChange(account?.eTransferAccount.id)}
                                />
                            );
                        }}
                    />
                )}
            </div>
            <Button
                variant="primary"
                size="normal"
                className={styles.send}
                onClick={handleOpenLinkInteracModal}>
                {t('link_interac')}
            </Button>
            <Button leftIcon="arrow_left" onClick={handlePrevStep} className={styles.back}>
                {t('other_payment')}
            </Button>
        </div>
    );
};

export default InteracPaymentStep;
