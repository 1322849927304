import React, { FC } from 'react';
import Typography from '@components/common/typography';
import Button from '@components/common/button';
// @ts-ignore
import illustration from '@img/success_visa_payment.png';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';

interface IProps {
    handlePrevStep: () => void;
}

const SentCardPaymentStep: FC<IProps> = ({ handlePrevStep }) => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'add_money.sent_card_payment'
    });
    return (
        <div className={styles.wrapper}>
            <Typography variant="h4">{t('title')}</Typography>
            <img src={illustration} alt="illustration" />
            <Button className={styles.back} leftIcon="arrow_left" onClick={handlePrevStep}>
                {t('other_payment')}
            </Button>
        </div>
    );
};

export default SentCardPaymentStep;
