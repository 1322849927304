import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import Typography from '@components/common/typography';
import ChooseTypeSection from '@sections/link_card_bank/choose_type';
import Button from '@components/common/button';
import LinkCardSection from '@sections/link_card_bank/link_card';
import LinkBankSection from '@sections/link_card_bank/link_bank';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { pageAnimation } from '@const/animation';

const LinkCardBankLayout = () => {
    const [type, setType] = useState<'card' | 'bank'>();
    const [preventType, setPreventType] = useState<'card' | 'bank'>('bank');
    const queryParams = new URLSearchParams(location.search);
    const { t } = useTranslation('sections', {
        keyPrefix: 'link_card_bank.link_bank'
    });

    useEffect(() => {
        const loginId = queryParams.get('loginId');
        const accountId = queryParams.get('accountId');

        if (loginId && accountId) {
            setType('bank');
        }
    }, []);

    const handleChoseOtherType = () => {
        setType((state) => {
            if (state === 'card') {
                return 'bank';
            } else {
                return 'card';
            }
        });
    };

    return (
        <motion.div {...pageAnimation} className={styles.wrapper}>
            <Typography variant="h4">
                {!type ? t('title') : type === 'card' ? t('link_card') : t('link_bank')}
            </Typography>
            <div className={styles.content}>
                {!type && (
                    <ChooseTypeSection
                        type={preventType}
                        onChange={(value) => setPreventType(value)}
                    />
                )}
                {type &&
                    (type === 'card' ? (
                        <LinkCardSection handleChoseOtherType={handleChoseOtherType} />
                    ) : (
                        <LinkBankSection handleChoseOtherType={handleChoseOtherType} />
                    ))}
                {!type && (
                    <Button variant="primary" size="normal" onClick={() => setType(preventType)}>
                        Next
                    </Button>
                )}
            </div>
        </motion.div>
    );
};

export default LinkCardBankLayout;
