import React, { FC } from 'react';
import Typography from '@components/common/typography';
import Input from '@components/common/input';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { useLocales } from '@hooks/helpers/useLocales';
import { IModalComponentProps } from '@context/modal_context';

const UpdatePinModal: FC<IModalComponentProps<{ pin: string }>> = ({
    changeModalData,
    modalData
}) => {
    const { t } = useTranslation('modals', {
        keyPrefix: 'update_pin'
    });
    const { labelLocale } = useLocales();

    return (
        <div className={styles.wrapper}>
            <Typography className={styles.title} variant="h4">
                {t('title')}
            </Typography>
            <div className={styles.inputs_wrapper}>
                <div className={styles.input_wrapper}>
                    <Input
                        label={labelLocale('your_new_pin')}
                        full
                        placeholder="••••"
                        type="password"
                        value={modalData?.pin}
                        onChange={(val) => {
                            changeModalData({ ...modalData, pin: val });
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default UpdatePinModal;
