import React, { FC } from 'react';
import Typography from '@components/common/typography';
import styles from './styles.module.scss';
import Select from '@components/common/select';
import { useTranslation } from 'react-i18next';
import { useLocales } from '@hooks/helpers/useLocales';
import { useApiQuery } from '@hooks/api/useApiQuery';
import { apiWalletService } from '@api';
import { IModalComponentProps } from '@context/modal_context';

const ReplaceCardReasonModal: FC<IModalComponentProps<{ reasonId: string | number }>> = ({
    changeModalData,
    modalData
}) => {
    const { t } = useTranslation('modals', {
        keyPrefix: 'replace_card'
    });
    const { labelLocale } = useLocales();
    const { data: replacementReasons } = useApiQuery({
        method: () => apiWalletService.getReplacementReasons()
    });
    return (
        <div className={styles.wrapper}>
            <Typography className={styles.title} variant="h4">
                {t('title')}
            </Typography>
            <div className={styles.inputs_wrapper}>
                <div className={styles.input_wrapper}>
                    <Select
                        label={labelLocale('reason')}
                        value={modalData?.reasonId}
                        onChange={(value) => changeModalData({ ...modalData, reasonId: value })}
                        items={
                            replacementReasons?.map((item) => ({
                                value: item.id,
                                label: item.description
                            })) || []
                        }
                        full
                    />
                </div>
            </div>
        </div>
    );
};

export default ReplaceCardReasonModal;
