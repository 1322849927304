import React, { FC, SVGAttributes } from 'react';

const Refresh: FC<SVGAttributes<SVGElement>> = (props) => {
    return (
        <svg {...props} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M22.0559 7.9375C20.2434 6.125 17.7559 5 14.9934 5C9.46836 5 5.00586 9.475 5.00586 15C5.00586 20.525 9.46836 25 14.9934 25C19.6559 25 23.5434 21.8125 24.6559 17.5H22.0559C21.0309 20.4125 18.2559 22.5 14.9934 22.5C10.8559 22.5 7.49336 19.1375 7.49336 15C7.49336 10.8625 10.8559 7.5 14.9934 7.5C17.0684 7.5 18.9184 8.3625 20.2684 9.725L16.2434 13.75H24.9934V5L22.0559 7.9375Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default Refresh;
