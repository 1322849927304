import React, { FC } from 'react';
import styles from './styles.module.scss';
import Typography from '../common/typography';
import canadian from '@svg/flags/canadian.svg';
import { prettifyAmount } from '@utils/index';

interface IProps {
    amount?: number;
    onClick?: () => void;
}

const AccountBalance: FC<IProps> = ({ amount = 0, onClick }) => {
    return (
        <button
            className={styles.wrapper}
            onClick={() => {
                if (onClick) onClick();
            }}>
            <img src={canadian} alt="canadian flag" />
            <Typography variant="h4">${prettifyAmount(amount.toFixed(2))}</Typography>
        </button>
    );
};

export default AccountBalance;
