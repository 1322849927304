import React, { FC } from 'react';
import Typography from '@components/common/typography';
import Button from '@components/common/button';
import Select from '@components/common/select';
import styles from './styles.module.scss';
import FormField from '@components/form_field';
import { useNavigate } from 'react-router-dom';
import { routes } from '@const/routes';
import { useLocales } from '@hooks/helpers/useLocales';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { pageAnimation } from '@const/animation';
import Skeleton from '@components/common/skeleton';
import { EDdrStatus } from '@xeppt/xeppt-sdk/types';
import { useUserContext } from '@hooks/context/useUserContext';
import { useApiQuery } from '@hooks/api/useApiQuery';
import { apiAccountService } from '@api';
import { modalIds } from '@const/modals';
import useModalContext from '@hooks/context/useModalContext';
import { useFormContext } from 'react-hook-form';

interface IProps {
    onNextStep: () => void;
    onPrevStep: () => void;
    isETransfer: boolean;
}

const RecipientSection: FC<IProps> = ({ onNextStep, onPrevStep }) => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'send_and_request.request_money.info'
    });
    const { user, account, refetchAccount } = useUserContext();
    const { onOpen, onClose, handleChangeLoading } = useModalContext();
    const navigate = useNavigate();
    const form = useFormContext();
    const {
        labelLocale,
        submitLocale,
        validationLocale,
        requestSuccessLocale,
        requestErrorLocale
    } = useLocales();

    const onChangeInterac = () => {
        onOpen({
            modalId: modalIds.UPDATE_INTERAC,
            onSubmit: ({ interacEmail }: { interacEmail: string }) => {
                handleChangeLoading(true);
                apiAccountService
                    .updateInteracEmail(interacEmail)
                    .then(() => {
                        requestSuccessLocale('update_interac');
                        refetchAccount();
                        onClose();
                    })
                    .catch(requestErrorLocale)
                    .finally(() => handleChangeLoading(false));
            }
        });
    };

    const { data: ddrStatus, isLoading: isDdrLoading } = useApiQuery({
        method: () => apiAccountService.checkETransferDdr()
    });

    const onSubmit = () => {
        form.trigger().then((isValid) => {
            if (isValid) {
                onNextStep();
            }
        });
    };

    return (
        <motion.div {...pageAnimation} className={styles.wrapper}>
            <div className={styles.content}>
                <div className={styles.header}>
                    <Typography variant="h4">{t('from')}</Typography>
                    <div className={styles.actions}>
                        <Button
                            variant="outlined-dark"
                            size="small"
                            onClick={() => navigate(routes.send_and_request_contacts)}>
                            {submitLocale('add_new')}
                        </Button>
                    </div>
                </div>
                <FormField<string | number>
                    name="contact"
                    renderComponent={(props) => (
                        <Select
                            full
                            label={labelLocale('contact')}
                            items={
                                []?.map((item: any) => ({
                                    label: `${item.firstName} ${item.lastName} (${item.email || `+${item.phone}`})`,
                                    value: item.id
                                })) || []
                            }
                            {...props}
                        />
                    )}
                    rules={{ required: { value: true, message: validationLocale('contact') } }}
                />
                <div className={styles.content}>
                    <div className={styles.header}>
                        <Typography variant="h4">{t('by')}</Typography>
                        <div className={styles.actions}>
                            <Typography variant="body3">
                                {user?.profile?.firstName} {user?.profile?.lastName} (
                                {account?.eTransferAccount?.interacEmail})
                            </Typography>
                            <Button leftIcon="edit" onClick={onChangeInterac} />
                        </div>
                    </div>
                    {isDdrLoading ? (
                        <Skeleton className={styles.skeleton} />
                    ) : (
                        ddrStatus !== EDdrStatus.ENABLED && (
                            <Typography variant="body3" className={styles.balance}>
                                {t('autodeposit')}
                            </Typography>
                        )
                    )}
                </div>
                <div className={styles.submit_wrapper}>
                    {ddrStatus !== EDdrStatus.ENABLED ? (
                        <Button
                            variant="primary"
                            size="normal"
                            onClick={() => navigate(routes.send_and_request_autodeposit)}>
                            {submitLocale('go_to_autodeposit')}
                        </Button>
                    ) : (
                        <Button variant="primary" size="normal" onClick={onSubmit}>
                            {submitLocale('next')}
                        </Button>
                    )}
                    <Button size="normal" leftIcon="arrow_left" onClick={onPrevStep}>
                        {submitLocale('back')}
                    </Button>
                </div>
            </div>
        </motion.div>
    );
};

export default RecipientSection;
