import React, { FC } from 'react';
import { Icon, TIconType } from '@components/icons';
import Typography from '@components/common/typography';
import Radio from '@components/common/radio';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';

const direction: { title: string; icon: TIconType }[] = [
    {
        title: 'money_in',
        icon: 'arrow_down'
    },
    {
        title: 'money_out',
        icon: 'arrow_up'
    }
];

interface IProps {
    onChange: (value: string) => void;
    active: string;
}

const MoneyDirectionPicker: FC<IProps> = ({ onChange, active }) => {
    const { t } = useTranslation('components', {
        keyPrefix: 'money_directions_picker'
    });
    return (
        <div className={styles.wrapper}>
            {direction.map((item, i) => (
                <button className={styles.card} key={i} onClick={() => onChange(item.title)}>
                    <div className={styles.left}>
                        <span>
                            <Icon name={item.icon} />
                        </span>
                        <Typography>{t(item.title)}</Typography>
                    </div>
                    <Radio checked={active === item.title} />
                </button>
            ))}
        </div>
    );
};

export default MoneyDirectionPicker;
