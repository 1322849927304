import React, { FC } from 'react';
import Typography from '@components/common/typography';
import Input from '@components/common/input';
import Button from '@components/common/button';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { useLocales } from '@hooks/helpers/useLocales';
import FormField from '@components/form_field';
import { useFormContext } from 'react-hook-form';
import { useUserContext } from '@hooks/context/useUserContext';

interface IProps {
    handleNext: () => void;
    isBalance?: boolean;
}

const AmountStep: FC<IProps> = ({ handleNext, isBalance }) => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'add_money.amount'
    });
    const { t: balanceLocale } = useTranslation('sections', {
        keyPrefix: 'send_and_request.send_money.info'
    });
    const { labelLocale, submitLocale, validationLocale } = useLocales();
    const { account } = useUserContext();
    const { trigger } = useFormContext();
    const onSubmit = () => {
        trigger().then((isValid) => {
            if (isValid) {
                handleNext();
            }
        });
    };
    return (
        <div className={styles.wrapper}>
            <Typography variant="h4">{t('title')}</Typography>
            {isBalance && (
                <Typography variant="body3" className={styles.balance}>
                    {balanceLocale('current_balance')}: ${(account?.balance || 0)?.toFixed(2)}
                </Typography>
            )}
            <div className={styles.input_wrapper}>
                <FormField
                    name="amount"
                    renderComponent={(props) => {
                        return (
                            <Input
                                {...props}
                                prefix="CAD"
                                onlyNumbers
                                hideErrorIcon
                                label={labelLocale('amount')}
                                full
                                rightIcon="canadian"
                            />
                        );
                    }}
                    rules={{
                        required: { value: true, message: validationLocale('amount') },
                        validate: (value: string) => {
                            if (!isBalance) {
                                return undefined;
                            }
                            return Number(value) <= (account?.balance || 0)
                                ? undefined
                                : validationLocale('not_enaught_balance');
                        }
                    }}
                />
            </div>
            <Button variant="primary" size="normal" onClick={onSubmit}>
                {submitLocale('next')}
            </Button>
        </div>
    );
};

export default AmountStep;
