import React, { useState } from 'react';
import styles from './styles.module.scss';
import Typography from '@components/common/typography';
import { motion } from 'framer-motion';
import { pageAnimation } from '@const/animation';
import ChooseDirection from '@sections/move_money/choose_direction';
import Button from '@components/common/button';
import { useLocales } from '@hooks/helpers/useLocales';
import AddMoneyLayout from '@sections/move_money/add_money';
import SendMoneyLayout from '@sections/move_money/send_money';

const MoveMoneyLayout = () => {
    const [type, setType] = useState<'send' | 'add'>('add');
    const [content, setContent] = useState(false);
    const { submitLocale } = useLocales();

    const renderContent = () => {
        setContent(true);
    };

    if (content && type === 'add') {
        return <AddMoneyLayout />;
    }
    if (content && type === 'send') {
        return <SendMoneyLayout />;
    }

    return (
        <motion.div {...pageAnimation} className={styles.wrapper}>
            <Typography variant="h4">Move funds</Typography>
            <div className={styles.content_wrapper}>
                <ChooseDirection
                    checked={type === 'add'}
                    onClick={() => setType('add')}
                    type="add"
                />
                <ChooseDirection
                    checked={type === 'send'}
                    onClick={() => setType('send')}
                    type="send"
                />
                <Button variant="primary" size="normal" onClick={renderContent}>
                    {submitLocale('next')}
                </Button>
            </div>
        </motion.div>
    );
};

export default MoveMoneyLayout;
