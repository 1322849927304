export const passwordValidation = (
    password: string | undefined | null,
    t: (val: string) => string
) => {
    const validations = [
        {
            regex: /.{12,}/,
            message: t('password_length')
        },
        {
            regex: /[A-Z]/,
            message: t('password_capitalize')
        },
        {
            regex: /[a-z]/,
            message: t('password_letter')
        },
        {
            regex: /\d/,
            message: t('password_number')
        },
        {
            regex: /[!@#$%^~&*()_+\-=[\]{};':"\\|,.<>/?]+/,
            message: t('password_special')
        }
    ];

    if (password === undefined || password === null) {
        return validations.map(({ message }) => ({
            message,
            isValid: false
        }));
    }

    return validations.map(({ message, regex }) => {
        return {
            message,
            isValid: regex.test(password)
        };
    });
};
