import React, { FC, useEffect } from 'react';
import styles from './styles.module.scss';
import Phone from '@components/phone';
import Typography from '@components/common/typography';
import { useTranslation } from 'react-i18next';
import { useLocales } from '@hooks/helpers/useLocales';
import { IModalComponentProps } from '@context/modal_context';
import { getDefaultPhoneCode } from '@utils/index';

const AddPhoneModal: FC<IModalComponentProps<{ phoneCode: string; phoneNumber: string }>> = ({
    modalData,
    changeModalData
}) => {
    const { t } = useTranslation('modals', {
        keyPrefix: 'add_phone'
    });
    const { labelLocale } = useLocales();

    useEffect(() => {
        changeModalData({ ...modalData, phoneCode: getDefaultPhoneCode() });
    }, []);

    return (
        <div className={styles.wrapper}>
            <Typography className={styles.title} variant="h4" weight="bold">
                {t('title')}
            </Typography>
            <div className={styles.content}>
                <Phone
                    label={labelLocale('enter_phone')}
                    isForm={false}
                    phoneCode={modalData?.phoneCode}
                    phoneNumber={modalData?.phoneNumber}
                    onPhoneChange={(value) => changeModalData({ ...modalData, phoneNumber: value })}
                    onCodeChange={(value) => changeModalData({ ...modalData, phoneCode: value })}
                    selectSize="small"
                />
            </div>
        </div>
    );
};

export default AddPhoneModal;
