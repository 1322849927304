import React, { FC } from 'react';
import Typography from '@components/common/typography';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { IModalComponentProps } from '@context/modal_context';

const DeleteEmailModal: FC<IModalComponentProps<{ email: string }>> = ({ modalData }) => {
    const { t } = useTranslation('modals', {
        keyPrefix: 'delete_email'
    });

    return (
        <div className={styles.wrapper}>
            <Typography variant="h4" className={styles.title}>
                {t('title')}
            </Typography>
            <Typography variant="body2" className={styles.description}>
                {t('sure')} <b>{modalData?.email}</b> {t('sure_second')}
            </Typography>
        </div>
    );
};

export default DeleteEmailModal;
