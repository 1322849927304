import React, { FC } from 'react';
import ReactPaginate from 'react-paginate';
import styles from './styles.module.scss';
import { Icon } from '@components/icons';
import Select from '@components/common/select';

interface IProps {
    className?: string;
    initialPage?: number;
    pageCount: number;
    onPageChange: (page: number) => void;
    onPageSizeChange: (pageSize: number) => void;
    pageSize: number;
}

const Pagination: FC<IProps> = ({
    className,
    pageCount,
    initialPage = 1,
    onPageChange,
    onPageSizeChange,
    pageSize
}) => {
    return (
        <div className={styles.main_wrapper}>
            <ReactPaginate
                breakLabel="..."
                nextLabel={<Icon name="nav_right" />}
                onPageChange={({ selected }) => onPageChange(selected)}
                pageRangeDisplayed={5}
                initialPage={initialPage}
                pageCount={pageCount}
                className={className}
                previousLabel={<Icon name="nav_left" />}
                containerClassName={styles.wrapper}
                pageClassName={styles.page}
                activeClassName={styles.active_page}
                nextClassName={styles.next}
                previousClassName={styles.previous}
            />
            <Select
                onChange={(val) => onPageSizeChange(Number(val))}
                value={pageSize}
                className={styles.select}
                items={[
                    { label: '10', value: 10 },
                    { label: '20', value: 20 },
                    { label: '50', value: 50 },
                    { label: '100', value: 100 }
                ]}
            />
        </div>
    );
};

export default Pagination;
