import React, { FC, useEffect, useState } from 'react';
import styles from '@layouts/settings/profile/styles.module.scss';
import Typography from '@components/common/typography';
import Button from '@components/common/button';
import { FormProvider, useWatch } from 'react-hook-form';
import { apiUserService } from '@api';
import { EAddressType, TBusinessAddress, TIndividualAddress } from '@xeppt/xeppt-sdk/types/user';
import { useForm } from '@hooks/helpers/useForm';
import { defaultAddressData } from '@const/default_form_data';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { validator } from '@xeppt/xeppt-sdk';
import FullAddress from '@components/address';
import { useLocales } from '@hooks/helpers/useLocales';
import { Country } from 'country-state-city';
import { useApiMutation } from '@hooks/api/useApiMutation';

interface IProps {
    label: string;
    name: 'billingAddress' | 'businessAddress' | 'registrationAddress' | 'tradingAddress';
    type: EAddressType;
    data: TBusinessAddress | TIndividualAddress;
}

const AddressSection: FC<IProps> = ({ label, name, type, data }) => {
    const [isHigh, setIsHigh] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const { validationLocale, requestSuccessLocale, submitLocale, requestErrorLocale } =
        useLocales();
    const formMethods = useForm({
        defaultValues: {
            [name]: defaultAddressData
        },
        resolver: zodResolver(
            z.object({
                [name]: validator.address({
                    address1: validationLocale('address1'),
                    address2: validationLocale('address2'),
                    zipCode: validationLocale('zipCode'),
                    city: validationLocale('city'),
                    country: validationLocale('country'),
                    region: validationLocale('region')
                })
            })
        )
    });

    useEffect(() => {
        if (data && name in data) {
            formMethods.reset({
                [name]: {
                    //@ts-ignore
                    ...data[name],
                    country:
                        Country.getAllCountries().find(
                            //@ts-ignore
                            (item) => item.isoCode === data[name]?.country
                            //@ts-ignore
                        )?.name || data[name]?.country
                }
            });
        }
    }, [data]);

    const address = useWatch({ name, control: formMethods.control });

    const { handleRequest: onSubmit, isLoading } = useApiMutation({
        method: () =>
            apiUserService.updateAddress(type, {
                ...address,
                country:
                    Country.getAllCountries().find((item) => item.name === address.country)
                        ?.isoCode || address.country
            }),
        onSuccess: () => {
            setIsEdit((state) => !state);
            requestSuccessLocale('update_address');
        },
        onError: requestErrorLocale
    });

    const onCancel = () => {
        setIsHigh(true);
        if (data && name in data) {
            formMethods.reset({
                [name]: {
                    //@ts-ignore
                    ...data[name]
                }
            });
        }
        setIsEdit(false);
        setTimeout(() => {
            setIsHigh(false);
        }, 0);
    };

    return (
        <div className={styles.row}>
            <FormProvider {...formMethods}>
                <div className={styles.header}>
                    <Typography variant="body1" lh="120%" weight="semibold">
                        {label}
                    </Typography>
                    <div className={styles.address_actions}>
                        {isEdit && (
                            <Button
                                variant="outlined"
                                size="normal"
                                onClick={() => onCancel()}
                                disabled={!isEdit}>
                                Cancel
                            </Button>
                        )}
                        <Button
                            leftIcon={isEdit ? undefined : 'edit'}
                            variant={isEdit ? 'primary' : 'outlined'}
                            size="normal"
                            isLoading={isLoading}
                            onClick={() => {
                                if (isEdit) {
                                    onSubmit(undefined);
                                } else {
                                    setIsEdit(true);
                                }
                            }}
                            disabled={isEdit && !formMethods.formState.isValid}>
                            {isEdit ? submitLocale('save') : submitLocale('edit')}
                        </Button>
                    </div>
                </div>
                <div className={styles.inputs_wrapper}>
                    {!isHigh && <FullAddress name={name} isFull={isEdit} disabled={!isEdit} />}
                </div>
            </FormProvider>
        </div>
    );
};

export default AddressSection;
