import React, { FC } from 'react';
import Typography from '@components/common/typography';
import styles from './styles.module.scss';
import Button from '@components/common/button';
import BankAccountRadio from '@components/cards/bank_account_radio';
import { useTranslation } from 'react-i18next';
import { useUserContext } from '@hooks/context/useUserContext';
import FormField from '@components/form_field';
import { useNavigate } from 'react-router-dom';
import { routes } from '@const/routes';
import { useLocales } from '@hooks/helpers/useLocales';

interface IProps {
    handlePrevStep: () => void;
}

const BankPaymentStep: FC<IProps> = ({ handlePrevStep }) => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'add_money.bank_payment'
    });
    const { account } = useUserContext();
    const { submitLocale } = useLocales();
    const navigate = useNavigate();

    return (
        <div className={styles.wrapper}>
            <div className={styles.card}>
                <Typography variant="h4">{t('title')}</Typography>
                <div className={styles.cards_wrapper}>
                    {account?.bankAccounts.map((item) => {
                        return (
                            <FormField
                                key={item.id}
                                name="id"
                                renderComponent={({ value, onChange }) => {
                                    return (
                                        <BankAccountRadio
                                            name={item.institutionName}
                                            account={`${item.accountName} ${item.accountNumber}`}
                                            checked={value === item.id}
                                            onChange={() => onChange(item.id)}
                                        />
                                    );
                                }}
                            />
                        );
                    })}
                    <Button
                        onClick={() => navigate(routes.link_card_bank)}
                        variant="primary"
                        size="normal">
                        {t('link_new')}
                    </Button>
                </div>
            </div>
            <Button leftIcon="arrow_left" onClick={handlePrevStep}>
                {submitLocale('back')}
            </Button>
        </div>
    );
};

export default BankPaymentStep;
