import React from 'react';
import Typography from '@components/common/typography';
import styles from './styles.module.scss';
import { Icon } from '@components/icons';
import { useTranslation } from 'react-i18next';

const ReplaceCardSubmitModal = () => {
    const { t } = useTranslation('modals', {
        keyPrefix: 'replace_card_submit'
    });
    return (
        <div className={styles.wrapper}>
            <Icon name="error_outlined" width={60} height={60} />
            <Typography className={styles.title} variant="h5">
                {t('title')}
            </Typography>
            <Typography variant="body3">{t('description')}</Typography>
        </div>
    );
};

export default ReplaceCardSubmitModal;
