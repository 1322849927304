import React from 'react';
import { useParams } from 'react-router-dom';
import styles from './styles.module.scss';
import { useApiQuery } from '@hooks/api/useApiQuery';
import { apiWalletService } from '@api';
import CardDataSection from '@sections/xeppt_card/card_data';
import TransactionsSection from '@sections/xeppt_card/transaction_data';
import { motion } from 'framer-motion';
import { pageAnimation } from '@const/animation';

const XepptCardLayout = () => {
    const { id } = useParams();

    const {
        data: card,
        handleRequest: refetchCard,
        isLoading
    } = useApiQuery({
        method: () => apiWalletService.getCardById(id as string),
        condition: !!id
    });

    return (
        <motion.div {...pageAnimation} className={styles.wrapper}>
            <CardDataSection card={card} refetchCard={refetchCard} isCardLoading={isLoading} />
            <TransactionsSection cardId={card?.id} />
        </motion.div>
    );
};

export default XepptCardLayout;
