import React, { FC } from 'react';
import Typography from '@components/common/typography';
import Button from '@components/common/button';
import styles from './styles.module.scss';
import { useNavigate } from 'react-router-dom';
import { routes } from '@const/routes';
import { useNotificationsContext } from '@hooks/context/useNotificationsContext';
import { useLocales } from '@hooks/helpers/useLocales';
import { useTranslation } from 'react-i18next';
import { prettifyDate } from '@utils/date';
import { getNotificationAction, getNotificationString } from '@utils/index';
import { useApiMutation } from '@hooks/api/useApiMutation';
import { apiNotificationService } from '@api';
import { TNotification } from '@xeppt/xeppt-sdk/types/notification';

interface IProps {
    onClose: () => void;
}

const NotificationsDropdown: FC<IProps> = ({ onClose }) => {
    const navigate = useNavigate();
    const { notifications, refetchNotifications } = useNotificationsContext();
    const { t } = useTranslation('notifications');
    const { t: shuftiProLocale } = useTranslation('shufti_pro');
    const { notificationsLocale } = useLocales();

    const { handleRequest: handleReadNotification } = useApiMutation({
        method: (id: string) => apiNotificationService.readNotification(id),
        onSuccess: refetchNotifications
    });

    const onReadNotification = (notification: TNotification) => {
        if (!notification.isRead) {
            handleReadNotification(notification.id);
        }
        onClose();
        getNotificationAction(notification, navigate);
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.head}>
                <Typography fw={500} variant="h5">
                    {t('title')}
                </Typography>
            </div>
            <div className={styles.content}>
                {notifications?.map((item, i) => {
                    if (i > 1) {
                        return null;
                    }
                    return (
                        <div
                            key={item.id}
                            className={styles.item}
                            onClick={() => onReadNotification(item)}>
                            <Typography className={styles.title} fw={400} variant="h5">
                                {notificationsLocale(item.event)}{' '}
                                {getNotificationString(item, shuftiProLocale)}
                            </Typography>
                            <Typography className={styles.time} variant="body3">
                                {prettifyDate(item.createdAt, t)}
                            </Typography>
                            <span className={`${styles.status} ${item.isRead ? '' : styles.red}`} />
                        </div>
                    );
                })}
                <Button
                    onClick={() => {
                        navigate(routes.notifications);
                        onClose();
                    }}
                    className={styles.button}>
                    View all message
                </Button>
            </div>
        </div>
    );
};

export default NotificationsDropdown;
