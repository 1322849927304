import React, { FC, SVGAttributes } from 'react';

const Camera: FC<SVGAttributes<SVGElement>> = (props) => {
    return (
        <svg {...props} viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0 10C0 4.47715 4.47715 0 10 0H40C45.5228 0 50 4.47715 50 10V40C50 45.5228 45.5228 50 40 50H10C4.47715 50 0 45.5228 0 40V10Z"
                fill="#FDF1DC"
            />
            <path
                d="M41.666 10.4167H35.0619L31.2494 6.25H18.7493L14.9368 10.4167H8.33268C6.04102 10.4167 4.16602 12.2917 4.16602 14.5833V39.5833C4.16602 41.875 6.04102 43.75 8.33268 43.75H41.666C43.9577 43.75 45.8327 41.875 45.8327 39.5833V14.5833C45.8327 12.2917 43.9577 10.4167 41.666 10.4167ZM41.666 39.5833H8.33268V14.5833H16.7702L20.5827 10.4167H29.416L33.2285 14.5833H41.666V39.5833ZM24.9994 16.6667C19.2494 16.6667 14.5827 21.3333 14.5827 27.0833C14.5827 32.8333 19.2494 37.5 24.9994 37.5C30.7494 37.5 35.416 32.8333 35.416 27.0833C35.416 21.3333 30.7494 16.6667 24.9994 16.6667ZM24.9994 33.3333C21.5618 33.3333 18.7493 30.5208 18.7493 27.0833C18.7493 23.6458 21.5618 20.8333 24.9994 20.8333C28.4369 20.8333 31.2494 23.6458 31.2494 27.0833C31.2494 30.5208 28.4369 33.3333 24.9994 33.3333Z"
                fill="#BE8317"
            />
        </svg>
    );
};

export default Camera;
