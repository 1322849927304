import React, { ButtonHTMLAttributes, FC, ReactNode, useMemo } from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import { Icon, TIconType } from '@components/icons';

interface IProps {
    variant?:
        | 'gold'
        | 'secondary'
        | 'primary'
        | 'icon'
        | 'icon-dark'
        | 'outlined'
        | 'outlined-dark'
        | 'outlined-red';
    size?: 'small' | 'normal' | 'large' | 'medium';
    notify?: boolean;
    notifyCount?: number;
    full?: boolean;
    disabled?: boolean;
    leftIcon?: TIconType;
    rightIcon?: TIconType;
    children?: ReactNode;
    iconSize?: number;
    isLoading?: boolean;
    className?: string;
}

const cx = classNames.bind(styles);
const Button: FC<IProps & ButtonHTMLAttributes<HTMLButtonElement>> = ({
    variant,
    size,
    children,
    className,
    leftIcon,
    rightIcon,
    disabled,
    full,
    notifyCount = 0,
    notify,
    isLoading,
    iconSize,
    ...props
}) => {
    const rootStyles = useMemo(
        () => cx([styles.root, size, variant, { notify, disabled, full }, className]),
        [size, variant, disabled, full, notify, className]
    );

    return (
        <button className={rootStyles} {...props} disabled={disabled || isLoading}>
            {leftIcon && <Icon name={leftIcon} width={iconSize} height={iconSize} />}
            {children}
            {isLoading && (
                <span className={styles.spinner_wrapper}>
                    <Icon name="loading_spinner" className={styles.spinner} />
                </span>
            )}
            {notify && (
                <span className={styles.notification}>
                    <p>{notifyCount > 99 ? `99+` : notifyCount}</p>
                </span>
            )}
            {rightIcon && <Icon name={rightIcon} width={iconSize} height={iconSize} />}
        </button>
    );
};

export default Button;
