import React from 'react';
import { Icon } from '@components/icons';
import Typography from '@components/common/typography';
import styles from './styles.module.scss';
import useModalContext from '@hooks/context/useModalContext';

const SetPreferredAccountModal = () => {
    const { modalData } = useModalContext();
    return (
        <div className={styles.wrapper}>
            <Icon name="check_outlined" width={60} height={60} />
            <Typography variant="h5">
                {modalData?.accountName} {modalData?.accountNumber}
            </Typography>
            <Typography variant="h5" weight="medium" className={styles.title}>
                will be set as your preferred payment method
            </Typography>
        </div>
    );
};

export default SetPreferredAccountModal;
