import React, { FC } from 'react';
import Typography from '@components/common/typography';
import Input from '@components/common/input';
import Button from '@components/common/button';
import styles from './styles.module.scss';
import Link from '@components/common/link';
import useSignupContext from '@hooks/context/useSignupContext';
import { routes } from '@const/routes';
import { useFormContext } from 'react-hook-form';
import FormField from '@components/form_field';
import { useTranslation } from 'react-i18next';
import { useLocales } from '@hooks/helpers/useLocales';
import { motion } from 'framer-motion';
import { pageAnimation } from '@const/animation';
import { useApiMutation } from '@hooks/api/useApiMutation';
import { apiRegistrationService } from '@api';

interface IProps {
    isEmailSent: boolean;
    toggleIsEmailSent: (val: boolean) => void;
}

const EmailSection: FC<IProps> = ({ isEmailSent, toggleIsEmailSent }) => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'signup.email'
    });
    const { labelLocale, submitLocale, requestErrorLocale } = useLocales();
    const { nextStep, prevStep } = useSignupContext();
    const { trigger, getValues } = useFormContext();

    const { handleRequest: handleValidateEmail } = useApiMutation({
        method: () => {
            const values = getValues();
            return apiRegistrationService.validateEmail(values.email);
        },
        onSuccess: () => toggleIsEmailSent(true),
        onError: requestErrorLocale
    });

    const { handleRequest: handleVerifyEmail } = useApiMutation({
        method: () => {
            const values = getValues();
            return apiRegistrationService.verifyEmail(values.email, values.code);
        },
        onSuccess: nextStep,
        onError: requestErrorLocale
    });

    const onSubmit = () => {
        trigger().then((isValid) => {
            if (isValid) {
                if (isEmailSent) {
                    handleVerifyEmail(undefined);
                } else {
                    handleValidateEmail(undefined);
                }
            }
        });
    };

    return (
        <motion.div {...pageAnimation} className={styles.wrapper}>
            <Typography className={styles.title} variant="h3">
                {t('title')}
            </Typography>
            <Typography className={styles.description} variant="body2">
                {t('already')} <Link to={routes.signin}>{t('login')}</Link>
            </Typography>
            <div className={styles.form}>
                <Typography variant="body2">{t('enter_email')}</Typography>
                <FormField
                    name="email"
                    renderComponent={(props) => (
                        <Input {...props} full required label={labelLocale('email')} />
                    )}
                />
                {isEmailSent && (
                    <>
                        <Typography variant="body2" style={{ marginTop: '16px' }}>
                            {t('enter_email_confirmation')}
                        </Typography>
                        <FormField
                            name="code"
                            renderComponent={(props) => (
                                <Input
                                    label={labelLocale('enter_code')}
                                    {...props}
                                    full
                                    placeholder="Code"
                                    required
                                />
                            )}
                        />
                    </>
                )}
                <Button onClick={onSubmit} size="normal" variant="primary">
                    {submitLocale('next')}
                </Button>
                <Button className={styles.back} leftIcon="arrow_left" onClick={prevStep}>
                    {submitLocale('back')}
                </Button>
            </div>
            <Typography lh="normal">
                {t('by_registration')} <Link to={routes.terms_of_service}>{t('term')}</Link>{' '}
                {t('and')} <Link to={routes.privacy_policy}>{t('privacy')}</Link>
            </Typography>
        </motion.div>
    );
};

export default EmailSection;
