import React from 'react';
import styles from './styles.module.scss';
import verifyEmailIllustration from '@svg/illustrations/verify_email.svg';
import Typography from '@components/common/typography';
import Button from '@components/common/button';
import useSignupContext from '@hooks/context/useSignupContext';
import { useTranslation } from 'react-i18next';
import { useLocales } from '@hooks/helpers/useLocales';
import { motion } from 'framer-motion';
import { pageAnimation } from '@const/animation';

const VerifyEmailSection = () => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'signup.verification_email'
    });
    const { submitLocale } = useLocales();
    const { nextStep } = useSignupContext();
    return (
        <motion.div {...pageAnimation} className={styles.wrapper}>
            <img src={verifyEmailIllustration} alt="Verify Identity illustration" />
            <Typography className={styles.title} variant="body1" weight="bold">
                {t('title')}
            </Typography>
            <Typography className={styles.description} variant="body2">
                {t('description')}
            </Typography>
            <Button onClick={nextStep} size="normal" variant="primary">
                {submitLocale('next')}
            </Button>
        </motion.div>
    );
};

export default VerifyEmailSection;
