import { useProgressBar } from '@hooks/helpers/useProgressBar';
import Info from '@sections/send_and_request/send_money/info';
import Success from '@sections/send_and_request/send_money/success';
import Verification from '@sections/send_and_request/send_money/verification';
import styles from './styles.module.scss';
import ProgressBar, { TProgressItem } from '@components/progress_bar';
import { useForm } from '@hooks/helpers/useForm';
import { FormProvider } from 'react-hook-form';
import { apiAccountService } from '@api';
import { useLocales } from '@hooks/helpers/useLocales';
import { useUserContext } from '@hooks/context/useUserContext';
import { pageAnimation } from '@const/animation';
import { motion } from 'framer-motion';
import { useApiMutation } from '@hooks/api/useApiMutation';
import { eTransferSendMoneyProgressSteps } from '@const/progress_bar_steps';
import { useState } from 'react';
import { ESchedulePaymentType, ESendRequestMoneyMethod } from '@enum';
import SendMethodSection from '@sections/send_and_request/send_money/method';
import { EPaymentFrequency } from '@xeppt/xeppt-sdk/types/billing';
import RecipientSection from '@sections/send_and_request/send_money/recipient';

const SendMoneySection = () => {
    const { requestErrorLocale, requestSuccessLocale } = useLocales();
    const { availableStep, prevStep, nextStep, currentStep, changeStep } = useProgressBar(
        eTransferSendMoneyProgressSteps
    );
    const formMethods = useForm({
        defaultValues: {
            paymentType: ESchedulePaymentType.PAYMENTS_DATE,
            contact: '',
            amount: undefined,
            securityQuestion: '',
            securityAnswer: '',
            message: '',
            paymentFrequency: EPaymentFrequency.ONCE,
            nextPaymentAt: new Date()
        }
    });
    const { refetchAccount } = useUserContext();
    const [sendMethod, setSendMethod] = useState<ESendRequestMoneyMethod>(
        ESendRequestMoneyMethod.INTERNAL
    );
    const [isMethodChosen, setIsMethodChosen] = useState(false);

    const { handleRequest: onSubmit, isLoading } = useApiMutation({
        method: () => {
            const values = formMethods.getValues();
            return apiAccountService.sendMoneyETransfer({
                contactId: values.contact,
                amount: Number(values.amount),
                securityQuestion: values.securityQuestion,
                securityAnswer: values.securityAnswer,
                message: values.message
            });
        },
        onSuccess: () => {
            requestSuccessLocale('send_money');
            nextStep();
            refetchAccount();
        },
        onError: requestErrorLocale
    });

    const renderContent = () => {
        switch (currentStep?.id) {
            case '1':
                return (
                    <Info
                        method={sendMethod}
                        onNextStep={nextStep}
                        onPrevStep={() => setIsMethodChosen(false)}
                    />
                );
            case '2':
                return (
                    <RecipientSection
                        onNextStep={nextStep}
                        onPrevStep={prevStep}
                        isETransfer={sendMethod === ESendRequestMoneyMethod.E_TRANSFER}
                    />
                );
            case '3':
                return (
                    <Verification
                        contactList={[]}
                        isLoading={isLoading}
                        onSubmit={() => onSubmit(undefined)}
                        onPrevStep={prevStep}
                    />
                );
            default:
                return <Success onRepeat={() => changeStep('1')} />;
        }
    };

    return (
        <motion.div {...pageAnimation} className={styles.main_wrapper}>
            {isMethodChosen ? (
                <>
                    <div className={styles.progress_wrapper}>
                        <div className={styles.content}>
                            <ProgressBar
                                variant="light"
                                size="large"
                                steps={
                                    eTransferSendMoneyProgressSteps.filter(
                                        (item) => item !== undefined
                                    ) as TProgressItem[]
                                }
                                availableStep={availableStep}
                                currentStep={currentStep}
                                className={styles.progress}
                            />
                        </div>
                    </div>
                    <FormProvider {...formMethods}>{renderContent()}</FormProvider>
                </>
            ) : (
                <SendMethodSection
                    method={sendMethod}
                    onChangeMethod={(val) => setSendMethod(val)}
                    onSubmit={() => setIsMethodChosen(true)}
                />
            )}
        </motion.div>
    );
};

export default SendMoneySection;
