import React, { FC, useMemo } from 'react';
import Typography from '@components/common/typography';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import { Icon } from '@components/icons';
import Radio from '@components/common/radio';
import { useTranslation } from 'react-i18next';

interface IProps {
    onClick?: () => void;
    checked?: boolean;
    type: 'add' | 'send';
}

const cx = classNames.bind(styles);

const ChooseDirection: FC<IProps> = ({ onClick, type, checked }) => {
    const { t } = useTranslation('move_money');
    const rootStyles = useMemo(() => cx([styles.wrapper, { isChecked: checked }]), [checked]);

    const renderSendMessage = () => {
        switch (type) {
            case 'add':
                return t('add_description');
            case 'send':
                return t('send_description');
        }
    };

    const renderTitle = () => {
        switch (type) {
            case 'add':
                return t('add_title');
            case 'send':
                return t('send_title');
        }
    };

    const getIcon = () => {
        switch (type) {
            case 'add':
                return 'account_balance';
            case 'send':
                return 'bank';
        }
    };

    return (
        <button
            className={rootStyles}
            onClick={() => {
                if (onClick) onClick();
            }}>
            <div className={styles.left}>
                <div className={styles.icon}>
                    <Icon name={getIcon()} />
                </div>
                <div className={styles.text_wrapper}>
                    <Typography variant="body1" weight="semibold">
                        {renderTitle()}
                    </Typography>
                    <Typography variant="body3">{renderSendMessage()}</Typography>
                </div>
            </div>
            <Radio checked={checked} />
        </button>
    );
};

export default ChooseDirection;
