import {
    AccountService,
    AuthService,
    BillingService,
    RegistrationService,
    UserService,
    WalletService,
    XepptClient,
    NotificationService
} from '@xeppt/xeppt-sdk';

export const sdk = new XepptClient(
    {
        baseURL: process.env.REACT_APP_BACKEND_URL
    },
    () => {
        localStorage.removeItem('client-metadata');
    }
);

export const apiRegistrationService = new RegistrationService(sdk);
export const apiAuthService = new AuthService(sdk);
export const apiUserService = new UserService(sdk);
export const apiWalletService = new WalletService(sdk);
export const apiAccountService = new AccountService(sdk);
export const apiBillingService = new BillingService(sdk);
export const apiNotificationService = new NotificationService(sdk);
