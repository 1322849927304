import { z } from 'zod';
import { validator } from '@xeppt/xeppt-sdk';

interface IProps {
    currentStep: number;
    messages: {
        email: string;
        password: string;
        passwordConfirm: string;
    };
}

export const forgotPasswordValidation = ({ messages, currentStep }: IProps) => {
    const steps = [
        z.object({
            email: validator.email(messages.email)
        }),
        z
            .object({
                password: validator.password(messages.password),
                passwordConfirm: validator.password(messages.password)
            })
            .refine((data) => data.password === data.passwordConfirm, {
                message: messages.passwordConfirm,
                path: ['passwordConfirm']
            })
    ];

    return steps[currentStep];
};
