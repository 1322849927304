import React from 'react';
import Typography from '@components/common/typography';
import CardsSection from '@sections/xeppt_cards/cards';
import styles from './styles.module.scss';
import { motion } from 'framer-motion';
import { pageAnimation } from '@const/animation';

const XepptCardsLayout = () => {
    return (
        <motion.div {...pageAnimation} className={styles.wrapper}>
            <Typography className={styles.title} variant="h4">
                XEPPT Card
            </Typography>
            <div className={styles.content}>
                <CardsSection />
                {/*<OrderCardsSection />*/}
            </div>
        </motion.div>
    );
};

export default XepptCardsLayout;
