import React, { useMemo, useState } from 'react';
import styles from './styles.module.scss';
import ProgressBar, { TProgressItem } from '@components/progress_bar';
import { useProgressBar } from '@hooks/helpers/useProgressBar';
import PaymentDetails from '@sections/pay_bills/pay_bills/payment_details';
import Verification from '@sections/pay_bills/pay_bills/verification';
import Success from '@sections/pay_bills/pay_bills/success';
import { useForm } from '@hooks/helpers/useForm';
import { FormProvider, useWatch } from 'react-hook-form';
import { apiBillingService } from '@api';
import { useLocales } from '@hooks/helpers/useLocales';
import { defaultPaymentData } from '@const/default_form_data';
import SelectPayee from '@sections/pay_bills/pay_bills/select_payee';
import { useApiQuery } from '@hooks/api/useApiQuery';
import { EPaymentFrequency } from '@xeppt/xeppt-sdk/types/billing';
import moment from 'moment/moment';
import { motion } from 'framer-motion';
import { pageAnimation } from '@const/animation';
import { useApiMutation } from '@hooks/api/useApiMutation';
import { useUserContext } from '@hooks/context/useUserContext';
import { payBillsPayBillsProgressSteps } from '@const/progress_bar_steps';
import useResponsive from '@hooks/helpers/useResponsive';

const PayBills = () => {
    const { availableStep, prevStep, nextStep, currentStep, changeStep } = useProgressBar(
        payBillsPayBillsProgressSteps
    );
    const { requestSuccessLocale, requestErrorLocale } = useLocales();
    const form = useForm({
        defaultValues: defaultPaymentData
    });
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const { refetchAccount } = useUserContext();
    const { isMobile } = useResponsive();

    const { data: listPayees } = useApiQuery({
        method: () => apiBillingService.listPayees(pageSize, currentPage),
        deps: [currentPage, pageSize]
    });
    const watchedPayee = useWatch({ name: 'payeeId', control: form.control });
    const chosenPayee = useMemo(
        () => listPayees?.data.find((item) => item.id === watchedPayee),
        [watchedPayee, listPayees]
    );

    const { handleRequest: createOneTimePayment, isLoading: isPaymentLoading } = useApiMutation({
        method: () => {
            const values = form.getValues();
            const { amount, payeeId } = values;
            return apiBillingService.createPayment(payeeId, Number(amount));
        },
        onSuccess: () => {
            requestSuccessLocale('create_payment');
            nextStep();
            refetchAccount();
            form.reset(defaultPaymentData);
        },
        onError: requestErrorLocale
    });
    const { handleRequest: createSchedulePayment, isLoading: isScheduleLoading } = useApiMutation({
        method: () => {
            const values = form.getValues();
            const {
                paymentFrequency,
                amount,
                numberOfPayments,
                nextPaymentAt,
                isInfinitePayment,
                payeeId
            } = values;
            return apiBillingService.createSchedule({
                amount: Number(amount || 0),
                nextPaymentAt,
                paymentFrequency,
                numberOfPayments: isInfinitePayment ? -1 : Number(numberOfPayments),
                payeeID: payeeId
            });
        },
        onSuccess: () => {
            requestSuccessLocale('create_schedule_payment');
            nextStep();
            refetchAccount();
            form.reset(defaultPaymentData);
        },
        onError: requestErrorLocale
    });

    const onSubmit = () => {
        const values = form.getValues();
        const { paymentFrequency, nextPaymentAt } = values;
        if (
            paymentFrequency === EPaymentFrequency.ONCE &&
            moment(nextPaymentAt).isSame(moment(), 'day')
        ) {
            createOneTimePayment(undefined);
        } else {
            createSchedulePayment(undefined);
        }
    };

    const renderContent = () => {
        switch (currentStep?.id) {
            case '1':
                return (
                    <SelectPayee
                        listPayees={listPayees?.data || []}
                        onNextStep={nextStep}
                        onPageChange={(page) => setCurrentPage(page)}
                        pagesCount={listPayees?.pagesTotal || 0}
                        pageSize={pageSize}
                        onPageSizeChange={(val) => setPageSize(val)}
                    />
                );
            case '2':
                return (
                    <PaymentDetails
                        chosenPayee={chosenPayee}
                        onNextStep={nextStep}
                        onPrevStep={prevStep}
                    />
                );
            case '3':
                return (
                    <Verification
                        chosenPayee={chosenPayee}
                        onPrevStep={prevStep}
                        onSubmit={onSubmit}
                        isLoading={isPaymentLoading || isScheduleLoading}
                    />
                );
            case '4':
                return <Success onRepeat={() => changeStep('1')} />;
            default:
                return <Success onRepeat={() => changeStep('1')} />;
        }
    };

    return (
        <motion.div {...pageAnimation} className={styles.main_wrapper}>
            <div className={styles.progress_wrapper}>
                <div className={styles.content}>
                    <ProgressBar
                        variant="light"
                        size="large"
                        isMini={isMobile}
                        steps={
                            payBillsPayBillsProgressSteps.filter(
                                (item) => item !== undefined
                            ) as TProgressItem[]
                        }
                        availableStep={availableStep}
                        currentStep={currentStep}
                        className={styles.progress}
                    />
                </div>
            </div>
            <FormProvider {...form}>
                <div className={styles.wrapper}>{renderContent()}</div>
            </FormProvider>
        </motion.div>
    );
};

export default PayBills;
