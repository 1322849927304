import React, { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Header from './header';
import styles from './styles.module.scss';
import planetImage from '@svg/planet.svg';
import { SignupContextProvider } from '../../../context/signup_context';

const AuthProvider = () => {
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <SignupContextProvider>
            <div className={styles.wrapper}>
                <Header />
                <main className={styles.content}>
                    <Outlet />
                </main>
                <div className={styles.planet}>
                    <img src={planetImage} alt="planet illustration" />
                </div>
            </div>
        </SignupContextProvider>
    );
};

export default AuthProvider;
