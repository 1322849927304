import React, { FC, useMemo, useState } from 'react';
import ProgressBar from '@components/progress_bar';
import { useProgressBar } from '@hooks/helpers/useProgressBar';
import FirstStepLinkCard from '@sections/link_card_bank/link_card/step1';
import styles from './styles.module.scss';
import Button from '@components/common/button';
import SecondStepLinkCard from '@sections/link_card_bank/link_card/step2';
import { useNavigate } from 'react-router-dom';
import { routes } from '@const/routes';
import { useForm } from '@hooks/helpers/useForm';
import { defaultLinkCard } from '@const/default_form_data';
import { FormProvider } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { linkCardValidation } from '@helpers/validation_objects/link_card_or_bank';
import { useLocales } from '@hooks/helpers/useLocales';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { pageAnimation } from '@const/animation';

const steps = (localization: (val: string) => string) => [
    {
        title: localization('card_details'),
        id: '1'
    },
    {
        title: localization('complete'),
        id: '2'
    }
];

interface IProps {
    handleChoseOtherType: () => void;
}

const LinkCardSection: FC<IProps> = ({ handleChoseOtherType }) => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'link_card_bank.link_card'
    });
    const navigate = useNavigate();
    const stepsMemo = useMemo(() => [...steps(t), undefined], [steps]);
    const { currentStep, availableStep, nextStep } = useProgressBar(stepsMemo);
    const { validationLocale, submitLocale } = useLocales();
    const [isDone, setIsDone] = useState(false);
    const formMethods = useForm({
        defaultValues: defaultLinkCard,
        resolver: zodResolver(
            linkCardValidation({
                messages: {
                    firstName: validationLocale('firstName'),
                    lastName: validationLocale('lastName'),
                    cardNumber: validationLocale('card_number'),
                    code: validationLocale('security_code'),
                    expire: validationLocale('expire')
                }
            })
        )
    });

    const renderStep = () => {
        switch (currentStep?.id) {
            case '1':
                return <FirstStepLinkCard handleChoseOtherType={handleChoseOtherType} />;
            case '2':
                return <SecondStepLinkCard />;
        }
    };

    return (
        <motion.div {...pageAnimation} className={styles.wrapper}>
            <ProgressBar
                steps={steps(t)}
                availableStep={availableStep}
                currentStep={currentStep}
                size="large"
                variant="light"
                className={styles.progressbar}
            />
            <FormProvider {...formMethods}>
                <div className={styles.content}>{renderStep()}</div>
                <Button
                    variant="primary"
                    size="normal"
                    onClick={() => {
                        if (currentStep?.id === '1') {
                            nextStep();
                            setIsDone(true);
                        } else {
                            navigate(routes.dashboard);
                        }
                    }}
                    disabled={!formMethods.formState.isValid}>
                    {isDone ? submitLocale('done') : submitLocale('next')}
                </Button>
            </FormProvider>
        </motion.div>
    );
};

export default LinkCardSection;
