import React, { FC } from 'react';
import { TBankAccount } from '@xeppt/xeppt-sdk/types';
import styles from '@sections/manage_accounts/interac/styles.module.scss';
import Typography from '@components/common/typography';
import { useTranslation } from 'react-i18next';

interface IProps {
    account: TBankAccount;
}

const BankSection: FC<IProps> = ({ account }) => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'manage_accounts.bank'
    });
    return (
        <div className={styles.wrapper}>
            <div className={styles.row}>
                <Typography variant="body2" weight="bold">
                    {t('account')}
                </Typography>
                <Typography variant="body2">{account.accountName}</Typography>
            </div>
            <div className={styles.row}>
                <Typography variant="body2" weight="bold">
                    {t('account_number')}
                </Typography>
                <Typography variant="body2">{account.accountNumber}</Typography>
            </div>
            <div className={styles.row}>
                <Typography variant="body2" weight="bold">
                    {t('institution')}
                </Typography>
                <Typography variant="body2">{account.institutionName}</Typography>
            </div>
        </div>
    );
};

export default BankSection;
