import React, { FC } from 'react';
import illustration from '@svg/illustrations/link_card.svg';
import Typography from '@components/common/typography';
import Button from '@components/common/button';
import styles from './styles.module.scss';
import useModalContext from '@hooks/context/useModalContext';
import { useNavigate } from 'react-router-dom';
import { routes } from '@const/routes';
import { useTranslation } from 'react-i18next';
import { IModalComponentProps } from '@context/modal_context';

const LinkCardOrBankModal: FC<IModalComponentProps> = () => {
    const { t } = useTranslation('modals', {
        keyPrefix: 'link_card_bank'
    });
    const { onClose } = useModalContext();
    const navigate = useNavigate();

    return (
        <div className={styles.wrapper}>
            <img src={illustration} alt="illustration" />
            <Typography className={styles.title} variant="h4">
                {t('title')}
            </Typography>
            <Typography className={styles.description} variant="body2">
                {t('description')}
            </Typography>
            <Button
                className={styles.link}
                size="medium"
                variant="primary"
                onClick={() => {
                    navigate(routes.link_card_bank);
                    onClose();
                }}>
                {t('submit')}
            </Button>
            <Button size="medium" variant="outlined" onClick={onClose}>
                {t('later')}
            </Button>
        </div>
    );
};

export default LinkCardOrBankModal;
