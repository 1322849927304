import React from 'react';
import illustration from '@svg/illustrations/success_link_card_or_bank.svg';
import Typography from '@components/common/typography';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { pageAnimation } from '@const/animation';

const SecondStepLinkCard = () => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'link_card_bank.link_card.step_2'
    });
    return (
        <motion.div {...pageAnimation} className={styles.wrapper}>
            <img src={illustration} alt="illustration" />
            <p className={styles.title}>{t('success')}</p>
            <Typography variant="h4">{t('linked_card')}</Typography>
        </motion.div>
    );
};

export default SecondStepLinkCard;
