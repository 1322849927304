export const getYears = (count: number, future: boolean) => {
    const finishYear = new Date().getFullYear() + (future ? 100 : 0),
        years = [];
    let startYear = finishYear - (count + (future ? 100 : 0));
    while (startYear <= finishYear) {
        years.push(startYear++);
    }
    return years;
};

export const prettifyDate = (date: Date, t: (val: string) => string) => {
    const now = new Date();
    const diff = Math.abs(now.getTime() - date.getTime()); // Difference in milliseconds

    const minutes = Math.floor(diff / (1000 * 60));
    const hours = Math.floor(diff / (1000 * 60 * 60));
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));

    if (minutes < 1) {
        return 'just now';
    } else if (minutes < 60) {
        return minutes === 1
            ? `1 ${t('minute')} ${t('ago')}`
            : `${minutes} ${t('minutes')} ${t('ago')}`;
    } else if (hours < 24) {
        return hours === 1 ? `1  ${t('hour')} ${t('ago')}` : `${hours} ${t('hours')} ${t('ago')}`;
    } else {
        return days === 1 ? `1  ${t('day')} ${t('ago')}` : `${days} ${t('days')} ${t('ago')}`;
    }
};
