import React from 'react';
import Typography from '@components/common/typography';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';

const WaitVerificationModal = () => {
    const { t } = useTranslation('header');
    return (
        <div className={styles.wrapper}>
            <Typography className={styles.title} variant="h4">
                {t('verification_pending_title')}
            </Typography>
            <Typography className={styles.description} variant="body1">
                {t('verification_pending')}
            </Typography>
        </div>
    );
};

export default WaitVerificationModal;
