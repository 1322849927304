import React, { FC, useEffect, useMemo, useState } from 'react';
import Typography from '@components/common/typography';
import Button from '@components/common/button';
import { EActivePayBills } from '@enum';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import { useNavigate } from 'react-router-dom';
import { routes } from '@const/routes';
import PayBills from '@sections/pay_bills/pay_bills';
import ManagePayeesSection from '@sections/pay_bills/manage_payees';
import SchedulePaymentsSection from '@sections/pay_bills/schedule_payments';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { pageAnimation, slideAnimation } from '@const/animation';
import { useVerificationGuard } from '@hooks/helpers/useVerificationGuard';
import { useUserContext } from '@hooks/context/useUserContext';
import useResponsive from '@hooks/helpers/useResponsive';

const cx = classNames.bind(styles);

interface IProps {
    activeTab?: EActivePayBills;
}

const PayBillsLayout: FC<IProps> = ({ activeTab }) => {
    const { t } = useTranslation('pay_bills');
    const navigate = useNavigate();
    const { verifiedAction } = useVerificationGuard();
    const [currentTab, setCurrentTab] = useState<EActivePayBills>();
    const { user } = useUserContext();
    const { isTablet, isMobile } = useResponsive();
    const [isTabletOpen, setIsTabletOpen] = useState(true);
    const isLessThenTablet = useMemo(() => isTablet || isMobile, [isTablet, isMobile]);

    const contentStyle = useMemo(
        () => cx([styles.content_wrapper, { isOpen: !!currentTab }]),
        [currentTab]
    );
    const sidebarStyle = useMemo(
        () =>
            cx([
                styles.sidebar,
                { isOpen: !!currentTab, isTabletOpen: isTabletOpen && !!currentTab }
            ]),
        [currentTab, isTabletOpen]
    );

    const toggleMobileMenu = () => {
        setIsTabletOpen((state) => !state);
    };

    useEffect(() => {
        setIsTabletOpen(!isLessThenTablet || !currentTab);
    }, [isLessThenTablet, currentTab]);

    const renderTab = () => {
        switch (activeTab) {
            case EActivePayBills.PAY:
                return <PayBills />;
            case EActivePayBills.MANAGE:
                return <ManagePayeesSection />;
            case EActivePayBills.SCHEDULE:
                return <SchedulePaymentsSection />;
        }
    };

    useEffect(() => {
        if (activeTab) {
            verifiedAction(
                () => {
                    setCurrentTab(activeTab);
                },
                () => {
                    setCurrentTab(undefined);
                    navigate(routes.pay_bills);
                }
            );
        } else {
            setCurrentTab(undefined);
        }
    }, [activeTab, user]);

    return (
        <motion.div {...pageAnimation} className={styles.wrapper}>
            <div className={contentStyle}>
                <div className={styles.content}>{renderTab()}</div>
            </div>
            <div className={sidebarStyle}>
                <div className={cx(styles.title_wrapper, { isOpen: isTabletOpen })}>
                    {isTabletOpen && (
                        <motion.div {...(isLessThenTablet && slideAnimation)}>
                            <Typography variant="h4">{t('title')}</Typography>
                        </motion.div>
                    )}
                    {isLessThenTablet && !!currentTab && (
                        <button
                            className={cx([styles.burger, { isOpen: isTabletOpen }])}
                            onClick={toggleMobileMenu}>
                            <div />
                            <div />
                            <div />
                        </button>
                    )}
                </div>
                <div className={styles.divider} />
                <div
                    className={cx([styles.tab, { isActive: currentTab === EActivePayBills.PAY }])}
                    onClick={() => navigate(routes.pay_bills_pay)}>
                    <Button leftIcon="send" variant="icon" />
                    {isTabletOpen && (
                        <motion.div {...(isLessThenTablet && slideAnimation)}>
                            {t('pay_bills')}
                        </motion.div>
                    )}
                </div>
                <div
                    className={cx([
                        styles.tab,
                        { isActive: currentTab === EActivePayBills.MANAGE }
                    ])}
                    onClick={() => navigate(routes.pay_bills_payees)}>
                    <Button leftIcon="dollar" variant="icon" />
                    {isTabletOpen && (
                        <motion.div {...(isLessThenTablet && slideAnimation)}>
                            {t('manage_payees')}
                        </motion.div>
                    )}
                </div>
                <div
                    className={cx([
                        styles.tab,
                        { isActive: currentTab === EActivePayBills.SCHEDULE }
                    ])}
                    onClick={() => navigate(routes.pay_bills_schedule)}>
                    <Button leftIcon="person" variant="icon" />
                    {isTabletOpen && (
                        <motion.div {...(isLessThenTablet && slideAnimation)}>
                            {t('schedule_payments')}
                        </motion.div>
                    )}
                </div>
            </div>
        </motion.div>
    );
};

export default PayBillsLayout;
