import React, { FC } from 'react';
import styles from './styles.module.scss';
import Typography from '@components/common/typography';
import { Icon } from '@components/icons';
import { useTranslation } from 'react-i18next';

interface IProps {
    onClick?: () => void;
}

const NewCardRow: FC<IProps> = ({ onClick }) => {
    const { t } = useTranslation('cards');
    return (
        <button
            className={styles.wrapper}
            onClick={() => {
                if (onClick) onClick();
            }}
        >
            <div className={styles.content}>
                <Icon name="plus" />
                <div className={styles.text_wrapper}>
                    <Typography variant="body1" weight="medium">
                        {t('order_new_card')}
                    </Typography>
                </div>
            </div>
            <Icon name="nav_right" />
        </button>
    );
};

export default NewCardRow;
