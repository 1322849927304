import styles from './styles.module.scss';
import React, { useState } from 'react';
import Typography from '@components/common/typography';
import PaymentsTable from '@sections/pay_bills/schedule_payments/payments_table';
import { useTranslation } from 'react-i18next';
import { useApiQuery } from '@hooks/api/useApiQuery';
import { apiBillingService } from '@api';
import PaymentDetails from '@sections/pay_bills/schedule_payments/payment_details';
import { FormProvider } from 'react-hook-form';
import { useForm } from '@hooks/helpers/useForm';
import { EPaymentFrequency, EScheduleStatus, TSchedule } from '@xeppt/xeppt-sdk/types/billing';
import { defaultPaymentData } from '@const/default_form_data';
import { ESchedulePaymentType } from '@enum';
import { useLocales } from '@hooks/helpers/useLocales';
import Pagination from '@components/pagination';
import { motion } from 'framer-motion';
import { pageAnimation } from '@const/animation';
import { useApiMutation } from '@hooks/api/useApiMutation';

const defaultData = {
    numberOfPayments: 1,
    paymentType: ESchedulePaymentType.PAYMENTS_DATE,
    paymentFrequency: EPaymentFrequency.ONCE,
    name: '',
    id: '',
    alias: '',
    amount: 0,
    accountNumber: '',
    isInfinitePayment: false,
    nextPaymentAt: new Date(),
    paymentsEndDate: new Date()
};

const SchedulePaymentsSection = () => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'pay_bills.schedule_payments'
    });
    const [isEdit, setIsEdit] = useState(false);
    const { requestErrorLocale, requestSuccessLocale } = useLocales();
    const [filter, setFilter] = useState<{
        paymentFrequency?: EPaymentFrequency[];
        status?: EScheduleStatus;
    }>({
        paymentFrequency: [],
        status: undefined
    });
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const { data: paymentsList, handleRequest: refetchPaymentsList } = useApiQuery({
        method: () =>
            apiBillingService.listSchedules(pageSize, page, {
                frequency:
                    (filter.paymentFrequency || [])?.length > 0
                        ? filter.paymentFrequency
                        : undefined,
                status: filter.status
            }),
        deps: [filter, page, pageSize]
    });
    const form = useForm({
        defaultValues: defaultData
    });

    const { handleRequest: onSubmit, isLoading } = useApiMutation({
        method: () => {
            const {
                id,
                amount,
                paymentFrequency,
                numberOfPayments,
                nextPaymentAt,
                isInfinitePayment
            } = form.getValues();
            return apiBillingService.updateSchedule(id, {
                amount: Number(amount),
                paymentFrequency,
                numberOfPayments: isInfinitePayment ? -1 : Number(numberOfPayments),
                nextPaymentAt
            });
        },
        onSuccess: () => {
            requestSuccessLocale('update_schedule_payment');
            form.reset(defaultData);
            setIsEdit(false);
            refetchPaymentsList();
        },
        onError: requestErrorLocale
    });

    return (
        <motion.div {...pageAnimation} className={styles.main_wrapper}>
            <div className={styles.wrapper}>
                <div className={styles.content}>
                    <div className={styles.header}>
                        <Typography variant="h4">{t('title')}</Typography>
                    </div>
                </div>
                {isEdit ? (
                    <FormProvider {...form}>
                        <PaymentDetails
                            onPrevStep={() => {
                                setIsEdit(false);
                                form.reset(defaultPaymentData);
                            }}
                            isLoading={isLoading}
                            onNextStep={() => onSubmit(undefined)}
                        />
                    </FormProvider>
                ) : (
                    <>
                        <PaymentsTable
                            refetchPaymentsList={refetchPaymentsList}
                            paymentsList={paymentsList?.data || []}
                            onFilterChange={(filter) => setFilter(filter)}
                            filter={filter}
                            onEdit={(data: TSchedule) => {
                                setIsEdit(true);
                                form.reset({
                                    numberOfPayments: data.remainingPayments,
                                    name: data.payeeName,
                                    alias: data.payeeAlias,
                                    id: data.id,
                                    amount: data.amount,
                                    accountNumber: data.payeeAccountNumber,
                                    nextPaymentAt: data.nextPaymentAt,
                                    paymentsEndDate: data.lastPaymentAt,
                                    paymentFrequency: data.paymentFrequency,
                                    isInfinitePayment: data.remainingPayments === -1,
                                    paymentType: ESchedulePaymentType.NUMBER_OF_PAYMENTS
                                });
                            }}
                        />
                        {(paymentsList?.pagesTotal || 0) > 1 && (
                            <Pagination
                                pageCount={paymentsList?.pagesTotal || 0}
                                onPageChange={(val) => setPage(val)}
                                pageSize={pageSize}
                                onPageSizeChange={(val) => setPageSize(val)}
                            />
                        )}
                    </>
                )}
            </div>
        </motion.div>
    );
};

export default SchedulePaymentsSection;
