import React, { createContext, useEffect, useState } from 'react';

import { Icon } from '@components/icons';
import Button from '@components/common/button';
import { modals } from '@const/modals';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import { Outlet } from 'react-router-dom';
import { useLocales } from '@hooks/helpers/useLocales';

type TOpenModal<T = any> = {
    modalId: string;
    onSubmit?: (modalData: T) => void;
};

export interface IModalComponentProps<T = any, R = any> {
    onSubmit?: (modalData?: T) => R;
    modalData: T;
    changeModalData: (value?: T) => void;
    handleChangeDisable: (value: boolean) => void;
    handleChangeLoading: (value: boolean) => void;
    isLoadingSubmit: boolean;
    onClose: () => void;
}

export type TModal = {
    confirmLabel?: string;
    cancelLabel?: string;
    isHeader?: boolean;
    isFooter?: boolean;
    Component: React.FC<IModalComponentProps> | null;
    variant: 'medium' | 'small' | 'custom' | 'large';
} & TOpenModal;

type TModalContext = {
    isOpen: boolean;
    onOpen: <T>(value: TOpenModal<T>) => void;
    rerender: number;
    repeatRequest: () => void;
    changeModalData: (value: any) => void;
    modalData: any;
    onClose: () => void;
    handleChangeDisable: (val: boolean) => void;
    handleChangeLoading: (val: boolean) => void;
    isLoadingSubmit: boolean;
};

const initialState: TModal = {
    modalId: '',
    onSubmit: () => {},
    confirmLabel: '',
    cancelLabel: '',
    Component: null,
    variant: 'custom'
};

export const ModalContext = createContext<TModalContext>({
    isOpen: false,
    isLoadingSubmit: false,
    onOpen: () => {},
    handleChangeDisable: () => {},
    handleChangeLoading: () => {},
    rerender: 0,
    repeatRequest: () => {},
    modalData: '',
    changeModalData: () => {},
    onClose: () => {}
});

const cx = classNames.bind(styles);

const ModalContextProvider = () => {
    const [modalState, setModalState] = useState<TModal>(initialState);
    const [isOpen, setIsOpen] = useState(false);
    const [modalData, setModalData] = useState<any>('');
    const [rerender, setRerender] = useState(0);
    const [isDisableSubmit, setIsDisableSubmit] = useState(false);
    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
    const { submitLocale } = useLocales();

    useEffect(() => {
        document.body.style.overflow = isOpen ? 'hidden' : 'auto';
    }, [isOpen]);

    const onOpen = (value: TOpenModal) => {
        const modal = modals.find((modal) => modal.modalId === value.modalId);

        if (modal) {
            setModalState({
                ...modal,
                ...value
            });
            setIsOpen(true);
        }
    };

    const handleCloseModal = () => {
        requestAnimationFrame(() => {
            setModalState(initialState);
        });
        setIsOpen(false);
        setModalData(undefined);
        setIsDisableSubmit(false);
        setIsLoadingSubmit(false);
    };

    const changeModalData = (value: any) => {
        setModalData(value);
    };

    const handleChangeDisable = (val: boolean) => {
        setIsDisableSubmit(val);
    };

    const handleChangeLoading = (val: boolean) => {
        setIsLoadingSubmit(val);
    };

    return (
        <ModalContext.Provider
            value={{
                isOpen: !!modalState.modalId,
                onOpen,
                rerender,
                isLoadingSubmit,
                modalData,
                onClose: handleCloseModal,
                changeModalData,
                handleChangeDisable,
                handleChangeLoading,
                repeatRequest: () => setRerender((state) => state + 1)
            }}>
            <Outlet />
            <div
                onClick={() => modalState.isHeader && handleCloseModal()}
                className={styles.overlay}
                style={{ display: isOpen ? 'block' : 'none' }}
            />
            {isOpen && (
                <div className={cx([styles.dialog, modalState.variant])}>
                    {modalState.isHeader && (
                        <div className={styles.header}>
                            <button onClick={handleCloseModal}>
                                <Icon name="close" width={24} height={24} />
                            </button>
                        </div>
                    )}
                    <div className={styles.container}>
                        {modalState.Component && (
                            <modalState.Component
                                onSubmit={modalState.onSubmit}
                                onClose={handleCloseModal}
                                modalData={modalData}
                                changeModalData={changeModalData}
                                handleChangeDisable={handleChangeDisable}
                                handleChangeLoading={handleChangeLoading}
                                isLoadingSubmit={isLoadingSubmit}
                            />
                        )}
                        {modalState.isFooter && (
                            <div className={styles.footer}>
                                {modalState.cancelLabel && (
                                    <Button variant="outlined" onClick={handleCloseModal}>
                                        {submitLocale(modalState.cancelLabel)}
                                    </Button>
                                )}
                                {modalState.confirmLabel && (
                                    <Button
                                        variant="primary"
                                        disabled={isDisableSubmit}
                                        isLoading={isLoadingSubmit}
                                        onClick={() => {
                                            if (modalState.onSubmit) {
                                                modalState.onSubmit(modalData);
                                            }
                                        }}>
                                        {submitLocale(modalState.confirmLabel)}
                                    </Button>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            )}
        </ModalContext.Provider>
    );
};

export default ModalContextProvider;
