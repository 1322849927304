import React, { FC, useEffect } from 'react';
import Typography from '@components/common/typography';
import Input from '@components/common/input';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { useUserContext } from '@hooks/context/useUserContext';
import { IModalComponentProps } from '@context/modal_context';
import { prettifyAmount } from '@utils/index';

const LoadCardBalanceModal: FC<IModalComponentProps<{ amount: string }>> = ({
    modalData,
    changeModalData,
    handleChangeDisable
}) => {
    const { t } = useTranslation('modals', {
        keyPrefix: 'load_card_balance'
    });
    const { account } = useUserContext();

    useEffect(() => {
        handleChangeDisable(
            Number(modalData?.amount || 0) > Number(account?.balance || 0) ||
                Number(modalData?.amount || 0) <= 0
        );
    }, [modalData]);

    return (
        <div className={styles.wrapper}>
            <Typography className={styles.title} variant="h4">
                {t('title')}
            </Typography>
            <div className={styles.balance_wrapper}>
                <Typography variant="body3">{t('current_balance')}</Typography>
                <div className={styles.balance}>
                    <Typography variant="body1" weight="bold">
                        CAD
                    </Typography>
                    <Typography variant="body1" weight="bold">
                        ${prettifyAmount((account?.balance || 0).toFixed(2) || '')}
                    </Typography>
                </div>
            </div>
            <div className={styles.inputs_wrapper}>
                <div className={styles.input_wrapper}>
                    <Input
                        label={t('amount')}
                        full
                        value={modalData?.amount}
                        onlyNumbers
                        onChange={(val) => {
                            changeModalData({ ...modalData, amount: val });
                        }}
                        rightIcon="canadian"
                        prefix="CAD"
                    />
                </div>
            </div>
        </div>
    );
};

export default LoadCardBalanceModal;
