import React, { FC, useState } from 'react';
import Typography from '@components/common/typography';
import PaymentCardRadio from '@components/cards/payment_card_radio';
import { EPaymentSystem } from '@enum';
import styles from './styles.module.scss';
import Switch from '@components/common/switch';
import Button from '@components/common/button';
import Input from '@components/common/input';
import { useLocales } from '@hooks/helpers/useLocales';
import { useTranslation } from 'react-i18next';

interface IProps {
    handlePrevStep: () => void;
}

const CardPaymentStep: FC<IProps> = ({ handlePrevStep }) => {
    const [card, setCard] = useState<string>();
    const { labelLocale } = useLocales();
    const { t } = useTranslation('sections', {
        keyPrefix: 'add_money.card_payment'
    });
    return (
        <div className={styles.wrapper}>
            <div className={styles.card}>
                <Typography variant="h4">{t('linked_card')}</Typography>
                <div className={styles.cards_wrapper}>
                    <PaymentCardRadio
                        type={EPaymentSystem.VISA}
                        name={t('visa')}
                        card="3748593847583234"
                        date="05/2026"
                        checked={card === '3748593847583234'}
                        onChange={(value) => setCard(value)}
                    />
                    <PaymentCardRadio
                        type={EPaymentSystem.MASTERCARD}
                        name={t('mastercard')}
                        card="3748593847582599"
                        date="01/2026"
                        checked={card === '3748593847582599'}
                        onChange={(value) => setCard(value)}
                    />
                </div>
            </div>
            <div className={styles.new_card_wrapper}>
                <Typography variant="h4">{t('linked_card')}</Typography>
                <div className={styles.card_wrapper}>
                    <Input full label={labelLocale('card_number')} />
                    <div className={styles.card_codes}>
                        <Input full label={labelLocale('expiry_date')} />
                        <Input full rightIcon="credit_card" label={labelLocale('security_code')} />
                    </div>
                </div>
                <div className={styles.save}>
                    <p>{t('save')}</p>
                    <Switch />
                </div>
            </div>
            <Button leftIcon="arrow_left" onClick={handlePrevStep}>
                {t('other_payment')}
            </Button>
        </div>
    );
};

export default CardPaymentStep;
