import { ECardType, EOrderStatus } from '@enum';
import { TBusinessProfile, TIndividualProfile } from '@xeppt/xeppt-sdk/types/user';

export type TCardOrder = {
    status: EOrderStatus;
    type: ECardType;
};

export type TAddress = {
    country: string;
    region: string;
    city: string;
    address2: string;
    address1: string;
    zipCode: string;
};

export type TBusinessDetails = {
    companyName: string;
    tradingName: string;
    registrationNumber: string;
    category: string;
    size: string;
    website?: string;
};

//--------------- GUARDS ---------------//

export const isBusinessUserTypeGuard = (
    user: TIndividualProfile | TBusinessProfile
): user is TBusinessProfile => {
    return 'business' in user;
};
