import React, { useCallback, useEffect, useRef, useState } from 'react';
import styles from './styles.module.scss';
import TableSection from '@sections/transactions/table';
import classNames from 'classnames/bind';
import FilterSection from '@sections/transactions/filter';
import { useApiQuery } from '@hooks/api/useApiQuery';
import { apiAccountService } from '@api';
import { TTransactionLite, TTransactionsFilter } from '@xeppt/xeppt-sdk/types/transaction';
import { motion } from 'framer-motion';
import { pageAnimation } from '@const/animation';

const cx = classNames.bind(styles);

const TransactionsLayout = () => {
    const [filter, setFilter] = useState<TTransactionsFilter>({
        action: undefined,
        dateFrom: undefined,
        dateTo: undefined
    });
    const [cursor, setCursor] = useState<Date>();
    const loader = useRef(null);
    const [transactions, setTransactions] = useState<TTransactionLite[]>([]);
    const [trigger, setTrigger] = useState(0);
    const {
        data: transactionsResponse,
        isLoading: isTransactionsLoading,
        handleRequest: handleGetTransactions
    } = useApiQuery({
        isInitialRequest: false,
        method: () => {
            return apiAccountService.findTransactions(
                {
                    ...filter,
                    action: filter.action?.length === 0 ? undefined : filter.action
                },
                {
                    cursor,
                    limit: 100
                }
            );
        },
        onSuccess: (data) => {
            if (cursor !== data.cursor) {
                setTransactions((state) => [...state, ...data.data]);
                setCursor(data.cursor);
            }
        },
        deps: [filter]
    });
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        const loadItems = () => {
            if (
                transactions.length < (transactionsResponse?.total || 0) &&
                !isTransactionsLoading
            ) {
                handleGetTransactions();
            }
        };

        loadItems();
    }, [trigger]);

    const handleObserver = useCallback((entries: any) => {
        const target = entries[0];
        if (target.isIntersecting) {
            setTrigger((prev) => prev + 1);
        }
    }, []);

    useEffect(() => {
        const option = {
            root: null,
            rootMargin: '20px',
            threshold: 0
        };
        const observer = new IntersectionObserver(handleObserver, option);
        if (loader.current) {
            observer.observe(loader.current);
        }
    }, [handleObserver]);

    return (
        <motion.div {...pageAnimation}>
            <div className={styles.wrapper}>
                <div className={cx([styles.left, { isOpen }])}>
                    <TableSection
                        transactions={transactions}
                        toggleFilter={() => setIsOpen((state) => !state)}
                        isTransactionsLoading={isTransactionsLoading}
                    />
                    <div ref={loader} />
                </div>
                <div className={cx([styles.right, { isOpen }])}>
                    <FilterSection
                        toggleFilter={() => setIsOpen((state) => !state)}
                        onChangeFilter={(value) => {
                            setCursor(undefined);
                            setTransactions([]);
                            setFilter(value);
                        }}
                    />
                </div>
            </div>
        </motion.div>
    );
};

export default TransactionsLayout;
