import React, { FC, useMemo } from 'react';
import styles from './styles.module.scss';
import { Icon } from '@components/icons';
import Typography from '@components/common/typography';
import Button from '@components/common/button';
import { useFormContext, useWatch } from 'react-hook-form';
import { useUserContext } from '@hooks/context/useUserContext';
import { EUserType, TContact } from '@xeppt/xeppt-sdk/types';
import { eTransferBusinessFees, eTransferIndividualFees } from '@const/fees';
import { useTranslation } from 'react-i18next';
import { useLocales } from '@hooks/helpers/useLocales';
import { motion } from 'framer-motion';
import { pageAnimation } from '@const/animation';

interface IProps {
    onSubmit: () => void;
    onPrevStep: () => void;
    isLoading: boolean;
    contactList: TContact[];
}

const Verification: FC<IProps> = ({ onSubmit, onPrevStep, contactList, isLoading }) => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'send_and_request.send_money.verification'
    });
    const { submitLocale } = useLocales();
    const { getValues, control } = useFormContext();
    const contactId = useWatch({ name: 'contact', control });
    const { user, account } = useUserContext();

    const fees = useMemo(() => {
        if (user?.type === EUserType.INDIVIDUAL) {
            return eTransferIndividualFees;
        }
        return eTransferBusinessFees;
    }, [user]);

    const foundContact = useMemo(() => {
        return contactList.find((item) => item.id === contactId);
    }, [contactList, contactId]);

    return (
        <motion.div {...pageAnimation} className={styles.wrapper}>
            <div className={styles.content}>
                <div className={styles.ddr_info}>
                    <Icon name="info" />
                    {t('title_1')} <span>{t('title_2')}</span> {t('title_3')}
                </div>
                <div className={`${styles.fees_container} ${styles.isOpen}`}>
                    <div className={styles.fees_wrapper}>
                        <Typography variant="h5">{t('summary')}</Typography>
                        <div className={styles.row}>
                            <Typography>{t('info_1')}</Typography>
                            <Typography>
                                {user?.profile?.firstName} {user?.profile?.lastName} (
                                {account?.eTransferAccount?.interacEmail})
                            </Typography>
                        </div>
                        <div className={styles.row}>
                            <Typography>{t('info_2')}</Typography>
                            <Typography>
                                ${(Number(getValues()?.amount) - fees.send).toFixed(2)} CAD
                            </Typography>
                        </div>
                        <div className={styles.row}>
                            <Typography>{t('info_3')}</Typography>
                            <Typography>
                                {foundContact?.firstName} {foundContact?.lastName}
                            </Typography>
                        </div>
                        <div className={styles.row}>
                            <Typography>{t('info_4')}</Typography>
                            <Typography>{foundContact?.email || foundContact?.phone}</Typography>
                        </div>
                        {getValues().securityQuestion && (
                            <div className={styles.row}>
                                <Typography>Security question</Typography>
                                <Typography>{getValues().securityQuestion}</Typography>
                            </div>
                        )}
                        {getValues().securityAnswer && (
                            <div className={styles.row}>
                                <Typography>Security answer</Typography>
                                <Typography>{getValues().securityAnswer}</Typography>
                            </div>
                        )}
                        <div className={styles.row}>
                            <Typography>{t('info_5')}</Typography>
                            <Typography>${fees.send.toFixed(2)}</Typography>
                        </div>
                        <div className={styles.row}>
                            <Typography>{t('info_6')}</Typography>
                            <Typography>{getValues()?.message || '-'}</Typography>
                        </div>
                    </div>
                </div>
                <div className={styles.ddr_info}>{t('notes')}</div>
            </div>
            <Button variant="primary" size="normal" onClick={onSubmit} isLoading={isLoading}>
                {submitLocale('send_money')}
            </Button>
            <Button leftIcon="arrow_left" onClick={onPrevStep}>
                {submitLocale('back')}
            </Button>
        </motion.div>
    );
};

export default Verification;
