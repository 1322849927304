import React, { FC } from 'react';
import useCollapse from '@hooks/helpers/useCollapse';
import Typography from '@components/common/typography';
import Button from '@components/common/button';
import Input from '@components/common/input';
import Textarea from '@components/common/textarea';
import styles from './styles.module.scss';
import FormField from '@components/form_field';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { routes } from '@const/routes';
import { useLocales } from '@hooks/helpers/useLocales';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { pageAnimation } from '@const/animation';

interface IProps {
    onNextStep: () => void;
    onPrevStep: () => void;
    isETransfer: boolean;
}

const Info: FC<IProps> = ({ onNextStep, onPrevStep, isETransfer }) => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'send_and_request.request_money.info'
    });
    const { labelLocale, submitLocale, validationLocale } = useLocales();
    const { isOpened, onToggle, contentStyles, contentRef } = useCollapse(true);
    const navigate = useNavigate();
    const form = useFormContext();

    const onSubmit = () => {
        form.trigger().then((isValid) => {
            if (isValid) {
                onNextStep();
            }
        });
    };

    return (
        <motion.div {...pageAnimation} className={styles.wrapper}>
            <div className={styles.content}>
                <Typography variant="body3" className={styles.send_disclaimer}>
                    {t(isETransfer ? 'description' : 'description_internal')}
                </Typography>
            </div>
            <div className={styles.content}>
                <div className={styles.header}>
                    <Typography variant="h4">{t('from')}</Typography>
                    <div className={styles.actions}>
                        <Button
                            variant="outlined-dark"
                            size="small"
                            onClick={() => navigate(routes.send_and_request_contacts)}>
                            {submitLocale('add_new')}
                        </Button>
                    </div>
                </div>
                <FormField
                    name="amount"
                    renderComponent={(props) => (
                        <Input
                            full
                            label={labelLocale('amount')}
                            prefix="CAD"
                            rightIcon="canadian"
                            hideErrorIcon
                            onlyNumbers
                            {...props}
                        />
                    )}
                    rules={{ required: { value: true, message: validationLocale('amount') } }}
                />
                <FormField
                    name="message"
                    renderComponent={(props) => (
                        <Textarea full label={labelLocale('message_optional')} {...props} />
                    )}
                />
            </div>
            <Button variant="primary" size="normal" onClick={onSubmit}>
                {submitLocale('next')}
            </Button>
            <Button size="normal" leftIcon="arrow_left" onClick={onPrevStep}>
                {submitLocale('back')}
            </Button>
            {isETransfer && (
                <div className={styles.fees}>
                    <Button onClick={onToggle}>
                        <div className={`${styles.icon} ${isOpened ? styles.isOpen : ''}`} />{' '}
                        <Typography variant="h5">{t('fees')}</Typography>
                    </Button>
                    <div
                        className={`${styles.fees_container} ${isOpened ? styles.isOpen : ''}`}
                        style={contentStyles}
                        ref={contentRef}>
                        <div className={styles.fees_wrapper}>
                            <Typography variant="h5">{t('fee_personal')}</Typography>
                            <div className={styles.row}>
                                <Typography>{t('fee_personal_1')}</Typography>
                                <Typography>{t('fee_personal_2')}</Typography>
                            </div>
                            <div className={styles.row}>
                                <Typography>{t('fee_personal_3')}</Typography>
                                <Typography>$5 {t('fee_personal_4')}</Typography>
                            </div>
                        </div>
                        <div className={styles.fees_wrapper}>
                            <Typography variant="h5">{t('fee_business')}</Typography>
                            <div className={styles.row}>
                                <Typography>{t('fee_business_1')}</Typography>
                                <Typography>$1.50 {t('fee_business_2')}</Typography>
                            </div>
                            <div className={styles.row}>
                                <Typography>{t('fee_business_3')}</Typography>
                                <Typography>$5 {t('fee_business_4')}</Typography>
                            </div>
                        </div>
                    </div>
                    <div className={styles.notes}>
                        {t('notes')}
                        <div className={styles.row}>
                            <span>1.</span>
                            <div>{t('notes_1')}</div>
                        </div>
                        <div className={styles.row}>
                            <span>2.</span>
                            <div>{t('notes_2')}</div>
                        </div>
                    </div>
                </div>
            )}
        </motion.div>
    );
};

export default Info;
