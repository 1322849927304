import { useEffect, useState } from 'react';
import { TProgressItem } from '@components/progress_bar';

export const useProgressBar = (points: (TProgressItem | undefined)[]) => {
    const [currentStep, setCurrentStep] = useState<TProgressItem | undefined>(points[0]);
    const [availableStep, setAvailableStep] = useState<TProgressItem | undefined>(points[1]);
    const [steps, setSteps] = useState([...points, { title: '', id: '' }]);

    useEffect(() => {
        setSteps(points);
    }, [points]);

    const nextStep = () => {
        const availableStepIndex = steps.findIndex((item) => item?.id === availableStep?.id);
        const currentStepIndex = steps.findIndex((item) => item?.id === currentStep?.id);
        setAvailableStep(steps[availableStepIndex + 1]);
        if (availableStepIndex !== -1) {
            setCurrentStep(steps[availableStepIndex]);
        } else {
            setAvailableStep(undefined);
        }
        if (currentStepIndex === steps.length - 1) {
            setCurrentStep(undefined);
        }
    };

    const prevStep = () => {
        const currentStepIndex = steps.findIndex((item) => item?.id === currentStep?.id);
        if (currentStepIndex !== 0) {
            setCurrentStep(steps[currentStepIndex - 1]);
            setAvailableStep(steps[currentStepIndex]);
        }
    };

    const changeStep = (id: string) => {
        const step = steps.find((item) => item?.id === id);
        setCurrentStep(step);
        const currentStepIndex = steps.findIndex((item) => item?.id === step?.id);
        if (steps[currentStepIndex + 1]) {
            setAvailableStep(steps[currentStepIndex + 1]);
        } else {
            setAvailableStep(undefined);
        }
    };

    return {
        currentStep,
        availableStep,
        prevStep,
        nextStep,
        changeStep
    };
};
