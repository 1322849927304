import React, { FC, ReactNode } from 'react';
import { useUserActivity } from '@hooks/helpers/useUserActivity';
import { useVerificationResult } from '@hooks/api/useVerificationResult';

interface IProps {
    children: ReactNode;
}

const ActivityServiceProvider: FC<IProps> = ({ children }) => {
    useUserActivity();
    useVerificationResult();

    return <>{children}</>;
};

export default ActivityServiceProvider;
