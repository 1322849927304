import React, { FC, useMemo } from 'react';
import { Icon } from '@components/icons';
import classNames from 'classnames/bind';
import styles from './styles.module.scss';

interface IProps {
    isIcon?: boolean;
    checked?: boolean;
    disabled?: boolean;
    className?: string;
    onClick?: () => void;
}

const cx = classNames.bind(styles);

const Switch: FC<IProps> = ({ onClick, isIcon, disabled, checked, className }) => {
    const rootStyles = useMemo(
        () => cx([styles.root, disabled, { checked }, className]),
        [checked, disabled, className]
    );

    return (
        <label className={rootStyles} onChange={onClick}>
            <span>{isIcon && <Icon name={checked ? 'check' : 'close'} />}</span>
            <input type="checkbox" checked={checked} />
        </label>
    );
};

export default Switch;
