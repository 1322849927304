import React, { FC, useEffect, useMemo, useState } from 'react';
import ProgressBar from '@components/progress_bar';
import { useProgressBar } from '@hooks/helpers/useProgressBar';
import styles from './styles.module.scss';
import Button from '@components/common/button';
import { useNavigate } from 'react-router-dom';
import FirstStepLinkBank from '@sections/link_card_bank/link_bank/step1';
import SecondStepInstantLinkBank from '@sections/link_card_bank/link_bank/step2_instant';
import ThirdStepLinkBank from '@sections/link_card_bank/link_bank/step3';
import SecondStepManualLinkBank from '@sections/link_card_bank/link_bank/step2_manual';
import { routes } from '@const/routes';
import { useForm } from '@hooks/helpers/useForm';
import { defaultLinkBank } from '@const/default_form_data';
import { zodResolver } from '@hookform/resolvers/zod';
import { linkBankValidation } from '@helpers/validation_objects/link_card_or_bank';
import { FormProvider } from 'react-hook-form';
import { useLocales } from '@hooks/helpers/useLocales';
import { apiAccountService } from '@api';
import { useUserContext } from '@hooks/context/useUserContext';
import { motion } from 'framer-motion';
import { pageAnimation } from '@const/animation';
import { useTranslation } from 'react-i18next';
import { useApiMutation } from '@hooks/api/useApiMutation';

const steps = (t: any) => [
    {
        title: t('methods'),
        id: '1'
    },
    {
        title: t('verification'),
        id: '2'
    },
    {
        title: t('complete'),
        id: '3'
    }
];

interface IProps {
    handleChoseOtherType: () => void;
}

const LinkBankSection: FC<IProps> = ({ handleChoseOtherType }) => {
    const navigate = useNavigate();
    const { validationLocale, submitLocale, requestErrorLocale, requestSuccessLocale } =
        useLocales();
    const { t } = useTranslation('sections', {
        keyPrefix: 'link_card_bank.link_bank'
    });
    const [isLoading, setIsLoading] = useState(false);
    const stepsMemo = useMemo(() => [...steps(t), undefined], [steps]);
    const { currentStep, availableStep, nextStep, changeStep } = useProgressBar(stepsMemo);
    const [linkMethod, setLinkMethod] = useState<'manual' | 'instant'>('instant');
    const [preventLinkMethod, setPreventLinkMethod] = useState<'manual' | 'instant'>('instant');
    const [isDone, setIsDone] = useState(false);
    const { user, refetchAccount } = useUserContext();
    const formMethods = useForm({
        defaultValues: defaultLinkBank,
        resolver: zodResolver(
            linkBankValidation({
                method: linkMethod,
                messages: {
                    accountNumber: validationLocale('account_number'),
                    transitNumber: validationLocale('transit_number'),
                    accountName: validationLocale('account_name'),
                    bankNumber: validationLocale('bank_number')
                }
            })
        )
    });

    const queryParams = new URLSearchParams(location.search);

    useEffect(() => {
        const loginId = queryParams.get('loginId');

        if (loginId && user?.id) {
            changeStep('3');
            setIsLoading(true);
            apiAccountService
                .flinksConnect(loginId)
                .then(() => {
                    refetchAccount();
                    changeStep('3');
                    formMethods.reset(defaultLinkBank);
                })
                .catch((e) => {
                    changeStep('1');
                    requestErrorLocale(e);
                })
                .finally(() => setIsLoading(false));
        }
    }, [user]);

    const renderStep = () => {
        switch (currentStep?.id) {
            case '1':
                return (
                    <FirstStepLinkBank
                        handleChoseOtherType={handleChoseOtherType}
                        type={preventLinkMethod}
                        onChange={(value) => setPreventLinkMethod(value)}
                    />
                );
            case '2':
                return linkMethod === 'manual' ? (
                    <SecondStepManualLinkBank
                        handleChooseOtherMethod={() => setLinkMethod('instant')}
                    />
                ) : (
                    <SecondStepInstantLinkBank
                        handleChooseOtherMethod={() => setLinkMethod('manual')}
                    />
                );
            case '3':
                return <ThirdStepLinkBank changeStep={changeStep} isLoading={isLoading} />;
        }
    };

    const { handleRequest: handleLinkBankAccount, isLoading: isLinkBankLoading } = useApiMutation({
        method: () => {
            const { accountNumber, transitNumber, accountName, bankNumber } =
                formMethods.getValues();
            return apiAccountService.linkBankAccount({
                accountName,
                transitNumber: Number(transitNumber),
                accountNumber: Number(accountNumber),
                bankNumber: Number(bankNumber)
            });
        },
        onSuccess: () => {
            requestSuccessLocale('link_bank_account');
            refetchAccount();
            nextStep();
            setIsDone(true);
        },
        onError: requestErrorLocale
    });

    return (
        <motion.div {...pageAnimation} className={styles.wrapper}>
            <FormProvider {...formMethods}>
                <ProgressBar
                    steps={steps(t)}
                    availableStep={availableStep}
                    currentStep={currentStep}
                    size="large"
                    variant="light"
                    className={styles.progressbar}
                />
                <div className={styles.content}>{renderStep()}</div>
                {((linkMethod !== 'instant' && currentStep?.id !== '2') ||
                    currentStep?.id === '1' ||
                    linkMethod === 'manual') &&
                    !(linkMethod === 'manual' && currentStep?.id === '3') && (
                        <Button
                            variant="primary"
                            size="normal"
                            isLoading={isLinkBankLoading}
                            onClick={() => {
                                if (currentStep?.id === '1') {
                                    setLinkMethod(preventLinkMethod);
                                    nextStep();
                                } else if (currentStep?.id === '2') {
                                    formMethods.trigger().then((isValid) => {
                                        if (isValid) {
                                            handleLinkBankAccount(undefined);
                                        }
                                    });
                                } else {
                                    navigate(routes.dashboard);
                                }
                            }}>
                            {isDone ? submitLocale('done') : submitLocale('next')}
                        </Button>
                    )}
            </FormProvider>
        </motion.div>
    );
};

export default LinkBankSection;
