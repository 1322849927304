import React, { FC } from 'react';
import styles from './styles.module.scss';
import Typography from '@components/common/typography';
import Input from '@components/common/input';
import { useLocales } from '@hooks/helpers/useLocales';
import { useTranslation } from 'react-i18next';
import { IModalComponentProps } from '@context/modal_context';

const AddEmailModal: FC<IModalComponentProps<{ email: string }>> = ({
    modalData,
    changeModalData
}) => {
    const { t } = useTranslation('modals', {
        keyPrefix: 'add_email'
    });
    const { labelLocale } = useLocales();
    return (
        <div className={styles.wrapper}>
            <Typography className={styles.title} variant="h4" weight="bold">
                {t('title')}
            </Typography>
            <div className={styles.content}>
                <Input
                    full
                    label={labelLocale('email')}
                    value={modalData?.email}
                    onChange={(value) => changeModalData({ email: value })}
                />
            </div>
        </div>
    );
};

export default AddEmailModal;
